import { Button, TextField } from '@mui/material';
import { HOLIDAY, HOLIDAY_GETALL } from 'api/apiEndPoint';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import React, { useState, useMemo, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { fetchData, postData } from 'utils/apiUtils';

const ReactCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventName, setEventName] = useState('');
  const [events, setEvents] = useState([]);

  const Date_Click_Fun = (date) => {
    setSelectedDate(date);
  };

  const Event_Data_Update = (event) => {
    setEventName(event.target.value);
  };

  const Create_Event_Fun = () => {
    console.log('selectedDate------>', selectedDate.toLocaleDateString('en-GB').split('/').reverse().join('-'));
    const postDate = selectedDate?.toLocaleDateString('en-GB').split('/').reverse().join('-');
    if (selectedDate && eventName) {
      const endpoint = HOLIDAY(postDate);
      const data = postData(endpoint);
      console.log('data------->', data);
      const newEvent = {
        id: new Date().getTime(),
        date: selectedDate,
        title: 'HOLIDAY'
      };
      setEvents([...events, newEvent]);
      setSelectedDate(null);
      setEventName('');
      setSelectedDate(newEvent.date);
    }
  };

  const fetchDataFromDB = async () => {
    const data = await fetchData(HOLIDAY_GETALL);
    setEvents(data);
    console.log('data------------->>>', data);
  };
  useEffect(() => {
    fetchDataFromDB();
  }, []);

  const columnHelper = createMRTColumnHelper();

  const columns = useMemo(() => [
    columnHelper.accessor('date', {
      header: 'Date',
      Cell: ({ row }) => <div>{new Date(row.original.date).toLocaleDateString('en-GB')}</div>
    }),
    columnHelper.accessor('title', {
      header: 'Title',
      Cell: () => <div>HOLIDAY</div>
    })
  ]);

  const table = useMaterialReactTable({
    columns,
    data: events,
    renderTopToolbar: false,
    enablePagination: false,
    positionToolbarAlertBanner: 'bottom',
    paginationDisplayMode: 'default'
  });

  // const isEventDate = (date) => {
  //   return events.some((event) => event.date.toDateString() === date.toDateString());
  // };

  return (
    <div className="container d-flex w-100">
      <div className="calendar-container w-40 d-flex justify-content-center">
        <Calendar
          value={selectedDate}
          onClickDay={(date) => Date_Click_Fun(date)}
          // Calendar component
          tileClassName={({ date }) =>
            selectedDate && date.toDateString() === selectedDate.toDateString()
              ? 'selected'
              : events.some((event) => new Date(event.date).toDateString() === date.toDateString())
              ? 'event-marked'
              : ''
          }
          tileDisabled={({ date }) => events.some((event) => new Date(event.date).toDateString() === date.toDateString())}
        />
      </div>
      <div className="w-20 d-flex justify-content-center container">
        {selectedDate && (
          <div className="event-form">
            <p className="text-center selected-date">
              Selected Date: {selectedDate.toLocaleDateString('en-GB').split('/').reverse().join('-')}{' '}
            </p>
            <TextField
              type="text"
              className=" d-flex justify-content-center "
              placeholder="Event Name"
              value={eventName}
              onChange={Event_Data_Update}
            />{' '}
            <div className="d-flex justify-content-center mt-1">
              <Button variant="contained" onClick={Create_Event_Fun}>
                Add
              </Button>{' '}
            </div>
          </div>
        )}
      </div>
      <div className="event-container w-40 d-flex justify-content-center">
        <div className="event-list">
          <div className="event-cards">
            <div className="event-container">
              <div className="event-list card" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <MaterialReactTable table={table} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactCalendar;
