/*eslint-disable*/

import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FilterListIcon from '@mui/icons-material/FilterList';
import { TextField, Tooltip, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { MONTHLY_LEAVEBAL } from 'api/apiEndPoint';
import { fetchData } from 'utils/apiUtils';

const MonthlyCalendar = ({ data, year, month, type }) => {

  const [leaveData, setleavedata] = useState(null);

  // Function to get the number of days in a month
  const daysInMonth = (year, month) => {
    return new Date(Date.UTC(year, parseInt(month) + 1, 0)).getUTCDate();
  };

  //filter options
  const [filterText, setFilterText] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  // Generate an array containing all dates in the month
  const allDates = Array.from({ length: daysInMonth(year, month) }, (_, i) => new Date(Date.UTC(year, month, i + 1)));

  // Function to generate table header cells with formatted date headers
  const generateHeaderCells = () => {
    return allDates.flatMap((date) => {
      const monthName = date.toLocaleString('default', { month: 'short' });
      const dayOfMonth = date.getDate();
      const dayOfWeek = date.toLocaleString('default', { weekday: 'short' });
      return (
        <TableCell key={`${monthName}-${dayOfMonth}-month`}>
          <p className="m-0 p-0">{monthName}</p>
          <p className="m-0 p-0">{dayOfMonth}</p>
          <p className="m-0 p-0">{dayOfWeek}</p>
        </TableCell>
      );
    });
  };

  // Function to get the attendance status for a specific date
  const getAttendanceStatus = (employee, date) => {
    const attendance = employee.attendance.find((a) => a.date === date.toISOString().split('T')[0]);
    return attendance ? attendance.status : 'Waiting';
  };

  // Function to render status based on the abbreviation
  const renderStatus = (status) => {
    switch (status) {
      case 'Leave':
        return 'L';
      case 'Absent':
        return 'A';
      case 'Present':
        return 'P';
      case 'Holiday':
        return 'HL';
      case 'Casual Leave':
        return 'CL';
      case 'CL':
        return 'CL';
      case 'Sick Leave':
        return 'SL';
      case 'SL':
        return 'SL';
      case 'Earned Leave':
        return 'EL';
      case 'EL':
        return 'EL';
      case 'Compoff Adjustment':
        return 'CA';
      case 'Compoff':
        return 'CA';
      case 'Compoff Earnings':
        return 'CE';
      case 'Compoff Earning':
        return 'CE';
      case 'On Duty':
        return 'OD';
      case 'Loss of Pay':
        return 'LOP';
      case 'LOP':
        return 'LOP';
      case 'Uninformed Leave':
        return 'UN';
      default:
        return '-';
    }
  };

  // Function to generate tooltip content for a cell
  const generateTooltipContent = (employee, date) => {
    const attendance = employee.attendance.find((a) => a.date === date.toISOString().split('T')[0]);
    if (attendance) {
      const { status, clock_in, clock_out } = attendance;
      let tooltipContent = `<div>Status: ${status}</div>`;
      if (status === 'Present') {
        tooltipContent += `<div>In Time: ${new Date(clock_in).toLocaleTimeString('en-us')}</div>`;
        tooltipContent += `<div>Out Time: ${new Date(clock_out).toLocaleTimeString('en-us')}</div>`;
        // Calculate total time
        const totalTime = new Date(clock_out) - new Date(clock_in);
        const hours = Math.floor(totalTime / (1000 * 60 * 60));
        const minutes = Math.floor((totalTime % (1000 * 60 * 60)) / (1000 * 60));
        tooltipContent += `<div>Total Working Hours: ${hours} Hrs. ${minutes} Min.</div>`;
      }
      return tooltipContent;
    }
    return 'No data available for this date';
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'Present':
        return '#b9f6ca'; // P (Present)
      case 'Holiday':
        return '#e3f2fd'; // HL (Holiday)
      case 'Sick Leave':
        return '#ffdfea'; // SL (Sick Leave)
      case 'SL':
        return '#ffdfea'; // SL (Sick Leave)
      case 'Leave':
        return '#ff000029'; // SL (Sick Leave)
      case 'Absent':
        return '#ff000029'; // SL (Sick Leave)
      case 'Casual Leave':
        return '#e6f8d2'; // CL (Casual Leave)
      case 'CL':
        return '#e6f8d2'; // CL (Casual Leave)
      case 'Earned Leave':
        return '#ffece8'; // EL (Earned Leave)
      case 'EL':
        return '#ffece8'; // EL (Earned Leave)
      case 'Compoff Adjustment':
        return '#f1a7a7f5'; // Compoff Adjustment
      case 'Compoff':
        return '#f1a7a7f5'; // Compoff Adjustment
      case 'Compoff Earnings':
        return '#f1baf1'; // CE (Compoff Earnings)
      case 'Compoff Earning':
        return '#f1baf1'; // CE (Compoff Earnings)
      case 'On Duty':
        return '#9af5e8'; // OD (On Duty)
      case 'Loss of Pay':
        return '#ffec7d'; // LOP (Loss of Pay)
      case 'LOP':
        return '#ffec7d'; // LOP (Loss of Pay)
      case 'Uninformed Leave':
        return '#afb7f3'; // UN (Uninformed Leave)
      default:
        return '#eef0f1'; // Leave (All other values)
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Present':
        return '#05e644'; // P (Present)
      case 'Holiday':
        return '#39abff'; // HL (Holiday)
      case 'Sick Leave':
        return '#000'; // SL (Sick Leave)
      case 'SL':
        return '#000'; // SL (Sick Leave)
      case 'Leave':
        return '#de0006'; // SL (Sick Leave)
      case 'Absent':
        return '#de0006'; // SL (Sick Leave)
      case 'Casual Leave':
        return '33392d'; // CL (Casual Leave)
      case 'CL':
        return '33392d'; // CL (Casual Leave)
      case 'Earned Leave':
        return '#000'; // EL (Earned Leave)
      case 'EL':
        return '#000'; // EL (Earned Leave)
      case 'Compoff Adjustment':
        return '#ff0202'; // Compoff Adjustment
      case 'Compoff':
        return '#ff0202'; // Compoff Adjustment
      case 'Compoff Earnings':
        return '#ff00ff'; // CE (Compoff Earnings)
      case 'On Duty':
        return '#00ad94'; // OD (On Duty)
      case 'Loss of Pay':
        return '#9f8700'; // LOP (Loss of Pay)
      case 'LOP':
        return '#9f8700'; // LOP (Loss of Pay)
      case 'Uninformed Leave':
        return '#000'; // UN (Uninformed Leave)
      default:
        return '#433b42'; // Leave (All other values)
    }
  };

  const sortedEmployees = data.slice().sort((a, b) => a.employee_code.localeCompare(b.employee_code));
  const filteredEmployees = sortedEmployees.filter((employee) => employee.employee_name.toLowerCase().includes(filterText.toLowerCase()));
  console.log('leavesdata', filteredEmployees);
  console.log('leavesdata', leaveData);
  const updatedFilteredEmployees = filteredEmployees.map((employee) => {
    // Find the matching leave data by employee_code
    const matchingLeaveData = leaveData?.find((leave) => leave['Employee Code'] === employee.employee_code);

    if (matchingLeaveData) {
      // Update the employee balance based on the matching leave data
      return {
        ...employee,
        balance: {
          ...employee.balance,
          Openingcl: Number(matchingLeaveData['CL OB']) || employee.balance.Openingcl,
          closingcl: Number(matchingLeaveData['CL CB']) || employee.balance.closingcl,
          Openingsl: Number(matchingLeaveData['SL OB']) || employee.balance.Openingsl,
          closingsl: Number(matchingLeaveData['SL CB']) || employee.balance.closingsl,
          Openingel: Number(matchingLeaveData['EL OB']) || employee.balance.Openingel,
          closingel: Number(matchingLeaveData['EL CB']) || employee.balance.closingel
        }
      };
    }

    // If no matching leave data, return the employee as is
    return employee;
  });
  const generateRows = () => {
    return updatedFilteredEmployees.map((employee, index) => {
      let presentDays = 0;
      let totalCE = 0;
      let totalCL = 0;
      let totalSL = 0;
      let totalEL = 0;
      let totalMinutes = 0;

      allDates.forEach((date) => {
        const status = getAttendanceStatus(employee, date);

        if (status === 'Casual Leave' || status === 'CL') {
          totalCL++;
        } else if (status === 'Sick Leave' || status === 'SL') {
          totalSL++;
        } else if (status === 'Earned Leave' || status === 'EL') {
          totalEL++;
        }

        const attendance = employee.attendance.find((a) => a.date === date.toISOString().split('T')[0]);
        if (attendance && status === 'Present') {
          const { clock_in, clock_out } = attendance;
          const inTime = new Date(clock_in);
          const outTime = new Date(clock_out);
          const dailyMinutes = (outTime - inTime) / (1000 * 60);
          totalMinutes += dailyMinutes;
        }
      });

      const totalHours = Math.floor(totalMinutes / 60);
      const remainingMinutes = Math.round(totalMinutes % 60);
      const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

      const closingCL = Number(employee.balance?.closingcl).toFixed(2).padStart(5, '0');
      const closingSL = Number(employee.balance?.closingsl).toFixed(2).padStart(5, '0');
      const closingEL = Number(employee.balance?.closingel).toFixed(2).padStart(5, '0');
      const Openingcl = Number(employee.balance?.Openingcl).toFixed(2).padStart(5, '0');
      const Openingsl = Number(employee.balance?.Openingsl).toFixed(2).padStart(5, '0');
      const Openingel = Number(employee.balance?.Openingel).toFixed(2).padStart(5, '0');

      return (
        <TableRow key={employee.employee_id}>
          <TableCell style={{ textAlign: 'center', position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 0 }}>
            {index + 1}
          </TableCell>
          <TableCell className="text-ellipse" style={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 0 }}>
            {employee.employee_name}
          </TableCell>
          <TableCell style={{ position: 'sticky', left: '114px', backgroundColor: 'white', zIndex: 0, width: '300px' }}>
            {employee.employee_code}
          </TableCell>
          <TableCell className="text-ellipse">
            {Openingcl} /{closingCL}
          </TableCell>

          <TableCell className="text-ellipse">
            {Openingsl} /{closingSL}
          </TableCell>

          <TableCell className="text-ellipse">
            {Openingel} /{closingEL}
          </TableCell>

          {allDates.map((date) => {
            const status = getAttendanceStatus(employee, date);
            if (status === 'Present' || status === 'On Duty') {
              presentDays++; // Increment presentDays counter if status is Present or On Duty
            } else if (status === 'Compoff Earnings') {
              totalCE++; // Increment totalCE counter if status is Compoff Earnings
            } else if (status === 'Casual Leave' || status === 'CL') {
              totalCL++; // Increment totalCL counter if status is Casual Leave or CL
            }
            const backgroundColor = getStatusBackgroundColor(status);
            const color = getStatusColor(status);

            return (
              <TableCell
                key={date.toISOString().split('T')[0]}
                className=""
                style={{ backgroundColor, color, border: '3px solid #fff', textAlign: 'center' }}
              >
                <Tooltip
                  title={
                    <Typography
                      style={{ textAlign: 'start' }}
                      dangerouslySetInnerHTML={{ __html: generateTooltipContent(employee, date) }}
                    />
                  }
                  PopperProps={{
                    disablePortal: true,
                    style: { maxWidth: '300px' }, // Optional: Adjust the width as needed
                    placement: 'top',
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 8] // Adjust the offset as needed
                        }
                      }
                    ]
                  }}
                >
                  <span className={`status-${status?.toLowerCase()}`}>{renderStatus(status)}</span>
                </Tooltip>
              </TableCell>
            );
          })}
          <TableCell style={{ textAlign: 'center' }}>
            {totalHours} h {formattedMinutes} mins
          </TableCell>
          <TableCell style={{ textAlign: 'center' }}>{presentDays}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{totalCE}</TableCell>
        </TableRow>
      );
    });
  };

  const fetchLeave = async () => {
    const apiUrl4Leave = MONTHLY_LEAVEBAL(month + 1, year);
    const data4Leave = await fetchData(apiUrl4Leave);
    setleavedata(data4Leave);
  };
  useEffect(() => {
    fetchLeave();
  }, []);

  const handleFilterClick = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleClearClick = () => {
    setIsFilterVisible(false);
    setFilterText('');
  };

  // const sortedEmployees = employees.sort((a, b) => a.employee_code.localeCompare(b.employee_code));

  return (
    <>
      <TableContainer component={Paper} style={{ backgroundColor: '#eef0f1', borderColor: '#fff', height: '62vh' }}>
        <Table>
          <TableHead
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 1,
              boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)'
            }}
          >
            <TableRow>
              <TableCell>S.No</TableCell>
              <div className="d-flex justify-content-center align-items-center mt-2">
                <TableCell>Name</TableCell>
                <FilterListIcon onClick={handleFilterClick} style={{ cursor: 'pointer' }} />
              </div>
              {isFilterVisible && (
                <div className="filter-options d-flex align-items-center">
                  <label>
                    <TextField
                      id="standard-basic"
                      label="Filter"
                      variant="standard"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </label>
                  <ClearIcon onClick={handleClearClick} style={{ cursor: 'pointer' }} />
                </div>
              )}
              {/* <div className='d-flex justify-content-center align-items-center mt-2'> */}
              <TableCell>Code</TableCell>
              {/* <FilterListIcon style={{ cursor: 'pointer' }} /> */}
              {/* </div> */}
              <TableCell>CL</TableCell>
              <TableCell>SL</TableCell>
              <TableCell>EL</TableCell>
              {generateHeaderCells()}
              <TableCell>Total Working Hours</TableCell>
              <TableCell>Total Presents</TableCell>
              <TableCell className="mx-2">Total CE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{generateRows()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MonthlyCalendar;
