// ThemeRoutes.js
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthenticationRoutes from './AuthenticationRoutes';
import MainRoutes from './MainRoutes';
// import PrivateRoute from './privateRoute';

const ThemeRoutes = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const localStore = localStorage.getItem('userData');
    const parsedData = JSON.parse(localStore);
    setIsUserLoggedIn(!!parsedData?.code);
  }, []);

  return (
    <Routes>
      <Route path="/*" element={isUserLoggedIn ? <MainRoutes /> : <AuthenticationRoutes />} />
    </Routes>
  );
};

export default ThemeRoutes;
