/* eslint-disable */
import React, { useState } from 'react';
import './UserLeaveDetails.css'; // Import CSS file for styling
import { JsonToTable } from 'react-json-to-table';
//import  DatePicker  from 'react-datepicker';
import { MONTHLY_LEAVEBAL } from 'api/apiEndPoint';
import { Box, useTheme } from '@mui/system';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, TextField, TableBody, Button } from '@mui/material';
import { fetchData } from 'utils/apiUtils';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import { IconDownload } from '@tabler/icons';
import { download, generateCsv, mkConfig } from 'export-to-csv';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true
});

const UserLeaveDetails = () => {
  // Dummy JSON data

  const [startDate, setStartDate] = useState('');
  const [ky, setky] = useState(0);
  const [leavedata, setleavedata] = useState([]);

  const handlechange = async (date) => {
    setky(Math.random());
    setleavedata([]);
    console.log(date);
    let prdate = new Date(date.target.value);
    console.log(prdate);
    setStartDate(date.target.value);
    let mn = date.target.value.split('/');
    let mnth = parseInt(prdate.getMonth() + 1);
    let yr = parseInt(prdate.getFullYear());
    const apiUrl = MONTHLY_LEAVEBAL(mnth, yr);
    const data = await fetchData(apiUrl);
    setleavedata(data);
  };
  console.log(leavedata, 'leavedataSSSSSSSSSSSS');

  // Filter options
  const [filterText, setFilterText] = useState('');
  const [filterText1, setFilterText1] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isFilterVisible1, setIsFilterVisible1] = useState(false);

  const handleFilterClick = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const handleFilterClick1 = () => {
    setIsFilterVisible1(!isFilterVisible);
  };

  const handleClearClick = () => {
    setIsFilterVisible(false);
    setFilterText('');
  };
  const handleClearClick1 = () => {
    setIsFilterVisible1(false);
    setFilterText1('');
  };

  const generateRows = () => {
    // Apply filter
    const filteredData = leavedata.filter(
      (employee) =>
        employee['Employee Name'].toLowerCase().includes(filterText.toLowerCase()) &&
        employee['Employee Code'].toLowerCase().includes(filterText1.toLowerCase())
    );

    // Apply sorting
    const sortedData = filteredData.sort((a, b) => {
      const codeComparison = a['Employee Code'].localeCompare(b['Employee Code']);
      if (codeComparison !== 0) {
        return codeComparison;
      }
      return a['Employee Name'].localeCompare(b['Employee Name']);
    });
    console.log('sortedData :--->', sortedData);

    return sortedData.map((employee, index) => {
      return (
        <TableRow key={index}>
          <TableCell style={{ textAlign: 'center', position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 0 }}>
            {employee['Employee Code']}
          </TableCell>
          <TableCell className="text-ellipse" style={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 0 }}>
            {employee['Employee Name']}
          </TableCell>
          <TableCell style={{ position: 'sticky', left: '114px', backgroundColor: 'white', zIndex: 0, width: '300px' }}>
            {employee.Team}
          </TableCell>
          <TableCell style={{ position: 'sticky', left: '114px', backgroundColor: 'white', zIndex: 0, width: '300px' }}>
            {employee['Joining Date']}
          </TableCell>
          <TableCell className="text-ellipse">{employee.Title}</TableCell>
          <TableCell className="text-ellipse">{employee.Month}</TableCell>
          <TableCell className="text-ellipse">{employee['EL OB']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['EL RCPT']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['EL CONS']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['EL CB']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['CL OB']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['CL RCPT']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['CL CONS']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['CL CB']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['SL OB']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['SL RCPT']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['SL CONS']}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>{employee['SL CB']}</TableCell>
        </TableRow>
      );
    });
  };

  //export
  const theme = useTheme();
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(leavedata);
    download(csvConfig)(csv);
  };

  return (
    <div>
      <Box className="d-flex justify-content-between align-items-center">
        <TextField
          id="date"
          label="Month and Year"
          type="month"
          onChange={(date) => handlechange(date)}
          InputLabelProps={{
            shrink: true
          }}
        />
        {/* selected={startDate}
        onChange={(date) => handlechange(date)}
        
       
        dateFormat="MM/yyyy"
        showMonthYearPicker
      /> */}

        <Button onClick={handleExportData} variant="contained" color="primary" startIcon={<IconDownload />}>
          Export
        </Button>
      </Box>

      <br />
      <div className="table-container">
        {leavedata && leavedata.length > 0 && (
          <TableContainer component={Paper} style={{ backgroundColor: '#eef0f1', borderColor: '#fff', height: '62vh' }}>
            <Table>
              <TableHead
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'white',
                  zIndex: 1,
                  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)'
                }}
              >
                <TableRow>
                  <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    Employee Code
                    <FilterListIcon onClick={handleFilterClick1} style={{ cursor: 'pointer', marginLeft: '4px' }} />
                    {isFilterVisible1 && (
                      <div className="filter-options d-flex align-items-center">
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          value={filterText1}
                          onChange={(e) => setFilterText1(e.target.value)}
                          style={{ marginLeft: '10px' }}
                        />
                        <ClearIcon onClick={handleClearClick1} style={{ cursor: 'pointer' }} />
                      </div>
                    )}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    Employee Name
                    <FilterListIcon onClick={handleFilterClick} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                    {isFilterVisible && (
                      <div className="filter-options d-flex align-items-center">
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          value={filterText}
                          onChange={(e) => setFilterText(e.target.value)}
                          style={{ marginLeft: '10px' }}
                        />
                        <ClearIcon onClick={handleClearClick} style={{ cursor: 'pointer' }} />
                      </div>
                    )}
                  </TableCell>
                  <TableCell>Team</TableCell>
                  <TableCell>Date OF Joining</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Month</TableCell>
                  <TableCell>EL OB</TableCell>
                  <TableCell>EL RCPT</TableCell>
                  <TableCell>EL CONS</TableCell>
                  <TableCell>EL CB</TableCell>
                  <TableCell>CL OB</TableCell>
                  <TableCell>CL RCPT</TableCell>
                  <TableCell>CL CONS</TableCell>
                  <TableCell>CL CB</TableCell>
                  <TableCell>SL OB</TableCell>
                  <TableCell>SL RCPT</TableCell>
                  <TableCell>SL CONS</TableCell>
                  <TableCell>SL CB</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{generateRows()}</TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default UserLeaveDetails;
