import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import { DangerousRounded } from '@mui/icons-material';

const Application2 = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10rem' }}>
      <MainCard
        style={{
          backgroundColor: '#f6b9b9',
          // width: '300px',
          // height: '200px',

          padding: '20px',
          position: 'relative',
          border: '2px solid #ff7777'
        }}
      >
        <div className="d-flex justify-content-center">
          <DangerousRounded style={{ color: '#c8004f', fontSize: '85px', textAlign: 'center' }} />{' '}
        </div>
        <br />
        <Typography id="modal-modal-title" variant="h2" component="h2" style={{ textAlign: 'center' }}>
          Verification Rejected from HR
        </Typography>
        <br />
        <Typography variant="h5" style={{ marginTop: '1rem', marginBottom: '1rem',textAlign: 'center'  }}>
          The submitted data may contain inaccuracies, or <br />
          there were issues with certificate background verification.
        </Typography>
      </MainCard>
    </div>
  );
};

export default Application2;
