import React from 'react'
import TaskPanel from 'views/task'

const Employee = () => {
  return (
    <div>
      <TaskPanel/>
    </div>
  )
}

export default Employee
