// assets
import {
  IconTypography,
  IconPalette,
  IconBusinessplan,
  IconShadow,
  IconWindmill,
  IconUser,
  IconLockAccess,
  IconUsers,
  IconUserX,
  IconUserCircle,
  IconClipboardData,
  IconFileStack,
  IconCoins,
  IconDeviceLaptop
} from '@tabler/icons';

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconBusinessplan,
  IconUser,
  IconLockAccess,
  IconUsers,
  IconUserX,
  IconUserCircle,
  IconClipboardData,
  IconFileStack,
  IconCoins,
  IconDeviceLaptop
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',
  // title: 'Utilities',
  type: 'group',
  children: [
    {
      id: 'icons',
      title: 'Business Logics',
      type: 'collapse',
      icon: icons.IconBusinessplan,
      children: [
        {
          id: 'tabler-customers',
          title: 'Customer DB',
          type: 'item',
          url: 'logics/customers',
          breadcrumbs: false
        },
        {
          id: 'tabler-icons',
          title: 'Leads',
          type: 'item',
          url: 'logics/leads',
          breadcrumbs: false
        },
        {
          id: 'rfq',
          title: 'RFQ',
          type: 'item',
          // external: true,
          // target: '_blank',
          url: 'logics/rfq',
          breadcrumbs: false
        },
        {
          id: 'projects',
          title: 'Project',
          type: 'item',
          // external: true,
          // target: '_blank',
          url: 'logics/projects',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'profiles',
      title: 'Profiles',
      type: 'item',
      url: '/profile',
      icon: icons.IconUser,
      breadcrumbs: false
    },
    {
      id: 'roles-access',
      title: 'Teams',
      type: 'item',
      url: '/roles-access',
      icon: icons.IconLockAccess,
      breadcrumbs: false
    },
    {
      id: 'icons',
      title: 'User',
      type: 'collapse',
      icon: icons.IconUsers,
      children: [
        {
          id: 'task-detail',
          title: 'Task Detail',
          type: 'item',
          // external: true,
          // target: '_blank',
          url: 'user/task-detail',
          breadcrumbs: false
        },
        {
          id: 'user-detail',
          title: 'User Detail',
          type: 'item',
          // external: true,
          // target: '_blank',
          url: 'user/user-detail',
          breadcrumbs: false
        },
        {
          id: 'change-password',
          title: 'Credential',
          type: 'item',
          // external: true,
          // target: '_blank',
          url: 'user/change-password',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'ManagerPanel',
      title: 'Manager Panel',
      type: 'item',
      url: '/Manager-Panel',
      icon: icons.IconUserX,
      breadcrumbs: false
    },
    // {
    //   id: 'procurement',
    //   title: 'procurement',
    //   type: 'item',
    //   url: '/procurement-Panel',
    //   icon: icons.IconFileStack,
    //   breadcrumbs: false
    // },
    {
      id: 'finance',
      title: 'Finance ',
      type: 'item',
      url: '/finance-team',
      icon: icons.IconCoins,
      breadcrumbs: false
    },
    // {
    //   id: 'AMS',
    //   title: 'AMS',
    //   type: 'item',
    //   url: '/assetmanagement-system',
    //   icon: icons.IconDeviceLaptop,
    //   breadcrumbs: false
    // },
    {
      id: 'hrpanel',
      title: 'HR Panel',
      type: 'item',
      url: '/HR-Panel',
      icon: icons.IconUserCircle,
      breadcrumbs: false
    }
  ]
};

export default utilities;
