/*eslint-disable */
import {
  Avatar,
  Box,
  ButtonBase,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  tableCellClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Input,
  RadioGroup,
  FormControlLabel,
  Radio,
  ButtonGroup,
  Autocomplete,
  InputAdornment
} from '@mui/material';
import React, { forwardRef, useEffect, useMemo } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { IconDownload, IconEdit, IconEye, IconHistoryToggle, IconPlus, IconTrash, IconUpload } from '@tabler/icons';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import * as XLSX from 'xlsx';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {
  Attachment,
  ConnectWithoutContact,
  CreateTwoTone,
  Delete,
  DeleteRounded,
  DeleteTwoTone,
  Group,
  History,
  KeyboardBackspaceRounded,
  Label,
  ModeEditRounded,
  NotStarted,
  PersonAdd,
  TaskAlt,
  ThumbDown,
  ThumbDownAltSharp,
  ThumbUpSharp,
  VisibilityRounded,
  VisibilityTwoTone
} from '@mui/icons-material';
import { useState } from 'react';
import MasterList from './masterList';
import { COUNTRYWITH_FLAG, IFSC_CODE, SUPPLIER_DELETE, SUPPLIER_GET, SUPPLIER_GETBYID, SUPPLIER_POST, SUPPLIER_PUT } from 'api/apiEndPoint';
import { deleteData, fetchData, postData, postFormData, updateFormData } from 'utils/apiUtils';
import FileUpload from 'react-material-file-upload';
import pdf from '../../assets/images/PDF.svg';
import Img from '../../assets/images/Img.svg';
import doc from '../../assets/images/Document.svg';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columnHelper = createMRTColumnHelper();
const data = [
  {
    Access: 'Admin',
    Team: 'IT'
  },
  {
    Access: 'User',
    Team: 'HR'
  },
  {
    Access: 'User',
    Team: 'HR'
  },
  {
    Access: 'User',
    Team: 'HR'
  }
];

const getIconForFileType = (mimetype) => {
  switch (mimetype) {
    case 'application/pdf':
      return <img src={pdfIcon} alt="PDF Icon" width="24" />;
    case 'image/png':
    case 'image/jpeg':
      return <img src={imageIcon} alt="Image Icon" width="24" />;
    default:
      return <img src={docIcon} alt="Document Icon" width="24" />;
  }
};

const getMimeType = (url) => {
  const extension = url.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'png':
    case 'jpg':
    case 'jpeg':
      return `image/${extension}`;
    default:
      return 'application/octet-stream';
  }
};

const handleDownload = async (filePath, fileName) => {
  try {
    const response = await fetch(filePath, { method: 'HEAD' });
    if (!response.ok) {
      throw new Error('File not available.');
    }
    const anchor = document.createElement('a');
    anchor.href = filePath;
    anchor.target = '_blank';
    anchor.download = fileName;
    anchor.click();
  } catch (error) {
    console.error('Error downloading the file:', error);
    alert('The file is not available or there was an error downloading it. Please try again later.');
  }
};

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true
});

const Masterpage = () => {
  // const mimetype = getMimeType(attachment);
  // const fileName = attachment.split('/').pop();

  const [view, setView] = useState({
    visible: false,
    mode: 'Initial' // 'add', 'edit', 'view'
  });

  const theme = useTheme();

  const handleToggle = () => {
    setView({
      visible: true,
      mode: 'Add'
    });
    resetForm();
  };
  const handleClose = () => {
    setView({
      visible: true,
      mode: 'Initial'
    });
  };

  const [supplierType, setSupplierType] = useState('local');
  const [localAttachment, setLocalAttachment] = useState('');
  const [foreignAttachment, setForeignAttachment] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleSupplierTypeChange = (event) => {
    setSupplierType(event.target.value);
    setLocalAttachment('');
    setForeignAttachment('');
    setSelectedFile(null);
  };

  const handleLocalAttachmentChange = (event) => {
    setLocalAttachment(event.target.value);
    setFormValues((prevState) => ({
      ...prevState,
      attachment: null
    }));
  };
  // console.log(localAttachment, );

  const handleForeignAttachmentChange = (event) => {
    setForeignAttachment(event.target.value);
    setFormValues2((prevState) => ({
      ...prevState,
      attachment: null
    }));
  };

  const [formValues, setFormValues] = useState({
    supplierName: '',
    email: '',
    contactNumber: '',
    bank: '',
    branch: '',
    pinCode: '',
    address: '',
    remark: '',
    attachment: null
  });
  console.log(formValues, 'formvalesdata--->');

  const [formValues2, setFormValues2] = useState({
    supplierName: '',
    email: '',
    contactNumber: '',
    bank: '',
    pinCode: '',
    address: '',
    remark: '',
    swiftCode: '',
    attachment: null
  });

  const handleForeignFileChange = (fieldName, file) => {
    setFormValues2((prevState) => ({
      ...prevState,
      [fieldName]: file
    }));
  };

  console.log('formValues2', formValues2);

  console.log(formValues.attachment, 'forvalues');

  const handleLocalFileChange = (fieldName, file) => {
    setFormValues((prevState) => ({
      ...prevState,
      [fieldName]: file
    }));
  };

  console.log(formValues2.attachment, 'formvaluesed');
  const [viewData, setViewData] = useState([]);
  const [userDetailsVisible, setUserDetailsVisible] = useState(false);

  const handleViewClick = (row) => {
    setView({ mode: 'View', data: row.original });
    setViewData(row.original);
    console.log(row.original, 'row.original');
    setUserDetailsVisible(true);
    handleSupplierView(row);
  };
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [activeTable, setActiveTable] = useState('supplier');

  const handleSupplierClick = () => setActiveTable('supplier');
  const handleMasterListClick = () => setActiveTable('masterList');

  // country with flag currency

  const [state, setState] = useState({
    countries: [],
    selectedCountryLocal: null,
    selectedCountryForeign: null,
    currencyAmountLocal: '',
    currencyAmountForeign: ''
  });

  console.log(state, 'statesssss');
  const apiUrl = COUNTRYWITH_FLAG;

  const fetchCountries = async () => {
    try {
      console.log('apiUrl', apiUrl);

      const data = await fetchData(apiUrl);

      if (Array.isArray(data)) {
        console.log('countries data:', data);
        setState((prevState) => ({
          ...prevState,
          countries: data
        }));
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (err) {
      console.error('Error fetching countries data:', err);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleCountryChange = (event, newValue, type) => {
    setState((prevState) => ({
      ...prevState,
      [type === 'local' ? 'selectedCountryLocal' : 'selectedCountryForeign']: newValue,
      [type === 'local' ? 'currencyAmountLocal' : 'currencyAmountForeign']: ''
    }));
  };

  // console.log(state.selectedCountryForeign.currencies, 'state');

  const handleCurrencyChange = (event, type) => {
    setState((prevState) => ({
      ...prevState,
      [type === 'local' ? 'currencyAmountLocal' : 'currencyAmountForeign']: event.target.value
    }));
  };

  // IFSC COde

  const [ifscCode, setIfscCode] = useState('');
  const [branch, setBranch] = useState('');
  const [bank, setBank] = useState('');
  const [isValid, setIsValid] = useState(null);

  const fetchForIFSC = async () => {
    try {
      if (ifscCode.length === 11) {
        const apiUrl = IFSC_CODE(ifscCode);
        console.log('apiUrl', apiUrl);

        const response = await fetch(apiUrl);
        if (response.ok) {
          const data = await response.json();
          console.log('IFSC data', data);
          // Update form values with fetched data
          setFormValues((prevValues) => ({
            ...prevValues,
            branch: data.BRANCH,
            bank: data.BANK
          }));
          setIsValid(true);
        } else {
          console.error('Error: Invalid IFSC code');
          setFormValues((prevValues) => ({
            ...prevValues,
            branch: '',
            bank: ''
          }));
          setIsValid(false);
        }
      } else {
        setFormValues((prevValues) => ({
          ...prevValues,
          branch: '',
          bank: ''
        }));
        setIsValid(ifscCode.length > 11 ? false : null);
      }
    } catch (err) {
      console.error('Error fetching IFSC data:', err);
      setFormValues((prevValues) => ({
        ...prevValues,
        branch: '',
        bank: ''
      }));
      setIsValid(false);
    }
  };

  // Optionally, you might want to call fetchForIFSC whenever ifscCode changes
  useEffect(() => {
    fetchForIFSC();
  }, [ifscCode]);

  const handleIFSCChange = (event) => {
    setIfscCode(event.target.value.toUpperCase());
    setIsValid(null);
  };
  console.log(ifscCode, 'ifsccode');

  // account number validation

  const [localAccountNumber, setLocalAccountNumber] = useState('');
  const [localRetypeAccountNumber, setLocalRetypeAccountNumber] = useState('');
  const [foreignAccountNumber, setForeignAccountNumber] = useState('');
  const [foreignRetypeAccountNumber, setForeignRetypeAccountNumber] = useState('');

  const isLocalMatched = localAccountNumber === localRetypeAccountNumber;
  const isForeignMatched = foreignAccountNumber === foreignRetypeAccountNumber;
  console.log('foreignAccountNumber', foreignAccountNumber);

  // iban number
  const [ibanFields, setIbanFields] = useState({
    iban: '',
    retypeIban: ''
  });

  const isIbanMatched = ibanFields.iban === ibanFields.retypeIban;
  const handleIbanFieldChange = (event) => {
    const { name, value } = event.target;
    setIbanFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };

  console.log(ibanFields.retypeIban, 'ibanfields');

  const [isLocalMatched1, setIsLocalMatched1] = useState(false);
  useEffect(() => {
    setIsLocalMatched1(localAccountNumber === localRetypeAccountNumber);
  }, [localAccountNumber, localRetypeAccountNumber]);
  console.log(localRetypeAccountNumber, 'localRetypeAccountNumber');

  // Handle change for all input fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  // Handle change for all input fields
  const handleChange2 = (event) => {
    const { name, value } = event.target;
    setFormValues2((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  console.log(formValues, 'formValues');

  // const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState();
  const [attachmentPreview, setAttachmentPreview] = useState('');

  const handleEditClick = (row) => {
    console.log('Row object:', row);

    // Handle attachment URL
    const attachmentURL = row.attachment ? row.attachment.replace(/\\/g, '/') : '';
    console.log('Attachment URL:', attachmentURL);

    // Set view and mode
    setView({
      visible: true,
      mode: 'Add'
    });

    // Handle local or foreign transaction types
    const isLocal = row.transactionType === 'local';
    setEditMode(row._id);
    setSupplierType(row.transactionType);

    if (isLocal) {
      setLocalAccountNumber(row.bankAcc || '');
      setLocalRetypeAccountNumber(row.confirmAccNumber || '');
      setIfscCode(row.ifscCode || '');
      setState((prevState) => ({
        ...prevState,
        selectedCountryLocal: {
          name: {
            common: row.country || ''
          },
          currencies: {
            [row.currencyCode || '']: {
              name: row.currencyName || '',
              symbol: row.currencyType || ''
            }
          }
        }
      }));

      setFormValues({
        supplierName: row.supplierName || '',
        bank: row.bankName || '',
        branch: row.branch || '',
        email: row.email || '',
        contactNumber: row.contactNumber || '',
        pinCode: row.pincode || '',
        address: row.address || '',
        remark: row.remark || '',
        attachment: [] // Set as empty since it's just for preview
      });

      // Clear foreign data fields
      setForeignAccountNumber('');
      setForeignRetypeAccountNumber('');
      setIbanFields({
        iban: '',
        retypeIban: ''
      });
      setFormValues2({
        supplierName: '',
        bank: '',
        swiftCode: '',
        iban: '',
        retypeIban: '',
        email: '',
        contactNumber: '',
        pinCode: '',
        address: '',
        remark: '',
        attachment: [] // Ensure it's an empty array for form2
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedCountryForeign: {
          name: {
            common: row.country || ''
          },
          currencies: {
            [row.currencyCode || '']: {
              name: row.currencyName || '',
              symbol: row.currencyType || ''
            }
          }
        }
      }));

      setForeignAccountNumber(row.bankAcc || '');
      setForeignRetypeAccountNumber(row.confirmAccNumber || '');
      setIbanFields({
        iban: row.ibanNumber || '',
        retypeIban: row.confirmIbanNumber || ''
      });

      setFormValues2({
        supplierName: row.supplierName || '',
        bank: row.bankName || '',
        swiftCode: row.swiftCode || '',
        iban: row.ibanNumber || '',
        retypeIban: row.confirmIbanNumber || '',
        email: row.email || '',
        contactNumber: row.contactNumber || '',
        pinCode: row.pincode || '',
        address: row.address || '',
        remark: row.remark || '',
        attachment: [] // Set as empty since it's just for preview
      });

      // Clear local data fields
      setLocalAccountNumber('');
      setLocalRetypeAccountNumber('');
      setIfscCode('');
      setFormValues({
        supplierName: '',
        bank: '',
        branch: '',
        email: '',
        contactNumber: '',
        pinCode: '',
        address: '',
        remark: '',
        attachment: [] // Ensure it's an empty array for form1
      });
    }

    // Set the attachment URL for preview
    setAttachmentPreview(attachmentURL);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setView({
      visible: true,
      mode: 'Initial'
    });
    const confirmed = window.confirm("Once you submit, you won't be able to change the form data. Are you sure you want to submit?");
    if (!confirmed) {
      return;
    }

    const formData = new FormData();
    const isLocal = supplierType === 'local';

    if (isLocal) {
      formData.append('transactionType', supplierType);
      // Append local form data
      formData.append('supplierName', formValues.supplierName);
      formData.append('bankAcc', localAccountNumber);
      formData.append('confirmAccNumber', localRetypeAccountNumber);
      formData.append('bankName', formValues.bank);
      formData.append('ifscCode', ifscCode);
      formData.append('branch', formValues.branch);
      formData.append('email', formValues.email);
      formData.append('contactNumber', formValues.contactNumber);
      formData.append('country', state?.selectedCountryLocal?.name?.common || '');
      formData.append(
        'currencyType',
        state?.selectedCountryLocal?.currencies ? Object.values(state.selectedCountryLocal.currencies)[0]?.symbol || '' : ''
      );

      formData.append('pincode', formValues.pinCode);
      formData.append('address', formValues.address);
      formData.append('remark', formValues.remark);

      if (formValues?.attachment && formValues.attachment[0]) {
        formData.append('attachment', formValues.attachment[0]);
      }
    } else {
      formData.append('transactionType', supplierType);
      // Append foreign form data
      formData.append('supplierName', formValues2.supplierName);
      formData.append('bankAcc', foreignAccountNumber);
      formData.append('confirmAccNumber', foreignRetypeAccountNumber);
      formData.append('bankName', formValues2.bank);
      formData.append('swiftCode', formValues2.swiftCode);
      formData.append('ibanNumber', ibanFields.iban);
      formData.append('confirmIbanNumber', ibanFields.retypeIban);
      formData.append('email', formValues2.email);
      formData.append('contactNumber', formValues2.contactNumber);
      formData.append('pincode', formValues2.pinCode);
      formData.append('country', state?.selectedCountryForeign?.name?.common || '');
      formData.append(
        'currencyType',
        state?.selectedCountryForeign?.currencies ? Object.values(state.selectedCountryForeign.currencies)[0]?.symbol || '' : ''
      );
      if (formValues2?.attachment && formValues2.attachment[0]) {
        formData.append('attachment', formValues2.attachment[0]);
      }
      formData.append('address', formValues2.address);
      formData.append('remark', formValues2.remark);
    }

    // Log FormData entries
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const apiUrl = editMode ? SUPPLIER_PUT(editMode) : SUPPLIER_POST;
      const localData = localStorage.getItem('userData');
      const parsedData = localData ? JSON.parse(localData) : {};
      const token = parsedData?.accessToken;

      let response;
      if (editMode) {
        response = await updateFormData(apiUrl, formData, token);
      } else {
        response = await postFormData(apiUrl, formData, token);
      }
      resetForm();
      fetchSupplierList();

      if (response.ok) {
        console.log('Form submitted successfully');
      } else {
        const errorText = await response.text();
        console.error('Form submission failed:', errorText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  // Form Reset
  const resetForm = () => {
    setFormValues({
      supplierName: '',
      email: '',
      contactNumber: '',
      bank: '',
      branch: '',
      pinCode: '',
      address: '',
      remark: '',
      attachment: null
    });

    setFormValues2({
      supplierName: '',
      email: '',
      contactNumber: '',
      bank: '',
      pinCode: '',
      address: '',
      remark: '',
      swiftCode: '',
      attachment: null
    });

    setLocalAccountNumber('');
    setLocalRetypeAccountNumber('');
    setIfscCode('');

    setState((prevState) => ({
      ...prevState,
      countries: [],
      selectedCountryLocal: null,
      selectedCountryForeign: null,
      currencyAmountLocal: '',
      currencyAmountForeign: ''
    }));

    setLocalAttachment('');
    setForeignAttachment('');
    setForeignAccountNumber('');
    setForeignRetypeAccountNumber('');
    setIbanFields({
      iban: '',
      retypeIban: ''
    });
  };

  // GET Method
  const [supplierList, setSupplierList] = useState([]);

  const columns = [
    columnHelper.accessor('transactionType', {
      header: 'Transaction Type'
    }),
    columnHelper.accessor('supplierName', {
      header: 'Supplier Name'
    }),
    columnHelper.accessor('supplierCode', {
      header: 'Supplier Code'
    }),
    columnHelper.accessor('bankAcc', {
      header: 'Bank Account Number'
    }),
    columnHelper.accessor('confirmAccNumber', {
      header: 'Confirm Acc Number'
    }),
    columnHelper.accessor('bankName', {
      header: 'Bank Name'
    }),
    columnHelper.accessor('ifscCode', {
      header: 'IFSC cCode'
    }),
    columnHelper.accessor('branch', {
      header: 'Branch'
    }),
    columnHelper.accessor('email', {
      header: 'Email'
    }),
    columnHelper.accessor('contactNumber', {
      header: 'Contact Number'
    }),
    columnHelper.accessor('address', {
      header: 'Address'
    }),
    columnHelper.accessor('pincode', {
      header: 'Pin Code'
    }),
    columnHelper.accessor('attachment', {
      header: 'Attachment'
    }),
    columnHelper.accessor('swiftCode', {
      header: 'Swift Code'
    }),
    columnHelper.accessor('ibanNumber', {
      header: 'IBAN Number'
    }),
    columnHelper.accessor('confirmIbanNumber', {
      header: 'confirm Iban Number'
    }),
    columnHelper.accessor('country', {
      header: 'Country'
    }),
    columnHelper.accessor('currencyType', {
      header: 'Currency Type'
    })
  ];

  const dataForSupplierList = useMemo(() => {
    return supplierList?.map((obj, index) => ({ ...obj, SNo: index + 1 }));
  }, [supplierList]);

  const table = useMaterialReactTable({
    columns,
    data: dataForSupplierList,
    enableRowActions: true,
    positionActionsColumn: 'last',
    initialState: {
      columnPinning: { right: ['mrt-row-actions'], left: ['NameOfCandidate'] }
    },
    renderRowActions: ({ row }) => (
      <div style={{ display: 'flex' }}>
        <IconButton onClick={() => handleDeleteClick(row)}>
          <DeleteTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
        <IconButton onClick={() => handleViewClick(row)}>
          <VisibilityTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
        <IconButton>
          <CreateTwoTone
            style={{ color: '#2196f3' }}
            onClick={() => {
              setEditMode(row.original._id);
              handleEditClick(row.original);
              console.log('EditMode set to:', row.original);
            }}
          />
        </IconButton>
      </div>
    ),
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: () => (
      <>
        <div></div>
        {/* <div style={{ marginLeft: '0.5rem' }}>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} accept=".xls,.xlsx" />
          <Button
            variant="contained"
            style={{ marginRight: '1rem' }}
            color="primary"
            onClick={handleImportClick}
            startIcon={<IconUpload />}
          >
            Import
          </Button>
          <Button onClick={handleExportData} variant="contained" color="primary" startIcon={<IconDownload />}>
            Export
          </Button>
        </div> */}
      </>
    )
  });

  const fetchSupplierList = async () => {
    try {
      const apiUrl = SUPPLIER_GET;
      const localData = localStorage.getItem('userData');
      const parsedData = JSON.parse(localData);
      const token = parsedData?.accessToken;
      console.log(token, 'token');
      const data = await fetchData(apiUrl, token);
      console.log('data', data);
      setSupplierList(data || []);
    } catch (err) {
      console.error('Error fetching master list data:', err);
    }
  };

  useEffect(() => {
    fetchSupplierList();
  }, []);

  // GETBYID method
  const handleSupplierView = async (row) => {
    if (!row || !row.original || !row.original._id) {
      console.error('Invalid row data or missing ID');
      return;
    }

    console.log(row.original, 'row.original');
    console.log(row.original._id, 'row.original._id');

    const id = row.original._id;

    try {
      const apiUrl = SUPPLIER_GETBYID(id);

      const localStore = localStorage.getItem('userData');
      if (!localStore) {
        throw new Error('User data not found in local storage');
      }

      const parsed = JSON.parse(localStore);

      const data = await fetchData(apiUrl, parsed.token);
      console.log('Fetched view data:', data);

      setViewData(data || {});
    } catch (err) {
      console.error('Error fetching view data:', err);
    }
  };
  console.log(viewData?.attachment, 'viewdata------->');

  // delete method
  const [opeModel, setopeModel] = useState();

  const handleDeleteClick = (row) => {
    setSelectedRow(row.original);
    setOpen(true);
    setopeModel(row);
  };

  const handleDelete = async (id) => {
    try {
      const apiUrl = SUPPLIER_DELETE(id?.original?._id);
      console.log(id?.original, 'originalidaaa');
      const localData = localStorage.getItem('userData');
      const parsedData = JSON.parse(localData);
      const token = parsedData?.accessToken;
      const data = await deleteData(apiUrl, token);
      console.log('test data', data);
      fetchSupplierList();
      setOpen(false);
    } catch (err) {
      console.error('Error deleting instrument:', err);
    }
  };

  const renderAttachmentPreview = () => {
    if (!attachmentPreview) return null;

    const fileExtension = attachmentPreview.split('.').pop().toLowerCase();

    if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
      return <img src={attachmentPreview} alt="Attachment Preview" style={{ width: '100%', height: 'auto' }} />;
    }

    if (fileExtension === 'pdf') {
      return <embed src={attachmentPreview} type="application/pdf" width="600" height="800" style={{ border: 'none' }} />;
    }

    return <p>Unsupported file type</p>;
  };

  return (
    <div className="max" style={{ marginTop: '-21px' }}>
      {view.mode === 'Add' && (
        <MainCard
          title={editMode ? 'Edit Supplier' : 'Add Supplier'}
          secondary={
            <Box
              sx={{
                ml: 2,
                // mr: 3,
                [theme.breakpoints.down('md')]: {
                  mr: 2
                }
              }}
            >
              <ButtonBase sx={{ borderRadius: '12px' }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                      background: theme.palette.secondary.dark,
                      color: theme.palette.secondary.light
                    }
                  }}
                  aria-haspopup="true"
                  onClick={handleClose}
                  color="inherit"
                >
                  <KeyboardBackspaceRounded stroke={2} size="1.3rem" />
                </Avatar>
              </ButtonBase>
            </Box>
          }
        >
          <form onSubmit={handleSubmit}>
            <Grid container>
              <FormControl component="fieldset" sx={{ mb: 4 }}>
                <RadioGroup row value={supplierType} onChange={(e) => setSupplierType(e.target.value)}>
                  <FormControlLabel value="local" control={<Radio />} label="Transfer Within India" />
                  <FormControlLabel value="foreign" control={<Radio />} label="Transfer Overseas" />
                </RadioGroup>
              </FormControl>

              {supplierType === 'local' && (
                <Grid container spacing={2}>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      label="Supplier Name"
                      variant="outlined"
                      name="supplierName"
                      value={formValues.supplierName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Bank Account Number"
                      variant="outlined"
                      value={localAccountNumber}
                      onChange={(e) => setLocalAccountNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Retype your Account Number"
                      variant="outlined"
                      value={localRetypeAccountNumber}
                      onChange={(e) => setLocalRetypeAccountNumber(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {localRetypeAccountNumber &&
                              (isLocalMatched ? <CheckCircleOutlineIcon color="success" /> : <ErrorOutlineIcon color="error" />)}
                          </InputAdornment>
                        )
                      }}
                      helperText={
                        localRetypeAccountNumber &&
                        (isLocalMatched1 ? (
                          <span style={{ color: 'green' }}>The account numbers match successfully.</span>
                        ) : (
                          'The account numbers do not match. Please verify and try again.'
                        ))
                      }
                      error={!isLocalMatched1 && localRetypeAccountNumber !== ''}
                      FormHelperTextProps={{ error: !isLocalMatched1 && localRetypeAccountNumber !== '' }}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      label="IFSC Code"
                      variant="outlined"
                      value={ifscCode}
                      onChange={handleIFSCChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {isValid === true && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                            {isValid === false && <ErrorOutlineIcon style={{ color: 'red' }} />}
                          </InputAdornment>
                        )
                      }}
                      helperText={
                        isValid === true ? (
                          <span style={{ color: 'green' }}>IFSC Code is valid</span>
                        ) : isValid === false ? (
                          'Invalid IFSC Code'
                        ) : (
                          ''
                        )
                      }
                      FormHelperTextProps={{ error: isValid === false }}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      disabled
                      label="Bank"
                      variant="outlined"
                      name="bank"
                      value={formValues.bank}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      label="Branch"
                      disabled
                      variant="outlined"
                      name="branch"
                      value={formValues.branch}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField fullWidth label="Email" variant="outlined" name="email" value={formValues.email} onChange={handleChange} />{' '}
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Contact Number"
                      variant="outlined"
                      name="contactNumber"
                      value={formValues.contactNumber}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      label="Pin Code"
                      type="number"
                      variant="outlined"
                      name="pinCode" // Name for state management
                      value={formValues.pinCode}
                      onChange={handleChange} // Handle change
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Autocomplete
                      options={state.countries}
                      getOptionLabel={(option) => option?.name?.common || ''}
                      onChange={(event, newValue) => handleCountryChange(event, newValue, 'local')}
                      renderOption={(props, option) => (
                        <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={option?.flags?.png}
                            alt={option?.name?.common || 'flag'}
                            style={{ width: 24, height: 16, marginRight: 8 }}
                          />
                          {option?.name?.common}
                        </li>
                      )}
                      renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                      value={state.selectedCountryLocal}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      disabled
                      label="Currency Type"
                      variant="outlined"
                      value={state.selectedCountryLocal?.currencies ? Object.values(state.selectedCountryLocal.currencies)[0]?.symbol : ''}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Attachment</InputLabel>
                      <Select value={localAttachment} onChange={handleLocalAttachmentChange} label="Attachment">
                        <MenuItem value="Passbook">Passbook</MenuItem>
                        <MenuItem value="Cancelled Copy">Cancelled Copy</MenuItem>
                        <MenuItem value="Bank Mandate Sign Copy">Bank Mandate Sign Copy</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <FileUpload
                      title="Attachment"
                      value={formValues.attachment}
                      onChange={(file) => handleLocalFileChange('attachment', file)}
                      multiFile={false}
                      maxUploadFiles={1}
                    />
                    {editMode && <div>{renderAttachmentPreview()}</div>}
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <TextField
                      fullWidth
                      label="Address"
                      variant="outlined"
                      name="address"
                      value={formValues.address}
                      onChange={handleChange}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      fullWidth
                      label="Remark"
                      variant="outlined"
                      name="remark"
                      value={formValues.remark}
                      onChange={handleChange}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              )}

              {supplierType === 'foreign' && (
                <Grid container spacing={2}>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      label="Supplier Name"
                      variant="outlined"
                      name="supplierName"
                      value={formValues2.supplierName}
                      onChange={handleChange2}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Bank Account Number"
                      variant="outlined"
                      value={foreignAccountNumber}
                      onChange={(e) => setForeignAccountNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Retype your Account Number"
                      variant="outlined"
                      value={foreignRetypeAccountNumber}
                      onChange={(e) => setForeignRetypeAccountNumber(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {foreignRetypeAccountNumber &&
                              (isForeignMatched ? <CheckCircleOutlineIcon color="success" /> : <ErrorOutlineIcon color="error" />)}
                          </InputAdornment>
                        )
                      }}
                      helperText={
                        foreignRetypeAccountNumber &&
                        (isForeignMatched ? (
                          <span style={{ color: 'green' }}>The account numbers match successfully.</span>
                        ) : (
                          'The account numbers do not match. Please verify and try again.'
                        ))
                      }
                      error={!isForeignMatched && foreignRetypeAccountNumber !== ''}
                      FormHelperTextProps={{ error: !isForeignMatched && foreignRetypeAccountNumber !== '' }}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField fullWidth label="Bank" variant="outlined" name="bank" value={formValues2.bank} onChange={handleChange2} />
                  </Grid>

                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      label="SWIFT Code"
                      variant="outlined"
                      name="swiftCode"
                      value={formValues2.swiftCode}
                      onChange={handleChange2}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      label="IBAN"
                      variant="outlined"
                      name="iban"
                      value={ibanFields.iban}
                      onChange={handleIbanFieldChange}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      label="Re-Type The IBAN"
                      variant="outlined"
                      name="retypeIban"
                      value={ibanFields.retypeIban}
                      onChange={handleIbanFieldChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {ibanFields.retypeIban &&
                              (isIbanMatched ? <CheckCircleOutlineIcon color="success" /> : <ErrorOutlineIcon color="error" />)}
                          </InputAdornment>
                        )
                      }}
                      helperText={
                        ibanFields.retypeIban &&
                        (isIbanMatched ? (
                          <span style={{ color: 'green' }}>The IBAN numbers match successfully.</span>
                        ) : (
                          'The IBAN numbers do not match. Please verify and try again.'
                        ))
                      }
                      error={!isIbanMatched && ibanFields.retypeIban !== ''}
                      FormHelperTextProps={{ error: !isIbanMatched && ibanFields.retypeIban !== '' }}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField fullWidth label="Email" variant="outlined" name="email" value={formValues2.email} onChange={handleChange2} />
                  </Grid>

                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Contact Number"
                      variant="outlined"
                      name="contactNumber"
                      value={formValues2.contactNumber}
                      onChange={handleChange2}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      label="Pin Code"
                      type="number"
                      variant="outlined"
                      name="pinCode"
                      value={formValues2.pinCode}
                      onChange={handleChange2}
                    />{' '}
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Autocomplete
                      options={state?.countries}
                      getOptionLabel={(option) => option?.name?.common || ''}
                      onChange={(event, newValue) => handleCountryChange(event, newValue, 'foreign')}
                      renderOption={(props, option) => (
                        <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={option?.flags?.png}
                            alt={option?.name?.common || 'flag'}
                            style={{ width: 24, height: 16, marginRight: 8 }}
                          />
                          {option?.name?.common}
                        </li>
                      )}
                      renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                      value={state?.selectedCountryForeign}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <TextField
                      fullWidth
                      disabled
                      label="Currency Symbol"
                      variant="outlined"
                      value={
                        state.selectedCountryForeign?.currencies ? Object.values(state.selectedCountryForeign.currencies)[0]?.symbol : ''
                      }
                    />
                  </Grid>

                  <Grid item xs={3} md={2}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Attachment</InputLabel>
                      <Select value={foreignAttachment} onChange={handleForeignAttachmentChange} label="Attachment">
                        <MenuItem value="Passbook">Passbook</MenuItem>
                        <MenuItem value="Cancelled Copy">Cancelled Copy</MenuItem>
                        <MenuItem value="Bank Sign Copy">Bank Sign Copy</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <FileUpload
                      title="Attachment"
                      value={formValues2.attachment}
                      onChange={(file) => handleForeignFileChange('attachment', file)}
                      multiFile={false}
                      maxUploadFiles={1}
                    />
                    {editMode && <div>{renderAttachmentPreview()}</div>}
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      fullWidth
                      label="Address"
                      variant="outlined"
                      name="address"
                      value={formValues2.address}
                      onChange={handleChange2}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      fullWidth
                      label="Remark"
                      variant="outlined"
                      name="remark"
                      value={formValues2.remark}
                      onChange={handleChange2}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid xs={12} p={6} style={{ margin: '2rem' }} className="d-flex justify-content-end align-items-center">
                <Button variant="outlined" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="contained" style={{ marginLeft: '10px' }} type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </MainCard>
      )}
      {view.mode === 'Initial' && (
        <MainCard
          title={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <ButtonGroup variant="outlined" aria-label="Basic button group">
                <Button onClick={handleSupplierClick}>Supplier</Button>
                <Button onClick={handleMasterListClick}>Master List</Button>
              </ButtonGroup>
            </Box>
          }
          secondary={
            activeTable === 'supplier' && (
              <Box
                sx={{
                  ml: 2,
                  [theme.breakpoints.down('md')]: {
                    mr: 2
                  }
                }}
              >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.mediumAvatar,
                      transition: 'all .2s ease-in-out',
                      background: theme.palette.secondary.light,
                      color: theme.palette.secondary.dark,
                      '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light
                      }
                    }}
                    aria-haspopup="true"
                    onClick={() => {
                      handleToggle();
                    }}
                    color="inherit"
                  >
                    <IconPlus stroke={2} size="1.3rem" />
                  </Avatar>
                </ButtonBase>
              </Box>
            )
          }
        >
          {activeTable === 'supplier' && <MaterialReactTable table={table} />}
          {activeTable === 'masterList' && (
            <div>
              <MasterList />
            </div>
          )}
        </MainCard>
      )}
      {view.mode === 'View' && view.data && (
        <>
          <MainCard
            title="Note"
            secondary={
              <Box
                sx={{
                  ml: 2,
                  // mr: 3,
                  [theme.breakpoints.down('md')]: {
                    mr: 2
                  }
                }}
              >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.mediumAvatar,
                      transition: 'all .2s ease-in-out',
                      background: theme.palette.secondary.light,
                      color: theme.palette.secondary.dark,
                      '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light
                      }
                    }}
                    aria-haspopup="true"
                    onClick={handleClose}
                    color="inherit"
                  >
                    <KeyboardBackspaceRounded stroke={2} size="1.3rem" />
                  </Avatar>
                </ButtonBase>
              </Box>
            }
          >
            <Grid container m={3}>
              <Grid xs={3} p={2}>
                <label className="text-muted">Transaction Type</label>
                <p>{viewData?.transactionType}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Supplier Name</label>
                <p>{viewData?.supplierName}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Supplier Code</label>
                <p>{viewData?.supplierCode}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Bank Account Number</label>
                <p>{viewData?.bankAcc}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Confirm Account Number</label>
                <p>{viewData?.confirmAccNumber}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Bank Name</label>
                <p>{viewData?.bankName}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">IFSC Code</label>
                <p>{viewData?.ifscCode}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Branch</label>
                <p>{viewData?.branch}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Email</label>
                <p>{viewData?.email}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Contact Number</label>
                <p>{viewData?.contactNumber}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Address</label>
                <p>{viewData?.address}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Pin Code</label>
                <p>{viewData?.pincode}</p>
              </Grid>
              <Grid item xs={3} p={2}>
                {/* {getIconForFileType(mimetype)}
                <p>{attachment}</p> */}
                <label className="text-muted">Attachment</label>
                <p>{viewData?.attachment}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Swift Code</label>
                <p>{viewData?.swiftCode}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">IBAN Number</label>
                <p>{viewData?.ibanNumber}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Confirm IBAN Number</label>
                <p>{viewData?.confirmIbanNumber}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Country</label>
                <p>{viewData?.country}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Currency Type</label>
                <p>{viewData?.currencyType}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Created_At</label>
                <p> {viewData?.createdAt ? viewData.createdAt.slice(0, 10) : 'N/A'}</p>
              </Grid>
            </Grid>
          </MainCard>
        </>
      )}

      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography variant="h3">Delete Lead</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className="d-flex justify-content-center align-item-center">
            <div className="bg-light rounded ">
              <Delete style={{ fontSize: '32' }} />
            </div>
          </DialogContentText>
          <Typography variant="h4" className="muted" display="block" gutterBottom style={{ textAlign: 'center' }} mt={2}>
            Are you want to Delete ?
          </Typography>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-1">
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleDelete(opeModel);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Masterpage;
