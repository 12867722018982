// material-ui
/*eslint-disable */
import { styled } from '@mui/material/styles';
import vdo from '../../../assets/images/clips/clip1.mp4'
// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper1 = styled('div')(({ theme }) => ({
  background: url(vdo),
  minHeight: '100vh'
}));

export default AuthWrapper1;
