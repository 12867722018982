/*eslint-disable */
import {
  Avatar,
  Box,
  ButtonBase,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  tableCellClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Input,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import React, { forwardRef } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { IconDownload, IconEdit, IconEye, IconHistoryToggle, IconPlus, IconTrash, IconUpload } from '@tabler/icons';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import * as XLSX from 'xlsx';

import {
  ConnectWithoutContact,
  CreateTwoTone,
  Delete,
  DeleteRounded,
  DeleteTwoTone,
  Group,
  History,
  KeyboardBackspaceRounded,
  Label,
  ModeEditRounded,
  NotStarted,
  PersonAdd,
  TaskAlt,
  ThumbDown,
  ThumbDownAltSharp,
  ThumbUpSharp,
  VisibilityRounded,
  VisibilityTwoTone
} from '@mui/icons-material';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab';
import { DateRangePicker } from 'rsuite';
import { deleteData, fetchData, postData } from 'utils/apiUtils';
import { TEAM_CREATION, TEAM_DELETE, TEAM_GET_ALL } from 'api/apiEndPoint';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const columnHelper = createMRTColumnHelper();
const data = [
  {
    Access: 'Admin',
    Team: 'IT'
  },
  {
    Access: 'User',
    Team: 'HR'
  },
  {
    Access: 'User',
    Team: 'HR'
  },
  {
    Access: 'User',
    Team: 'HR'
  }
];
const columns = [
  columnHelper.accessor('Team', {
    header: 'Team'
  }),
  columnHelper.accessor('Team', {
    header: 'Team'
  }),
  columnHelper.accessor('Team', {
    header: 'Team'
  })
];

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true
});




const PaymentRequest = () => {

  const [view, setView] = useState({
    visible: false,
    mode: 'Initial' // 'add', 'edit', 'view'
  });

  const theme = useTheme();
  const handleViewClick = (row) => {
    setView({ mode: 'View', data: row.original });
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <div style={{ display: 'flex' }}>
        <IconButton onClick={() => handleDeleteClick(row)}>
          <DeleteTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
        <IconButton onClick={() => handleViewClick(row)}>
          <VisibilityTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
        <IconButton >
          <CreateTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
      </div>
    ),
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: () => (
      <>
        <div></div>
        {/* <div style={{ marginLeft: '0.5rem' }}>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} accept=".xls,.xlsx" />
          <Button
            variant="contained"
            style={{ marginRight: '1rem' }}
            color="primary"
            onClick={handleImportClick}
            startIcon={<IconUpload />}
          >
            Import
          </Button>
          <Button onClick={handleExportData} variant="contained" color="primary" startIcon={<IconDownload />}>
            Export
          </Button>
        </div> */}
      </>
    )
  });

  const handleToggle = () => {
    setView({
      visible: true,
      mode: 'Add'
    });
  };
  const handleClose = () => {
    setView({
      visible: true,
      mode: 'Initial'
    });
  };

  const [formData, setFormData] = useState({
    requestorName: "",
    date: "",
    projectName: "",
    typeOfExpense: "",
    totalAmount: "",
    advanceRequired: "",
    advanceAmount: "",
    supplierName: "",
    accountDetails: "",
    ifscCode: "",
    attachment: null,
    baseValue: "",
    hsnCode: "",
    gst: "",
    gstType: "",
    tds: "",
    tcs: "",
    tcsAmount: "",
    gstValue: "",
    totalAfterDiscount: "",
    discountValue: "",
    typeOfExpense: '',
    remark: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      attachment: e.target.files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Handle form submission here
  };

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleDeleteClick = (row) => {
    setSelectedRow(row.original); // Store the data of the selected row
    setOpen(true); // Open the dialog
  };


  return (
    <div className="max" style={{ marginTop: '-21px' }}>
      {view.mode === 'Add' && (
        <MainCard
          title="Add Payments "
          secondary={
            <Box
              sx={{
                ml: 2,
                // mr: 3,
                [theme.breakpoints.down('md')]: {
                  mr: 2
                }
              }}
            >
              <ButtonBase sx={{ borderRadius: '12px' }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                      background: theme.palette.secondary.dark,
                      color: theme.palette.secondary.light
                    }
                  }}
                  aria-haspopup="true"
                  onClick={handleClose}
                  color="inherit"
                >
                  <KeyboardBackspaceRounded stroke={2} size="1.3rem" />
                </Avatar>
              </ButtonBase>
            </Box>
          }
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="Date"
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  disabled
                  label="Requestor Name"
                  name="requestorName"
                  value={formData.requestorName}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  disabled
                  label="Requestor ID"
                  name="Requestor ID"
                  value={formData.requestorName}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  disabled
                  label="Team"
                  name=" Team"
                  value={formData.requestorName}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="Manager"
                  name=" Manager"
                  value={formData.requestorName}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>

              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="Project Name"
                  name="projectName"
                  value={formData.projectName}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Type of Expense</InputLabel>
                  <Select
                    label="Type of Expense"
                    name="typeOfExpense"
                    value={formData.typeOfExpense}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="service">Service</MenuItem>
                    <MenuItem value="asset">Asset</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {formData.typeOfExpense && (
                <Grid item xs={3} md={2}>
                  <TextField
                    label="Remark"
                    name="remark"
                    value={formData.remark}
                    onChange={handleInputChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
              )}
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="Total Invoice Value"
                  name="Total Invoice Value"
                  type="number"
                  value={formData.totalAmount}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>

              <Grid item xs={3} md={2}>
                <FormControl component="fieldset">
                  <FormLabel>Advance Required</FormLabel>
                  <RadioGroup
                    row
                    name="advanceRequired"
                    value={formData.advanceRequired}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="required"
                      control={<Radio />}
                      label="Required"
                    />
                    <FormControlLabel
                      value="not_required"
                      control={<Radio />}
                      label="Not Required"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {formData.advanceRequired === "required" && (
                <Grid item xs={3} md={2}>
                  <TextField
                    fullWidth
                    label="Advance Amount"
                    name="advanceAmount"
                    type="number"
                    value={formData.advanceAmount}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
              )}
              <Grid item xs={3} md={2} style={{ marginTop: '18px' }}>
                <Button variant="contained" component="label">
                  Upload Attachment
                  <input
                    type="file"
                    name="attachment"
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
              </Grid>
              <Grid item xs={3} md={2}></Grid>

              <Grid item xs={12}>
                <Box sx={{ width: '100%', backgroundColor: '#bfc0c2', height: '1px' }}>
                  <Divider sx={{ height: '1px', backgroundColor: '#bfc0c2', margin: 0 }} />
                </Box>
              </Grid>


              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="Supplier Name"
                  name="supplierName"
                  value={formData.supplierName}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="Account Details"
                  name="accountDetails"
                  value={formData.accountDetails}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="IFSC Code"
                  name="ifscCode"
                  value={formData.ifscCode}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="HSN Code"
                  name="hsnCode"
                  value={formData.hsnCode}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>

              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="Base Value"
                  name="baseValue"
                  type="number"
                  value={formData.baseValue}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>

              <Grid item xs={3} md={2}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>GST %</InputLabel>
                  <Select
                    label="GST %"
                    name="gst"
                    value={formData.gst}
                    onChange={handleInputChange}
                  >
                    <MenuItem value={5}>5%</MenuItem>
                    <MenuItem value={12}>12%</MenuItem>
                    <MenuItem value={18}>18%</MenuItem>
                    <MenuItem value={28}>28%</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} md={2}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>GST Type</InputLabel>
                  <Select
                    label="GST Type"
                    name="gstType"
                    value={formData.gstType}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="cgst_sgst">CGST/SGST</MenuItem>
                    <MenuItem value="igst">IGST</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} md={2}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>TDS Applicable</InputLabel>
                  <Select
                    label="TDS Applicable"
                    name="tds"
                    value={formData.tds}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="cgst_sgst">Yes</MenuItem>
                    <MenuItem value="igst">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3} md={2}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>TCS Applicable</InputLabel>
                  <Select
                    label="TCS Applicable"
                    name="tcs"
                    value={formData.tcs}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="cgst_sgst">Yes</MenuItem>
                    <MenuItem value="igst">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={6} md={3}>
                <FormControl component="fieldset">
                  <FormLabel>TCS Applicable</FormLabel>
                  <RadioGroup
                    row
                    name="tcs"
                    value={formData.tcs}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
              {formData.tcs === "yes" && (
                <Grid item xs={3} md={2}>
                  <TextField
                    fullWidth
                    label="TCS Amount"
                    name="tcsAmount"
                    type="number"
                    value={formData.tcsAmount}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
              )}
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="GST Value"
                  name="gstValue"
                  type="number"
                  value={formData.gstValue}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="Discount Value"
                  name="discountValue"
                  type="number"
                  value={formData.discountValue}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="Total After Discount"
                  name="totalAfterDiscount"
                  type="number"
                  value={formData.totalAfterDiscount}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  fullWidth
                  label="Payment to be processed on or before"
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={12} p={6} style={{  margin: '2rem' }} className='d-flex justify-content-end align-items-center'>
                <Button variant="outlined" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="contained" style={{marginLeft:'10px'}}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </MainCard>
      )}
      {view.mode === 'Initial' && (
        <MainCard
          title="Vendor Payments"
          secondary={
            <Box
              sx={{
                ml: 2,
                // mr: 3,
                [theme.breakpoints.down('md')]: {
                  mr: 2
                }
              }}
            >
              <ButtonBase sx={{ borderRadius: '12px' }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                      background: theme.palette.secondary.dark,
                      color: theme.palette.secondary.light
                    }
                  }}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  color="inherit"
                >
                  <IconPlus stroke={2} size="1.3rem" />
                </Avatar>
              </ButtonBase>
            </Box>
          }
        >
          <MaterialReactTable table={table} />
        </MainCard>
      )}

      {view.mode === 'View' && view.data && (
        <>
          <MainCard
            title="Note"
            secondary={
              <Box
                sx={{
                  ml: 2,
                  // mr: 3,
                  [theme.breakpoints.down('md')]: {
                    mr: 2
                  }
                }}
              >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.mediumAvatar,
                      transition: 'all .2s ease-in-out',
                      background: theme.palette.secondary.light,
                      color: theme.palette.secondary.dark,
                      '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light
                      }
                    }}
                    aria-haspopup="true"
                    onClick={handleClose}
                    color="inherit"
                  >
                    <KeyboardBackspaceRounded stroke={2} size="1.3rem" />
                  </Avatar>
                </ButtonBase>
              </Box>
            }
          >
            <Grid container m={3}>
              <Grid xs={3} p={2}>
                <label className="text-muted">Employee Code</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Name Of Candidate</label>
                <p>Thara</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Date Of Birth</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Designation</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Team</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Role</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Contact Number</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Category</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Status</label>
                <p>CAE0015</p>
              </Grid>
            </Grid>
          </MainCard>
        </>
      )}

      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
      // onClose={handleClose}
      // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography variant="h3">Delete Lead</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className="d-flex justify-content-center align-item-center">
            <div className="bg-light rounded ">
              <Delete style={{ fontSize: '32' }} />
            </div>
          </DialogContentText>
          <Typography variant="h4" className="muted" display="block" gutterBottom style={{ textAlign: 'center' }} mt={2}>
            Are you want to Delete ?
          </Typography>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-1">
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PaymentRequest;
