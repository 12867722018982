/*eslint-disable */
import {
  Avatar,
  Box,
  ButtonBase,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  tableCellClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Input,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import React, { forwardRef, useMemo } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { IconDownload, IconEdit, IconEye, IconHistoryToggle, IconPlus, IconTrash, IconUpload } from '@tabler/icons';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import * as XLSX from 'xlsx';

import {
  ConnectWithoutContact,
  CreateTwoTone,
  Delete,
  DeleteRounded,
  DeleteTwoTone,
  Group,
  History,
  KeyboardBackspaceRounded,
  Label,
  ModeEditRounded,
  NotStarted,
  PersonAdd,
  TaskAlt,
  ThumbDown,
  ThumbDownAltSharp,
  ThumbUpSharp,
  VisibilityRounded,
  VisibilityTwoTone
} from '@mui/icons-material';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab';
import { DateRangePicker } from 'rsuite';
import { deleteData, fetchData, postData, postFormData } from 'utils/apiUtils';
import {
  MASTERLIST_FINANCE_GET,
  PROFILES_GET,
  PROFILES_GET_ID,
  REQUEST_TO_CASH,
  REQUEST_TO_CASH_POST,
  TEAM_CREATION,
  TEAM_DELETE,
  TEAM_GET_ALL
} from 'api/apiEndPoint';
import { fetchKeyValueById } from 'utils/FetchkeyValue';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columnHelper = createMRTColumnHelper();

const Reimbursement = () => {
  const [view, setView] = useState({
    visible: false,
    mode: 'Initial' // 'add', 'edit', 'view'
  });

  const theme = useTheme();

  const [localData, setLocalData] = useState(null);
  const localStore = () => {
    const data = localStorage.getItem('userData');

    if (data) {
      const parseData = JSON.parse(data);
      setLocalData(parseData);
    }
  };

  useEffect(() => {
    localStore();
  }, []);

  useEffect(() => {
    if (localData) {
      setState((prevState) => ({
        ...prevState,
        name: localData.name || '',
        code: localData.code || '',
        team: localData.team || ''
      }));
    }
  }, [localData]);

  const [editingRowId, setEditingRowId] = useState(null);
  const [historyTableData1, setHistoryTableData1] = useState([]);
  const [masterList, setMasterList] = useState([]);
  const [masterList2, setMasterList2] = useState([]);
  const [state, setState] = useState({
    type: 'petty-cash',
    selectedDate: '',
    name: '',
    code: '',
    team: '',
    reporter: '',
    cashrequest: '',
    requestadmin: '663a0a233656a20a3900214b',
    requesttofinance: '663aff653656a20a39002321'
  });
  console.log(state?.type, 'typessssss');

  const handleChange = (event) => {
    const { name, value } = event.target;

    const newValue = name === 'cashrequest' ? (value === '' ? '' : parseFloat(value)) : value;

    setState((prevState) => ({
      ...prevState,
      [name]: newValue
    }));
  };

  const [selectedLabel, setSelectedLabel] = useState('');

  const handleLead = (event) => {
    const value = event.target.value;
    const selectedDirector = directors.find((director) => director.value === value);
    setSelectedLabel(selectedDirector ? selectedDirector.label : '');
  };

  console.log(selectedLabel, 'selectedLabel');

  console.log('Current state:', state);

  const handleToggle = () => {
    setView({
      visible: true,
      mode: 'Add'
    });
  };
  const handleClose = () => {
    setView({
      visible: true,
      mode: 'Initial'
    });
  };

  useEffect(() => {
    console.log('Updated historyTableData1 after creation:', historyTableData1);
  }, [historyTableData1]);

  const handleSaveRow = (values, originalRow) => {
    setHistoryTableData1((prevData) => {
      const updatedData = prevData.map((data) => (data._id === originalRow._id ? { ...values, _id: originalRow._id } : data));

      if (!updatedData.find((data) => data._id === originalRow._id)) {
        updatedData.push({ ...values, _id: Date.now().toString() });
      }

      console.log('Updated Data:', updatedData);
      return updatedData;
    });
  };

  const handleViewClick = (row) => {
    setView({ mode: 'View', data: row.original });
  };
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleDeleteClick = (row) => {
    setSelectedRow(row.original);
    setOpen(true);
  };

  // Create a new row
  const handleCreateRowHistory = (values) => {
    setHistoryTableData1((prevData) => [...prevData, { ...values, _id: Date.now().toString() }]);
  };

  // Cancel editing mode
  const handleCancelEditHistory = () => {
    setEditingRowId(null);
    console.log('Editing canceled. Current editingRowId:', editingRowId);
  };

  // Cancel creation mode
  const handleCancelCreateHistory = () => {
    setEditingRowId(null);
    console.log('Creation canceled. Current editingRowId:', editingRowId);
  };

  // Delete a row
  const handleDeleteRowHistory = (row) => {
    console.log('Deleting row with _id:', row.original._id);
    setHistoryTableData1((prevData) => prevData.filter((data) => data._id !== row.original._id));
    console.log('Updated historyTableData1:', historyTableData1);
  };

  // Save edited row
  const handleSaveRowHistory = (values, originalRow) => {
    setHistoryTableData1((prevData) => {
      const updatedData = prevData.map((data) => (data._id === originalRow._id ? { ...values, _id: originalRow._id } : data));

      if (!updatedData.find((data) => data._id === originalRow._id)) {
        updatedData.push({ ...values, _id: Date.now().toString() });
      }

      console.log('Updated Data:', updatedData);
      return updatedData;
    });
  };

  const fetchMasterList = async () => {
    try {
      const apiUrl = MASTERLIST_FINANCE_GET;
      const localData = localStorage.getItem('userData');
      const parsedData = JSON.parse(localData);
      const token = parsedData?.accessToken;

      const data = await fetchData(apiUrl, token);
      console.log('data', data);

      const master = data.find((item) => item.label === 'Project');
      const Expense = data.find((item) => item.label === 'Expense');

      const projectOptions = master?.options || [];
      const ExpenseOption = Expense?.options || [];

      setMasterList(projectOptions);
      setMasterList2(ExpenseOption);
    } catch (err) {
      console.error('Error fetching master list data:', err);
    }
  };

  useEffect(() => {
    fetchMasterList();
  }, []);

  const [manager, setManager] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [reportingManager1, setReportingManager1] = useState([]);
  const fetchManagerList = async () => {
    try {
      const apiUrl = PROFILES_GET;

      const localData = localStorage.getItem('userData');
      const parsedData = JSON.parse(localData);
      const token = parsedData?.accessToken;

      const data = await fetchData(apiUrl, token);

      const report = data?.data?.filter((item) => item.EmployeeCode === parsedData.code);
      let reports = null;
      if (report.length > 0) {
        reports = report[0].reportingManager;
      }

      // Filter Directors
      const directorsList = data?.data?.filter((item) => item.Designation === 'Director') || [];
      const directorOptions = directorsList.map((dir) => ({
        value: dir._id,
        label: dir.NameOfCandidate
      }));

      // Filter Managers
      const managersList = data?.data?.filter((item) => item.Role === 'Manager') || [];
      const managerOptions = managersList.map((mgr) => ({
        value: mgr._id,
        label: mgr.NameOfCandidate
      }));

      // Update state
      setManager(managerOptions);
      setDirectors(directorOptions);
      setReportingManager1(reports);

      setEmployees(data?.data || []);

      if (reports) {
        const reportingManagerDetails = await fetchKeyValueById(reports, PROFILES_GET_ID, 'NameOfCandidate');
        setReportingManager1(reportingManagerDetails);
      }
    } catch (err) {
      console.error('Error fetching manager list data:', err);
    }
  };

  console.log(directors, 'directors');

  useEffect(() => {
    fetchManagerList();
  }, []);

  const [reporter, setReporter] = useState([]);

  useEffect(() => {
    const fetchReportingManagerName = async () => {
      if (reportingManager1) {
        try {
          const name = await fetchKeyValueById(reportingManager1, PROFILES_GET_ID, 'NameOfCandidate');
          setReporter(name);
        } catch (error) {
          console.error('Error fetching reporting manager name:', error);
        }
      }
    };

    fetchReportingManagerName();
  }, [reportingManager1]);

  useEffect(() => {
    const fetchReportingManagerName = async () => {
      if (reportingManager1) {
        try {
          const name = await fetchKeyValueById(reportingManager1, PROFILES_GET_ID, 'NameOfCandidate');
          setState((prevState) => ({
            ...prevState,
            reporter: name
          }));
        } catch (error) {
          console.error('Error fetching reporting manager name:', error);
        }
      }
    };

    fetchReportingManagerName();
  }, [reportingManager1]);
  const gstOptions = [
    { value: 'gst', label: 'GST' },
    { value: 'non-gst', label: 'Non-GST' }
    // Add more options if necessary
  ];
  const paymentModeOptions = [
    { value: 'cash', label: 'Cash' },
    { value: 'credit', label: 'Credit' },
    { value: 'debit', label: 'Debit' },
    { value: 'online', label: 'Online' }
  ];

  // Columns definition
  const columnsForreimbursement = [
    {
      accessorKey: 'date',
      header: 'Date',
      enableEditing: true,
      muiEditTextFieldProps: {
        type: 'date',
        required: true
      },
      Cell: ({ renderedCellValue, column }) => (
        <TextField
          type="date"
          value={renderedCellValue || ''}
          onChange={(e) => column.onCellEdit(e.target.value)}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )
    },
    {
      accessorKey: 'billno',
      header: 'Bill No',
      enableEditing: true,
      Cell: ({ renderedCellValue, column }) => (
        <TextField value={renderedCellValue || ''} onChange={(e) => column.onCellEdit(e.target.value)} fullWidth />
      )
    },
    {
      accessorKey: 'project',
      header: 'Project',
      enableEditing: true,
      muiEditTextFieldProps: {
        select: true,
        SelectProps: {
          native: false
        },
        children: masterList.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))
      },
      Cell: ({ renderedCellValue, column }) => (
        <TextField select variant="standard" value={renderedCellValue || ''} onChange={(e) => column.onCellEdit(e.target.value)} fullWidth>
          {masterList.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      )
    },
    {
      accessorKey: 'expense',
      header: 'Expense',
      enableEditing: true,
      muiEditTextFieldProps: {
        select: true,
        SelectProps: {
          native: false
        },
        children: masterList2.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))
      },
      Cell: ({ renderedCellValue, column }) => (
        <TextField select variant="standard" value={renderedCellValue || ''} onChange={(e) => column.onCellEdit(e.target.value)} fullWidth>
          {masterList2.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      )
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ renderedCellValue, column }) => (
        <TextField value={renderedCellValue || ''} onChange={(e) => column.onCellEdit(e.target.value)} fullWidth />
      )
    },
    {
      accessorKey: 'gst/non-gst',
      header: 'GST/Non-GST',
      enableEditing: true,
      muiEditTextFieldProps: {
        select: true,
        SelectProps: {
          native: false
        },
        children: gstOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))
      },
      Cell: ({ renderedCellValue, column }) => (
        <TextField select variant="standard" value={renderedCellValue || ''} onChange={(e) => column.onCellEdit(e.target.value)} fullWidth>
          {gstOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )
    },
    {
      accessorKey: 'paymentmode',
      header: 'Payment Mode',
      enableEditing: true,
      muiEditTextFieldProps: {
        select: true,
        SelectProps: {
          native: false
        },
        children: paymentModeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))
      },
      Cell: ({ renderedCellValue, column }) => (
        <TextField select variant="standard" value={renderedCellValue || ''} onChange={(e) => column.onCellEdit(e.target.value)} fullWidth>
          {paymentModeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )
    },
    {
      accessorKey: 'amountpaid',
      header: 'Amount Paid',
      enableEditing: true,
      Cell: ({ renderedCellValue, column }) => (
        <TextField type="number" value={renderedCellValue || ''} onChange={(e) => column.onCellEdit(e.target.value)} fullWidth />
      )
    },
    {
      accessorKey: 'attachment',
      header: 'Attachment',
      enableEditing: true,
      muiEditTextFieldProps: {
        type: 'file',
        required: true,
        onChange: (event) => {
          const file = event.target.files[0];
          if (file) {
            // Handle file logic here
            console.log('Selected file:', file.name);
          }
        }
      },
      Cell: ({ renderedCellValue }) => <Box component="span">{renderedCellValue.split('\\').pop()}</Box>
    },
    {
      accessorKey: 'remark',
      header: 'Remark',
      enableEditing: true,
      Cell: ({ renderedCellValue, column }) => (
        <TextField value={renderedCellValue || ''} onChange={(e) => column.onCellEdit(e.target.value)} fullWidth />
      )
    }
  ];

  console.log('historyTableData1', historyTableData1);

  // Material React Table setup
  const Reimbursement = useMaterialReactTable({
    columns: columnsForreimbursement,
    data: historyTableData1,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: true,
    positionActionsColumn: 'last',
    enableColumnFilters: false,
    enableFilters: false,
    enableDensityToggle: false,
    enablePagination: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    getRowId: (row) => row._id,
    onEditingRowSave: ({ row, values, exitEditingMode }) => {
      handleSaveRowHistory(values, row.original);
      exitEditingMode();
    },
    onEditingRowCancel: handleCancelEditHistory,
    onCreatingRowSave: ({ values }) => {
      handleCreateRowHistory(values);
    },
    onCreatingRowCancel: handleCancelCreateHistory,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleDeleteRowHistory(row)}>
            <DeleteRounded style={{ color: '#2196f3' }} />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="title-bar">
        <div className="custom-header d-flex justify-content-between ">
          <p style={{ fontWeight: 'bold', fontSize: 'large' }}>Expense details</p>
          <ButtonBase sx={{ borderRadius: '12px' }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              onClick={() => {
                table.setCreatingRow(true);
              }}
              color="inherit"
            >
              <IconPlus />
            </Avatar>
          </ButtonBase>
        </div>
      </div>
    )
  });

  const [selectedManager, setSelectedManager] = useState('');
  const handleManagerChange = (event) => {
    const selectedValue = event.target.value;
    const selectedManager = manager.find((manager) => manager.value === selectedValue);
    setSelectedManager(selectedManager ? selectedManager.label : '');
  };

  const getValueFromLabel = () => {
    const selected = manager.find((manager) => manager.label === selectedManager);
    return selected ? selected.value : '';
  };
  console.log(selectedManager, 'selectedManager');

  // GET Method
  const [requesttoCash, setRequesttoCash] = useState([]);

  const columnsForRequest = [
    columnHelper.accessor('paymentType', {
      header: 'Payment Type'
    }),
    columnHelper.accessor('requestorName', {
      header: 'Requestor Name'
    }),
    columnHelper.accessor('requestorCode', {
      header: 'Requestor Code'
    }),
    columnHelper.accessor('pettycashrequestorId', {
      header: 'PettycashRequestorId'
    }),
    columnHelper.accessor('team', {
      header: 'Team'
    }),
    columnHelper.accessor('manager', {
      header: 'Manager'
    }),
    columnHelper.accessor('date', {
      header: 'Date'
    }),
    columnHelper.accessor('amount', {
      header: 'Amount'
    }),
    // columnHelper.accessor('expenses', {
    //   header: 'Expenses'
    // }),
    columnHelper.accessor('managerApprovalStatus', {
      header: 'ManagerApprovalStatus'
    }),
    columnHelper.accessor('managerApprovalBy', {
      header: 'managerApprovalBy'
    }),
    columnHelper.accessor('managerApprovalAt', {
      header: 'managerApprovalAt'
    }),
    columnHelper.accessor('managerApprovalRemark', {
      header: 'managerApprovalRemark'
    }),
    columnHelper.accessor('leadApprovalStatus', {
      header: 'leadApprovalStatus'
    }),
    columnHelper.accessor('leadApprovalBy', {
      header: 'leadApprovalBy'
    }),
    columnHelper.accessor('leadApprovalAt', {
      header: 'leadApprovalAt'
    }),
    columnHelper.accessor('leadApprovalRemark', {
      header: 'leadApprovalRemark'
    }),
    columnHelper.accessor('adminApprovalStatus', {
      header: 'adminApprovalStatus'
    }),
    columnHelper.accessor('adminApprovalBy', {
      header: 'adminApprovalBy'
    }),
    columnHelper.accessor('adminApprovalAt', {
      header: 'adminApprovalAt'
    }),
    columnHelper.accessor('adminApprovalRemark', {
      header: 'adminApprovalRemark'
    }),
    columnHelper.accessor('financeApprovalStatus', {
      header: 'financeApprovalStatus'
    }),
    columnHelper.accessor('financeApprovalBy', {
      header: 'financeApprovalBy'
    }),
    columnHelper.accessor('financeApprovalAt', {
      header: 'financeApprovalAt'
    }),
    columnHelper.accessor('financeApprovalRemark', {
      header: 'financeApprovalRemark'
    }),
    columnHelper.accessor('queueNumber', {
      header: 'queueNumber'
    }),
    columnHelper.accessor('createdAt', {
      header: 'createdAt'
    }),
    columnHelper.accessor('updatedAt ', {
      header: 'updatedAt '
    })
  ];
  const dataRequestToCash = useMemo(() => {
    return requesttoCash?.map((obj, index) => ({ ...obj, SNo: index + 1 }));
  }, [requesttoCash]);

  const table = useMaterialReactTable({
    columns: columnsForRequest,
    data: dataRequestToCash,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <div style={{ display: 'flex' }}>
        <IconButton onClick={() => handleDeleteClick(row)}>
          <DeleteTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
        <IconButton onClick={() => handleViewClick(row)}>
          <VisibilityTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
        <IconButton>
          <CreateTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
      </div>
    ),
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom'
  });

  const fetchpetycash = async () => {
    try {
      const apiUrltype = REQUEST_TO_CASH;
      console.log(apiUrltype, 'apiUrl');

      const localData = localStorage.getItem('userData');
      const parsedData = JSON.parse(localData);
      const token = parsedData?.accessToken;
      console.log(token, 'token');
      const data = await fetchData(apiUrltype, token);
      console.log('data', data);
      setRequesttoCash(data || []);
    } catch (err) {
      console.error('Error fetching master list data:', err);
    }
  };

  useEffect(() => {
    fetchpetycash();
  }, []);

  // POST Method

  // const handlePost = async (e) => {
  //   e.preventDefault();
  //   setView({
  //     visible: true,
  //     mode: 'Initial'
  //   });
  //   const confirmed = window.confirm("Once you submit, you won't be able to change the form data. Are you sure you want to submit?");
  //   if (!confirmed) {
  //     return;
  //   }

  //   const formData = new FormData();
  //   const cashRequest = state?.type === 'petty-cash';
  //   if (cashRequest) {
  //     formData.append('paymentType', state?.type);
  //     formData.append('date', state?.selectedDate);
  //     formData.append('requestorName', state?.name);
  //     formData.append('requestorCode', state?.code);
  //     formData.append('team', state?.team);
  //     formData.append('manager', state?.reporter);
  //     formData.append('amount', state?.cashrequest);
  //   } else {
  //     formData.append('paymentType', state?.type);
  //     formData.append('date', state?.selectedDate);
  //     formData.append('requestorName', state?.name);
  //     formData.append('requestorCode', state?.code);
  //     formData.append('team', state?.team);
  //     formData.append('manager', state?.reporter);
  //     formData.append('amount', state?.cashrequest);
  //   }
  //   console.log('formData', formData);

  //   try {
  //     const apiUrl = REQUEST_TO_CASH_POST;
  //     const localData = localStorage.getItem('userData');
  //     const parsedData = JSON.parse(localData);
  //     // console.log(parsedData,"parsedData");
  //     const token = parsedData?.accessToken;

  //     const response = await postFormData(apiUrl, formData, token);
  //     console.log(response, 'response');
  //   } catch (err) {
  //     console.error('Error:', err);
  //   }
  // };

  return (
    <div className="max" style={{ marginTop: '-21px' }}>
      {view.mode === 'Add' && (
        <MainCard
          title="Add Reimbursement "
          secondary={
            <Box
              sx={{
                ml: 2,
                // mr: 3,
                [theme.breakpoints.down('md')]: {
                  mr: 2
                }
              }}
            >
              <ButtonBase sx={{ borderRadius: '12px' }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                      background: theme.palette.secondary.dark,
                      color: theme.palette.secondary.light
                    }
                  }}
                  aria-haspopup="true"
                  onClick={handleClose}
                  color="inherit"
                >
                  <KeyboardBackspaceRounded stroke={2} size="1.3rem" />
                </Avatar>
              </ButtonBase>
            </Box>
          }
        >
          <form onSubmit={handlePost}>
            <div>
              <div>
                <RadioGroup
                  row
                  name="type" // Adding name attribute to identify the field
                  value={state.type} // Accessing type from the state object
                  onChange={handleChange}
                >
                  <FormControlLabel value="petty-cash" control={<Radio />} label="Petty Cash" />
                  <FormControlLabel value="reimbursement" control={<Radio />} label="Reimbursement" />
                </RadioGroup>
                <br />
                <Grid container spacing={2} marginBottom={3}>
                  <Grid item xs={3} sm={2}>
                    <TextField
                      label="Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      name="selectedDate" // Adding name attribute to identify the field
                      value={state.selectedDate} // Accessing selectedDate from the state object
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <TextField
                      fullWidth
                      disabled
                      label="Requestor Name"
                      variant="outlined"
                      name="name"
                      value={state.name}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <TextField
                      fullWidth
                      disabled
                      label="Requestor Code"
                      variant="outlined"
                      name="code"
                      value={state.code}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <TextField
                      fullWidth
                      disabled
                      label="Team"
                      variant="outlined"
                      name="team"
                      value={state.team}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <TextField
                      fullWidth
                      disabled
                      label="Reporting Manager"
                      variant="outlined"
                      name="reporter"
                      value={state.reporter}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <TextField
                      label={state.type === 'petty-cash' ? 'Petty Cash Requested' : 'Request for Advance'}
                      type="number"
                      name="cashrequest"
                      value={state.cashrequest || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <MaterialReactTable table={Reimbursement} />
                <br />

                <Grid container spacing={2} marginBottom={3}>
                  <Grid item xs={3} sm={2}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Request to Manager</InputLabel>
                      <Select value={getValueFromLabel()} onChange={handleManagerChange} label="Request to Manager">
                        {manager.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Request to Lead</InputLabel>
                      <Select
                        label="Request to Lead"
                        value={directors.find((director) => director.label === selectedLabel)?.value || ''}
                        onChange={handleLead}
                      >
                        {directors.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <TextField
                      label="Request to Admin"
                      value={`Radha Krishnan R ${state.requestadmin.slice(0, 0)}`}
                      disabled
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={3} sm={2}>
                    <TextField
                      label="Finance Approver"
                      value={`Akash Pandiyan  ${state.requesttofinance.slice(0, 0)}`}
                      disabled
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid xs={12} p={6} style={{ margin: '2rem' }} className="d-flex justify-content-end align-items-center">
                    <Button variant="outlined" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="contained" style={{ marginLeft: '10px' }} type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </form>
        </MainCard>
      )}
      {view.mode === 'Initial' && (
        <MainCard
          title="PettyCash and  Reimbursement"
          secondary={
            <Box
              sx={{
                ml: 2,
                // mr: 3,
                [theme.breakpoints.down('md')]: {
                  mr: 2
                }
              }}
            >
              <ButtonBase sx={{ borderRadius: '12px' }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                      background: theme.palette.secondary.dark,
                      color: theme.palette.secondary.light
                    }
                  }}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  color="inherit"
                >
                  <IconPlus stroke={2} size="1.3rem" />
                </Avatar>
              </ButtonBase>
            </Box>
          }
        >
          <MaterialReactTable table={table} />
        </MainCard>
      )}
      {view.mode === 'View' && view.data && (
        <>
          <MainCard
            title="Note"
            secondary={
              <Box
                sx={{
                  ml: 2,
                  // mr: 3,
                  [theme.breakpoints.down('md')]: {
                    mr: 2
                  }
                }}
              >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.mediumAvatar,
                      transition: 'all .2s ease-in-out',
                      background: theme.palette.secondary.light,
                      color: theme.palette.secondary.dark,
                      '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light
                      }
                    }}
                    aria-haspopup="true"
                    onClick={handleClose}
                    color="inherit"
                  >
                    <KeyboardBackspaceRounded stroke={2} size="1.3rem" />
                  </Avatar>
                </ButtonBase>
              </Box>
            }
          >
            <Grid container m={3}>
              <Grid xs={3} p={2}>
                <label className="text-muted">Employee Code</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Name Of Candidate</label>
                <p>Thara</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Date Of Birth</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Designation</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Team</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Role</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Contact Number</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Category</label>
                <p>CAE0015</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Status</label>
                <p>CAE0015</p>
              </Grid>
            </Grid>
          </MainCard>
        </>
      )}

      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography variant="h3">Delete Lead</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className="d-flex justify-content-center align-item-center">
            <div className="bg-light rounded ">
              <Delete style={{ fontSize: '32' }} />
            </div>
          </DialogContentText>
          <Typography variant="h4" className="muted" display="block" gutterBottom style={{ textAlign: 'center' }} mt={2}>
            Are you want to Delete ?
          </Typography>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-1">
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Reimbursement;
