/*eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button, Modal, TextField } from '@mui/material';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { LEAVES_LOGSGET, LEAVE_APPROVAL } from 'api/apiEndPoint';
import { useMemo } from 'react';
import { fetchData, postData } from 'utils/apiUtils';
import { parse } from 'date-fns';

const columnHelper = createMRTColumnHelper();

const Attendance = ({ row }) => {

    const [attendanceData, setAttendanceData] = useState([]);
    const [statusData, setStatusData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [currentLeaveId, setCurrentLeaveId] = useState(null);


    useEffect(() => {
        const fetchDataAndUpdate = async () => {
            try {
                const localStore = localStorage.getItem('userData');
                if (localStore) {
                    const parsedData = JSON.parse(localStore);
                    const apiUrl = LEAVES_LOGSGET;
                    const data = await fetchData(apiUrl);
                    setAttendanceData(data);
                }
            } catch (err) {
                console.error('Error fetching attendance data:', err);
            }
        };

        const fetchStatus = async () => {
            try {
                if (!row || !row.original._id) {
                    return;
                }

                const response = await fetchData(LEAVE_APPROVAL + `?employeeid=${row.original.employeeid}&leaveid=${row.original.leaveid}`);
                const data = await response.json();
                setStatusData(data);
            } catch (error) {
                console.error('Error fetching approval status:', error);
            }
        };

        fetchDataAndUpdate();
        fetchStatus();
    }, [row]);

    const handleAction = async (action, id, reason = '') => {
        if (!action) {
            console.error('Action is undefined. Skipping.');
            return;
        }
        try {
            const leaveToApprove = attendanceData.find(leave => leave._id === id);
            if (!leaveToApprove) {
                console.error(`Leave with id ${id} not found.`);
                return;
            }
            const postDataToApprove = {
                employeeid: leaveToApprove.employee_id,
                leaveid: leaveToApprove._id,
                status: action === 'approve' ? 1 : 0,
                rejectedreason: reason,
                managerid: localStorage.employeeId
            };
            const response = await postData(LEAVE_APPROVAL, postDataToApprove);
            if (response.ok) {
                await fetchDataAndUpdate();
                setOpenModal(false);
                setRejectionReason('');
                setCurrentLeaveId(null);
            } else {
                console.error(`Error ${action}ing leave:`, await response.text());
            }
        } catch (error) {
            console.error(`Error ${action}ing leave:`, error);
        }
    };


    const columns = [
        columnHelper.accessor('employee_code', { header: 'Employee Code' }),
        columnHelper.accessor('employee_name', { header: 'Employee Name' }),
        columnHelper.accessor('employee_team', { header: 'Employee Team' }),
        columnHelper.accessor('employee_location', { header: 'Employee Location' }),
        columnHelper.accessor('leavefromdate', { header: 'From Date' }),
        columnHelper.accessor('leavetodate', { header: 'To Date' }),
        columnHelper.accessor('leavetype', { header: 'Leave Type' }),
        columnHelper.accessor('leavereason', { header: 'Leave Reason' }),
        columnHelper.accessor('status', {
            header: 'Status',
            Cell: ({ row }) => {
                const leaveToApprove = attendanceData.find(leave => leave._id === row.original._id);
                if (!leaveToApprove) return null;

                const isApproved = leaveToApprove.status === 1;
                const isDeclined = leaveToApprove.status === 0;

                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                            onClick={() => {
                                setCurrentLeaveId(row.original._id);
                                setOpenModal(true);
                            }}
                            sx={{ backgroundColor: '#e5a6a6', color: '#c62d41', transition: 'background-color 0.3s', '&:hover': { backgroundColor: '#df8590', color: '#c62d41' } }}
                            disabled={isApproved}
                        >
                            {isDeclined ? 'Declined' : 'Decline'}
                        </Button>

                        <Button
                            onClick={() => handleAction('approve', row.original._id)}
                            sx={{ backgroundColor: '#b9f6ca', color: '#00d5b7', transition: 'background-color 0.3s', '&:hover': { backgroundColor: '#97edaf', color: '#00d5b7' } }}
                            disabled={isDeclined}
                        >
                            {isApproved ? 'Approved' : 'Approve'}
                        </Button>
                    </div>
                );
            }
        })
    ];


    const localStore = localStorage.getItem('userData');
    const parsedData = JSON.parse(localStore);
    console.log(parsedData?.team, "parsedData");

    const dataWithSNo = useMemo(() => {
        return attendanceData.map((obj, index) => ({ ...obj, SNo: index + 1 }));
    }, [JSON.stringify(attendanceData)]);

    console.log(dataWithSNo, "employee_team");
    // const filteredByTeam = useMemo(() => {
    //     const localStore = localStorage.getItem('userData');
    //     const parsedData = JSON.parse(localStore);
    //     return dataWithSNo.filter((team) => team.employee_team === parsedData?.team);
    // }, [dataWithSNo]);

    // const filteredByTeam = dataWithSNo.filter((team) => team.employee_team === parsedData?.team);
    // console.log(filteredByTeam, "filteredByTeam");



    const table = useMaterialReactTable({
        columns,
        data: dataWithSNo,
        columnFilterDisplayMode: 'popover'
    });


    return (
        <div className="max">
            <MaterialReactTable table={table} />


            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <div className="d-flex justify-content-center" style={{ padding: '20px', minHeight: '200px' }}>
                    {/* <h2>Enter Rejection Reason</h2> */}
                    <TextField
                        label="Rejection Reason"
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                        style={{
                            maxWidth: '300px', // Adjust the width as needed
                            fontSize: '0.875rem' // Adjust the font size as needed
                        }}
                        className="form-control"
                    />
                    <br />
                    <Button
                        onClick={() => {
                            handleAction('decline', currentLeaveId, rejectionReason);
                            setOpenModal(false); // Close the modal after submitting
                        }}
                        variant="contained"
                        color="error"
                        style={{ marginTop: '124px', width: '170px', height: '50px', marginLeft: '-175px' }}
                    >
                        Submit Rejection
                    </Button>
                </div>
            </Modal>

        </div>
    );
};

export default Attendance;