/* eslint-disable */
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Avatar, Box, Button, ButtonBase, CssBaseline, Modal, Toolbar, Typography, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
// import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import { KeyboardBackspaceRounded, LockOutlined, LockRounded } from '@mui/icons-material';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  const [open, setOpen] = React.useState(false);
  const [localSkip, setLocalSkip] = useState('');
  const [localStore, setLocalStore] = useState({});
  const handleSkip = () => {
    setOpen(false);
    localStorage.setItem('Skipped', 'Is Skipped');
  };
  const handleUpdate = () => {
    navigate('/user/change-password', { replace: true });
    localStorage.setItem('Skipped', 'Is Skipped');
    setLocalSkip('Is Skipped');
  };
  useEffect(() => {
    const localData = localStorage.getItem('userData');
    const isSkipped = localStorage.getItem('Skipped');
    console.log('isSkipped', isSkipped);
    setLocalSkip(isSkipped);
    const parsed = JSON.parse(localData);
    setLocalStore(parsed);
    parsed?.isReseted ? setOpen(false) : setOpen(true);
  }, []);
  console.log('localStore', localStore?.isReseted);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
        <Modal open={open && localSkip !== 'Is Skipped'} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              padding: '2rem',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: '15px'
            }}
          >
            <div className="">
              <Box sx={{}}>
                <ButtonBase sx={{ borderRadius: '12px', backgroundColor: '#ffac0c2e' }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.mediumAvatar,
                      transition: 'all .2s ease-in-out',
                      background: '#ffac0c2e',
                      padding: '1rem',
                      color: '#ffac0c',
                      '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: '#ffac0c'
                      }
                    }}
                    aria-haspopup="true"
                    // onClick={handleClose}
                    color="inherit"
                  >
                    <LockOutlined stroke={2} size="1.3rem" />
                  </Avatar>
                </ButtonBase>
              </Box>
              <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ marginTop: '1rem' }}>
                Security Alert
              </Typography>
              <Typography id="modal-modal-title" variant="p" component="p" sx={{ marginTop: '1rem', color: 'GrayText', padding: '0' }}>
                Your password is currently set to your Date of Birth (DOB). This makes your account vulnerable to unauthorized access.
                Protect your account by changing your password immediately.
              </Typography>
              <div className="mt-2 d-flex justify-content-end">
                <Button variant="outlined" disableElevation className="me-1" onClick={() => handleSkip()}>
                  Skip
                </Button>
                <Button variant="contained" disableElevation onClick={() => handleUpdate()}>
                  Update
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </Main>
      {/* <Customization /> */}
    </Box>
  );
};

export default MainLayout;
