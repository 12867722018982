/*eslint-disable */
import {
  Avatar,
  Box,
  ButtonBase,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  tableCellClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Input
} from '@mui/material';
// import './Attendancerequest.css';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconDownload } from '@tabler/icons';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import * as XLSX from 'xlsx';
import UserDetailsforHR from './UserDetailsforHR';
import { PERSONAL_DETAILS } from 'api/apiEndPoint';

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true
});

const Usersprofile = () => {
  const [viewData, setViewData] = useState([]);
  const [open, setOpen] = useState(false);
  const [userDetailsVisible, setUserDetailsVisible] = useState(false);

  const handleView = (id) => {
    console.log('here full data:', id?.original);
    setOpen(true);
    setViewData(id?.original);
    setUserDetailsVisible(true);
  };

  

  const handleClose = () => {
    setUserDetailsVisible(false); // Reset userDetailsVisible when closing the dialog
  };

  const columns = [
    columnHelper.accessor('employeeId.EmployeeCode', {
      header: 'Employee Code'
    }),
    columnHelper.accessor('employeeId.NameOfCandidate', {
      header: 'Employee Name'
    }),
    columnHelper.accessor('employeeId.Team', {
      header: 'Department'
    }),
    columnHelper.accessor('employeeId.Location', {
      header: 'Location'
    }),
    columnHelper.accessor('employeeId.ContactNumber', {
      header: 'Contact Number'
    }),
    columnHelper.accessor('verifiedProfile', {
      header: 'Verification Status',
      Cell: ({ row }) => (
        <p className={row?.original?.verifiedProfile === 'verified' ? 'banner-green' : 'banner-yellow'}>{row?.original?.verifiedProfile}</p>
      )
    }),
    columnHelper.accessor('action', {
      header: 'Action',
      Cell: ({ row }) => (
        <div
          style={{
            display: 'flex',
            gap: '10px'
          }}
        >
          <Button
            onClick={() => handleView(row)}
            sx={{
              backgroundColor: '#ede7f6',
              color: '#5e35b1',
              transition: 'background-color 0.3s',
              '&:hover': {
                backgroundColor: '#5e35b1',
                color: '#fff'
              }
            }}
          >
            View
          </Button>
        </div>
      )
    })
  ];

  const fileInputRef = React.createRef();
  const handleImportClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        await uploadToServer(parsedData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const uploadToServer = async (data) => {
    try {
      const response = await fetch('http://localhost:3001/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data })
      });

      // Handle response if needed
      console.log('Upload successful:', response);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  const [ProfileData, setProfileData] = useState([]);

  const fetchPersonalDetails = async () => {
    try {
      const localStore = localStorage.getItem('userData');
      if (localStore) {
        const parsedData = JSON.parse(localStore);
        if (parsedData) {
          const apiEndPoint = `${PERSONAL_DETAILS}`;
          const response = await fetch(apiEndPoint);
          if (!response.ok) {
            // Handle non-OK response status (e.g., 404)
            throw new Error('Failed to fetch personal details');
          }
          const personalDetails = await response.json();
          console.log('personalDetails :', personalDetails);

          // Ensure profileData is an array
          //   const profileDataArray = Array.isArray(personalDetails) ? personalDetails : [personalDetails];
          //   console.log('profileDataArray[0]', profileDataArray);
          setProfileData(personalDetails);
        } else {
          setProfileData([]);
        }
      }
    } catch (error) {
      console.error('Error fetching personal details:', error);
      // Handle the error (e.g., display a message to the user)
    }
  };

  useEffect(() => {
    fetchPersonalDetails();
  }, []);

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: ProfileData,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: () => (
      <>
        <div style={{ marginLeft: '0.5rem' }}>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} accept=".xls,.xlsx" />

          <Button onClick={handleExportData} variant="contained" color="primary" startIcon={<IconDownload />}>
            Export
          </Button>
        </div>
      </>
    )
  });

  return (
    <div className="">
      {userDetailsVisible ? <UserDetailsforHR handleBack={handleClose} data={viewData} /> : <MaterialReactTable table={table} />}
    </div>
  );
};
export default Usersprofile;
