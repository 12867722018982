/*eslint-disable */
import { Avatar, Box, Slide, Tab } from '@mui/material';
import React, { forwardRef } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import * as XLSX from 'xlsx';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import {
  MILESTONE_CREATE,
  MILESTONE_GET,
  PROFILES_CREATE,
  PROFILES_GET,
  PROFILES_GET_ID,
  PROFILES_GET_ROLE,
  PROJECT_CREATE,
  PROJECT_DELETE,
  PROJECT_GET,
  PROJECT_UPDATE,
  RFQ_GET,
  RFQ_GET_ID,
  TASKS_GET_ALL
} from 'api/apiEndPoint';
import { useEffect } from 'react';
import { deleteData, fetchData, postData, updateData } from 'utils/apiUtils';
import Overallattendance from 'Manager/Overallattendance';
import Leaves from 'views/Leaves/Leaves';
import Calender from 'views/Calender/Calender';
import UserDetailsforHR from './UserDetailsforHR';
import Usersprofile from './Usersprofile';
import UserLeaveDetails from './UserLeaveDetails';
import LeaveInfo from './LeaveInfo';
import FinalAttendance from './FinalAttendance';
import ReactCalendar from 'views/Calender/reactCalander';

const columnHelper = createMRTColumnHelper();
const data = [
  {
    projectname: 222,
    projectNumber: '22-04-2022',
    companyname: '23-08-2023',
    assignedDate: 'yes',
    targetDate: '1234',
    milestone: '1234',
    description: 'abc'
  },
  {
    projectname: 222,
    projectNumber: '22-04-2022',
    companyname: '23-08-2023',
    assignedDate: 'yes',
    targetDate: '1234',
    milestone: '1234',
    description: 'abc'
  }
];
const columns = [
  columnHelper.accessor('projectName', {
    header: 'Title'
  }),
  columnHelper.accessor('projectNumber', {
    header: 'Ref Number'
  }),
  columnHelper.accessor('companyName', {
    header: 'Company Name'
  }),
  columnHelper.accessor('assignedDate', {
    header: 'Start Date',
    Cell: ({ renderedCellValue, row }) => (
      <Box component="span">
        <p>{row.original.assignedDate?.slice(0, 10)}</p>
      </Box>
    )
  }),
  columnHelper.accessor('targetDate', {
    header: 'End Date',
    Cell: ({ renderedCellValue, row }) => (
      <Box component="span">
        <p>{row.original.targetDate?.slice(0, 10)}</p>
      </Box>
    )
  }),
  // columnHelper.accessor('status', {
  //   header: 'Status'
  // }),
  columnHelper.accessor('description', {
    header: 'Description'
  }),
  columnHelper.accessor('milestone1', {
    header: 'MileStone'
  })
];
const optionsForHistoryApproval = ['Pending', 'Approval', 'Reject'];
const optionsForHistoryStatus = [' OnGoing', 'Customer Review', 'Internal Review', 'Complete', 'Hold'];
const optionsForTaskStatus = ['Not Started', 'On Going', 'Completed'];
const optionsForteamproject = ['IT Team', 'Finance Team', 'Teardown Team'];
const optionsForFinance = ['Credit', 'Invoice', 'Spent'];
const optionsForlocation = ['Thiruvallur', 'Ambattur'];

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true
});
const dataForHistory = [
  {
    date: '12-09-2023',
    description: 'description',
    remarks: 'remarks',
    status: 'status',
    assigneddate: '2-04-2001',
    targetdate: '27-04-2001'
  },
  {
    date: '12-09-2023',
    description: 'description',
    remarks: 'remarks',
    status: 'status',
    assigneddate: '2-04-2001',
    targetdate: '27-04-2001'
  }
];

const dataForproject = [
  {
    empcode: 'CAE0001',
    name: 'Ram',
    fromdate: '21-02-2001',
    todate: '21-05-2001',
    percentage: '50%',
    team: 'IT Team',
    location: 'Chennai'
  },
  {
    empcode: 'CAE0002',
    name: 'Anbu',
    fromdate: '21-02-2001',
    todate: '21-05-2001',
    percentage: '50%',
    team: 'IT Team',
    location: 'Chennai'
  }
];
const validationSchema = Yup.object({
  rfqNumber: Yup.string().required('RFQ Number is required'),
  companyName: Yup.string().required('Company Name is required'),
  assignedDate: Yup.string().required('Assigned Date is required'),
  status: Yup.string().required('Status is required'),
  description: Yup.string().required('Description is required')
  // Add validation for other fields as needed
});
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HR = ({ _history, tasks }) => {
  const navigateToTaskpage = () => {
    window.location.href = '/task-panel';
  };
  const [profilesData, setProfilesData] = useState([]);
  const [managerData, setManagerData] = useState([]);
  const [employeeDetail, setEmployeeDetail] = useState();
  const [managerId, setManagerId] = useState('');
  const handleEmployee = (e) => {
    console.log(e.target.value.slice(0, 7), 'its worked');
    const value = e.target.value;
    const filterEmployee = profilesData.filter((employee) => employee?.EmployeeCode === value.slice(0, 7));
    console.log(filterEmployee[0], 'filterEmployee');
    setManagerId(filterEmployee[0]);
  };
  const columnsForproject = [
    {
      accessorKey: 'employeeCode',
      header: 'Employee Code',
      editVariant: 'select',
      editSelectOptions: profilesData.map((item) => item.EmployeeCode + '-' + item.NameOfCandidate),
      muiEditTextFieldProps: {
        select: true,
        onChange: (e) => handleEmployee(e)
      },
      enableEditing: true
    },
    {
      accessorKey: 'fromDate',
      header: 'From Date',
      muiEditTextFieldProps: {
        type: 'date',
        required: true
      }
    },
    {
      accessorKey: 'toDate',
      header: 'To Date',
      muiEditTextFieldProps: {
        type: 'date',
        required: true
      }
    },
    {
      accessorKey: 'percentage',
      header: 'Percentage',
      muiEditTextFieldProps: {
        type: 'number',
        required: true
      },
      enableEditing: true
    },

    {
      accessorKey: 'team',
      header: 'Team',
      editVariant: 'select',
      editSelectOptions: optionsForteamproject,
      muiEditTextFieldProps: {
        select: true
      },
      enableEditing: true
    },
    {
      accessorKey: 'location',
      header: 'Location',
      editVariant: 'select',
      editSelectOptions: optionsForlocation,
      muiEditTextFieldProps: {
        select: true
      },
      enableEditing: true
    }
  ];
  const columnsForFinance = [
    {
      accessorKey: 'date',
      header: 'Date',
      muiEditTextFieldProps: {
        type: 'date',
        required: true
      }
    },
    {
      accessorKey: 'refNumber',
      header: 'Ref No/Bill',
      muiEditTextFieldProps: {
        type: 'number',
        required: true
      },
      enableEditing: true
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      muiEditTextFieldProps: {
        type: 'number',
        required: true
      },
      enableEditing: true
    },
    {
      accessorKey: 'tax',
      header: 'Tax',
      muiEditTextFieldProps: {
        type: 'number',
        required: true
      },
      enableEditing: true
    },

    {
      accessorKey: 'status',
      header: 'Status',
      editVariant: 'select',
      editSelectOptions: optionsForFinance,
      muiEditTextFieldProps: {
        select: true
      },
      enableEditing: true
    }
  ];
  const columnsForHistory = [
    {
      accessorKey: 'date',
      header: 'Date',
      muiEditTextFieldProps: {
        type: 'date',
        required: true
      }
    },
    {
      accessorKey: 'projectDescription',
      header: 'Lead Description',
      enableEditing: true
    },
    {
      accessorKey: 'requestStatus',
      header: 'Request Status',
      editVariant: 'select',
      editSelectOptions: optionsForHistoryStatus,
      muiEditTextFieldProps: {
        select: true
      },
      enableEditing: true
    },
    {
      accessorKey: 'approvalStatus',
      header: 'Approval Status',
      editVariant: 'select',
      editSelectOptions: optionsForHistoryApproval,
      muiEditTextFieldProps: {
        select: true
      },
      enableEditing: true
    }
  ];
  const columnsForTask = [
    {
      accessorKey: 'title',
      header: 'Title'
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true
    },
    {
      accessorKey: 'responsible',
      header: 'Responsible',
      enableEditing: true
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true
    },
    {
      accessorKey: 'assignedDate',
      header: 'Assigned Date',
      muiEditTextFieldProps: {
        type: 'date',
        required: true
      }
    },
    {
      accessorKey: 'targetDate',
      header: 'Target Date',
      muiEditTextFieldProps: {
        type: 'date',
        required: true
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      editVariant: 'select',
      editSelectOptions: optionsForTaskStatus,
      muiEditTextFieldProps: {
        select: true
      },
      enableEditing: true
    }
  ];
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [selectedDate, setSelectedDate] = useState('');
  const parseDate = (dateString) => {
    // Check if dateString is provided and is a non-empty string
    if (dateString && typeof dateString === 'string' && dateString.trim() !== '') {
      // Assuming dateString is in the format 'dd-MM-yyyy'
      const [day, month, year] = dateString.split('-');
      const parsedDate = new Date(`${year}-${month}-${day}`);
      return parsedDate;
    } else {
      // Return null or handle the case when dateString is not valid
      // return null;
    }
  };

  const handleEndDateChange = (event) => {
    const inputDate = new Date(event);
    const formattedDate = inputDate.toLocaleDateString('en-GB');
    const [day, month, year] = formattedDate.split('/');
    const formattedStartDate = `${day}-${month}-${year}`;
    setEndDate(formattedStartDate);
    formik.setValues({
      ...formik.values,
      targetDate: formattedStartDate
    });
  };
  const handleStartDateChange = (event) => {
    const inputDate = new Date(event);
    const formattedDate = inputDate.toLocaleDateString('en-GB');
    const [day, month, year] = formattedDate.split('/');
    const formattedStartDate = `${day}-${month}-${year}`;
    setStartDate(formattedStartDate);
    formik.setValues({
      ...formik.values,
      assignedDate: formattedStartDate
    });
  };

  const [selectedOption, setSelectedOption] = React.useState('no');

  const [mileselectedOption, setMileSelectedOption] = React.useState('');
  const [textFieldText, setTextFieldText] = React.useState('PONO Number is Not Allocated');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === 'yes') {
      setTextFieldText('');
    } else {
      setTextFieldText('PONO Number is Not Allocated');
    }
  };

  const fileInputRef = React.createRef();
  const handleImportClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        await uploadToServer(parsedData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const uploadToServer = async (data) => {
    try {
      const response = await fetch('http://localhost:3001/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data })
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const [milestone, setMilestone] = useState([]);
  const [showSelects, setShowSelects] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState('');
  const [customOptiones, setCustomOptions] = useState('');
  const [descriptionValues, setDescriptionValues] = useState({}); // State to store descriptions for options

  const [view, setView] = useState({
    visible: false,
    mode: 'Initial' // 'add', 'edit', 'view'
  });

  const handleDescriptionChange = (optionValue, description) => {
    setDescriptionValues({ ...descriptionValues, [optionValue]: description });
  };

  const [historyTableColumns, setHistoryTableColumns] = useState(columnsForHistory);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [date, setDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [localData, setLocalData] = useState('');
  const [projectData, setProjectData] = useState([]);
  const [rfqData, setRFQData] = useState([]);
  const [selectedRFQ, setSelectedRFQ] = useState('');
  const [selectedPR, setSelectedPR] = useState('');
  const [teamData, setTeamData] = useState([]);
  const [financeData, setFinanceData] = useState([]);
  const [historyTableData, setHistoryTableData] = useState([]);
  const [taskTableData, setTaskTableData] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const generateTempId = () => `temp_${Math.random().toString(36).substr(2, 9)}`;
  const [isCreatingRow, setIsCreatingRow] = useState(false);

  const formik = useFormik({
    initialValues: {
      assignedDate: '',
      targetDate: '',
      companyName: '',
      description: '',
      manager: '',
      milestone: '',
      poNumber: '',
      projectName: '',
      projectAllocation: [],
      finance: [],
      history: [],
      task: []
      // Add initial values for other fields
      // ...
    },
    // validationSchema,
    onSubmit: async (values) => {
      console.log('worked', values);
      try {
        if (editId) {
          const teamsValue = teamData
            ? teamData.map((item) => ({
                fromDate: item.fromDate,
                employeeCode: item.employeeCode?.slice(0, 7),
                employeeId: managerId?._id,
                toDate: item.toDate,
                location: item.location,
                percentage: Number(item.percentage),
                team: item.team
              }))
            : [];
          const historyValue = historyTableData
            ? historyTableData.map((data) => ({
                date: data.date,
                projectDescription: data.projectDescription,
                requestStatus: data.requestStatus,
                approvalStatus: data.approvalStatus
              }))
            : [];
          const taskValue = taskTableData
            ? taskTableData.map((data) => ({
                title: data.title,
                description: data.description,
                responsible: data.responsible,
                remarks: data.remarks,
                assignedDate: data.assignedDate,
                targetDate: data.targetDate,
                status: data.status
              }))
            : [];
          const financeValue = financeData
            ? financeData.map((data) => ({
                date: data.date,
                refNumber: data.refNumber,
                amount: data.amount,
                tax: data.tax,
                status: data.status
              }))
            : [];
          console.log(values, 'upd');
          const formattedData = {
            ...values,
            assignedDate: startDate,
            targetDate: endDate,
            companyName: selectedRFQ?.companyName,
            projectName: selectedPR,
            milestone: mileselectedOption,
            projectAllocation: teamsValue,
            finance: financeValue,
            history: historyValue,
            task: taskValue
          };
          const endpoint = PROJECT_UPDATE(editId?.original?._id);
          await updateData(endpoint, formattedData, localData?.accessToken);
          setView({
            visible: true,
            mode: 'Initial'
          });
          fetchFun();
        } else {
          console.log('handle submit', values, startDate, endDate);
          const teamsValue = teamData
            ? teamData.map((item) => ({
                fromDate: item.fromDate,
                employeeCode: item.employeeCode?.slice(0, 7),
                employeeId: managerId?._id,
                toDate: item.toDate,
                location: item.location,
                percentage: Number(item.percentage),
                team: item.team
              }))
            : [];
          const historyValue = historyTableData
            ? historyTableData.map((data) => ({
                date: data.date,
                projectDescription: data.description,
                requestStatus: data.requeststatus,
                approvalStatus: data.approvalstatus
              }))
            : [];
          const taskValue = taskTableData
            ? taskTableData.map((data) => ({
                title: data.title,
                description: data.description,
                responsible: data.responsible,
                remarks: data.remarks,
                assignedDate: data.assigneddate,
                targetDate: data.targetdate,
                status: data.status
              }))
            : [];
          const financeValue = financeData
            ? financeData.map((data) => ({
                date: data.date,
                refNumber: data.refNumber,
                amount: data.amount,
                tax: data.tax,
                status: data.status
              }))
            : [];
          const formattedData = {
            ...values,
            assignedDate: startDate,
            targetDate: endDate,
            companyName: selectedRFQ?.companyName,
            projectName: selectedPR,
            projectAllocation: teamsValue,
            finance: financeValue,
            history: historyValue,
            task: taskValue
          };
          console.log(formattedData, 'values');
          const projectAllocate = await postData(PROJECT_CREATE, formattedData, localData?.accessToken);
          console.log(projectAllocate, 'projectAllocate');
          setView({
            visible: true,
            mode: 'Initial'
          });
          fetchFun();
          console.log(data.data, 'fetched using API');
        }
      } catch (error) {
        console.error('API error:', error);
      }
    }
  });
  const [deleteId, setDeleteId] = useState('');
  const [viewId, setViewId] = useState('');
  const [editId, setEditId] = useState('');
  const [open, setOpen] = useState(false);
  const fetchFun = async () => {
    const data = await fetchData(MANAGERS_PROJECT(employeeDetail?.EmployeeCode), localData?.accessToken);
    console.log(data, 'datadatadata');
    // const data = await fetchData(PROJECT_GET, localData?.accessToken);
    setProjectData(data.data);
  };
  const handleDelete = (e) => {
    setOpen(true);
    console.log('open', e.original._id);
    setDeleteId(e.original._id);
  };
  const userData = [
    {
      name: 'John Doe',
      code: '001',
      joiningDate: '2023-01-01',
      leaveBalance: [
        { CL: 10, SL: 5, EL: 15, total: 30 }
        // Add leave balances for each month here
      ]
    }
    // Add more employees if needed
  ];
  const confirmDelete = async () => {
    try {
      const apiEndPoint = PROJECT_DELETE(deleteId);
      await deleteData(apiEndPoint, localData?.accessToken);
      fetchFun();
      setOpen(false);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const [milestoneOptions, setMilestoneOptions] = useState([]);

  const theme = useTheme();

  const handleEdit = (row) => {
    console.log('worked', row);
    setView({
      visible: true,
      mode: 'Edit'
    });
    setEditId(row);
    setMileSelectedOption(row?.original?.milestone);
    setSelectedRFQ(row?.original);
    setSelectedPR(row?.original?.projectName);
    setStartDate(row?.original?.assignedDate);
    setEndDate(row?.original?.targetDate);
    formik.setValues({
      rfqNumber: row?.original?.rfqNumber,
      // companyName: row?.original?.companyName,
      status: row?.original?.status,
      assignedDate: row?.original?.assignedDate,
      targetDate: row?.original?.targetDate,
      description: row?.original?.description,
      manager: row?.original?.manager,
      milestone: row?.original?.milestone
    });
    setTeamData(row?.original?.projectAllocation);
    setHistoryTableData(row?.original?.history);
    setFinanceData(row?.original?.finance);
    setTaskTableData(row?.original?.task);
    // setSelectedOption(row?.original?.requirePO === true ? 'yes' : 'no');
  };

  console.log(formik.values, 'formik');
  const handleView = (row) => {
    console.log('worked', row);
    setView({
      visible: true,
      mode: 'View'
    });
    setViewId(row?.original);
  };

  const [isFilter, setIsFilter] = useState('getProject');

  console.log('history---->', historyTableData);
  console.log('teams---->', teamData);
  console.log('tasks---->', taskTableData);
  console.log('finance---->', financeData);
  function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24),
    createData('Ice cream sandwich', 237, 9.0, 3),
    createData('Eclair', 262, 16.0, 24),
    createData('Cupcake', 305, 3.7, 67),
    createData('Gingerbread', 356, 16.0, 49)
  ];

  function createData(Date = '', Ref = 0, Amount = 0, Tax = 0, Status = '') {
    return { Date, Ref, Amount, Tax, Status };
  }

  console.log(startDate, '11111111111111');
  useEffect(() => {
    const fetchDataAndUpdate = async () => {
      try {
        const localStore = localStorage.getItem('userData');
        console.log(localStore, 'inside localStore');

        if (localStore) {
          setLocalData(JSON.parse(localStore));
        }
        if (localStore) {
          const parsedData = JSON.parse(localStore);
          console.log(parsedData, 'parsed');
          const EmployeeCode = await fetchData(PROFILES_GET_ID(parsedData?.employeeId), parsedData?.accessToken);
          const data = await fetchData(TASKS_GET_ALL, parsedData?.accessToken);
          console.log(data, 'datadatadata');
          const data4Rfq = await fetchData(RFQ_GET, parsedData?.accessToken);
          const data4Employee = await fetchData(PROFILES_GET_ROLE('Employee'), parsedData?.accessToken);
          const EmployeeRole = await fetchData(PROFILES_GET_ROLE('Manager'), parsedData?.accessToken);
          console.log(PROFILES_GET_ROLE, "PROFILES_GET_ROLE");
          const categoryData = await fetchData(MILESTONE_GET);
          setMilestoneOptions(categoryData);
          setManagerData(EmployeeRole?.data);
          setEmployeeDetail(EmployeeCode);
          console.log(EmployeeRole, 'EmployeeRole');
          console.log(EmployeeCode, 'EmployeeCode');
          setProfilesData(data4Employee?.data);
          setRFQData(data4Rfq?.data);
          console.log(data, 'parsedddd');
          setProjectData(data?.data);
          console.log(data?.data, 'fetched using db');
          // Fetch updateId data
        }
      } catch (error) {
        console.error('Error in fetchDataAndUpdate:', error);
      }
    };

    fetchDataAndUpdate(); // Invoke the async function
  }, []); // Add dependencies if needed
  console.log(startDate, '1 date');
  console.log(endDate, '2 date');
  return (
    <div className="max">
      {view.mode === 'Initial' && (
        <MainCard>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box
                className="pe-1"
                sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab
                    label={<div style={{ display: 'flex', alignItems: 'center' }}> Attendance Logs</div>}
                    value="1"
                    style={{ fontSize: '15px' }}
                  />
                  <Tab
                    label={<div style={{ display: 'flex', alignItems: 'center' }}> User Details</div>}
                    value="2"
                    style={{ fontSize: '15px' }}
                  />
                  <Tab
                    label={<div style={{ display: 'flex', alignItems: 'center' }}> User Leave Details</div>}
                    value="3"
                    style={{ fontSize: '15px' }}
                  />
                  <Tab
                    label={<div style={{ display: 'flex', alignItems: 'center' }}> Leave History</div>}
                    value="4"
                    style={{ fontSize: '15px' }}
                  />
                  <Tab
                    label={<div style={{ display: 'flex', alignItems: 'center' }}> Holiday Lock</div>}
                    value="5"
                    style={{ fontSize: '15px' }}
                  />
                </TabList>
              </Box>

              <TabPanel value="1">
                <Overallattendance />
                {/* <FinalAttendance/> */}
              </TabPanel>
              <TabPanel value="2">
                <Usersprofile />
              </TabPanel>
              <TabPanel value="3">
                <UserLeaveDetails data={userData} />
              </TabPanel>
              <TabPanel value="4">
                <LeaveInfo />
              </TabPanel>
              <TabPanel value="5">
                <ReactCalendar />
                {/* <Calender /> */}
              </TabPanel>
            </TabContext>
          </Box>
        </MainCard>
      )}
    </div>
  );
};
export default HR;
