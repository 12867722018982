/*eslint-disable */

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import MainCard from 'ui-component/cards/MainCard'
import Masterpage from './masterpage'
import PaymentRequest from './paymentRequest'
import Reimbursement from './reimbursement'


const Finance = () => {
  const [view, setView] = useState({
    visible: false,
    mode: 'Initial' // 'add', 'edit', 'view'
  });
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>{view.mode === 'Initial' && (
      <MainCard>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value} >
            <Box
              className="pe-1"
              sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <TabList onChange={handleChange} aria-label="lab API tabs example">

                <Tab
                  label={<div style={{ display: 'flex', alignItems: 'center' }}>Master</div>}
                  value="1"
                  style={{ fontSize: '15px' }}
                />
                <Tab
                  label={<div style={{ display: 'flex', alignItems: 'center' }}>Vendor Payments</div>}
                  value="2"
                  style={{ fontSize: '15px' }}
                />
                <Tab
                  label={<div style={{ display: 'flex', alignItems: 'center' }}>Reimbursement</div>}
                  value="3"
                  style={{ fontSize: '15px' }}
                />
              </TabList>

            </Box>
            <TabPanel value="1">
              <Masterpage />
            </TabPanel>
            <TabPanel value="2">
              <PaymentRequest />
            </TabPanel>

            <TabPanel value="3">
              <Reimbursement />
            </TabPanel>
          </TabContext>
        </Box>
      </MainCard>
    )}</div>
  )
}

export default Finance