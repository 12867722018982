/*eslint-disable */
import {
  Avatar,
  Box,
  ButtonBase,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  tableCellClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Input,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormLabel,
  FormHelperText,
  Card,
  CardContent,
  CardActions
} from '@mui/material';
import React, { forwardRef } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { IconDownload, IconEdit, IconEye, IconHistoryToggle, IconPlus, IconRefresh, IconTrash, IconUpload } from '@tabler/icons';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import * as XLSX from 'xlsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  ConnectWithoutContact,
  Delete,
  DeleteRounded,
  Group,
  History,
  KeyboardBackspaceRounded,
  Label,
  ModeEditRounded,
  NotStarted,
  PeopleAltTwoTone,
  LocationOnTwoTone,
  PersonAdd,
  TaskAlt,
  ThumbDown,
  ThumbDownAltSharp,
  ThumbUpSharp,
  VisibilityRounded,
  Money,
  CurrencyRupee,
  DeleteTwoTone,
  CreateTwoTone,
  VisibilityTwoTone,
  CurrencyExchange
} from '@mui/icons-material';
import { useState } from 'react';
import styled from '@emotion/styled';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from 'rsuite';
import {
  CUSTOMER_POST,
  LEAD_GET_BY_CODE,
  MILESTONE_CREATE,
  MILESTONE_GET,
  PROFILES_CREATE,
  PROFILES_GET,
  PROFILES_GETBY_STATUS,
  PROFILES_GET_ROLE,
  PROJECT_CREATE,
  PROJECT_DELETE,
  PROJECT_GET,
  PROJECT_UPDATE,
  RFQ_GET,
  RFQ_GET_BY_CODE,
  RFQ_GET_ID,
  RFQ_GET_STATUS
} from 'api/apiEndPoint';
import { useEffect } from 'react';
import { deleteData, fetchData, postData, updateData } from 'utils/apiUtils';

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true
});

const dataForproject = [
  {
    name: 'CAE0001',
    rfqDescription: 'Ram',
    companyName: 'capcp'
  }
];

const Customers = () => {
  const handleClose = () => {
    setView({
      visible: true,
      mode: 'Initial'
    });
  };

  const theme = useTheme();

  const [view, setView] = useState({
    visible: false,
    mode: 'Initial' // 'add', 'edit', 'view'
  });

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name'
    }),
    columnHelper.accessor('department', {
      header: 'Department'
    }),

    columnHelper.accessor('designation', {
      header: 'Designation'
    }),
    columnHelper.accessor('phone1', {
      header: 'Phone-1'
    }),
    columnHelper.accessor('phone2', {
      header: 'Phone-2'
    }),
    columnHelper.accessor('email', {
      header: 'Email'
    }),
    columnHelper.accessor('company', {
      header: 'Company'
    }),
    columnHelper.accessor('address', {
      header: 'Address'
    }),
    columnHelper.accessor('category', {
      header: 'Category'
    }),
    columnHelper.accessor('website', {
      header: 'Website'
    }),
    columnHelper.accessor('createdAt', {
      header: 'createdAt'
    })
  ];

  const table = useMaterialReactTable({
    columns,
    data: dataForproject,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableColumnPinning: true,
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] }
    },
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderRowActions: ({ row }) => (
      <div style={{ display: 'flex' }}>
        <IconButton>
          <DeleteTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
        <IconButton>
          <VisibilityTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
        <IconButton>
          <CreateTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
      </div>
    )
  });

  const handleToggle = () => {
    setView({
      visible: true,
      mode: 'Add'
    });
  };

  // post method

  const handlePost = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = CUSTOMER_POST;
      const localData = localStorage.getItem('userData');
      const parsedData = JSON.parse(localData);
      // console.log(parsedData,"parsedData");
      const token = parsedData?.accessToken;
  
      const response = await postData(apiUrl, formData, token);
      console.log(response, 'response');
    } catch (err) {
      console.error('Error:', err);
    }
  };
  
  
  const [formData, setFormData] = useState({
    name: '',
    department: '',
    designation: '',
    phone1: '',
    phone2: '',
    email: '',
    company: '',
    address: '',
    website: '',
    category: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <div className="max">
      {view.mode === 'Add' && (
        <MainCard
          title="Create Customer DB"
          secondary={
            <Box
              sx={{
                ml: 2,
                [theme.breakpoints.down('md')]: {
                  mr: 2
                }
              }}
            >
              <ButtonBase sx={{ borderRadius: '12px' }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                      background: theme.palette.secondary.dark,
                      color: theme.palette.secondary.light
                    }
                  }}
                  aria-haspopup="true"
                  onClick={handleClose}
                  color="inherit"
                >
                  <KeyboardBackspaceRounded stroke={2} size="1.3rem" />
                </Avatar>
              </ButtonBase>
            </Box>
          }
        >
          <form onSubmit={handlePost}>
            <Grid container>
              <Grid item xs={4} p={2}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Department"
                  variant="outlined"
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Designation"
                  variant="outlined"
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  name="phone1"
                  label="Phone Number1"
                  value={formData.phone1}
                  onChange={handleChange}
                  className="w-100"
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  name="phone2"
                  label="Phone Number2"
                  value={formData.phone2}
                  onChange={handleChange}
                  className="w-100"
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Company"
                  variant="outlined"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Website"
                  variant="outlined"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    <MenuItem value="OEM">OEM</MenuItem>
                    <MenuItem value="Tire1">Tire1</MenuItem>
                    <MenuItem value="Tire2">Tire2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Button variant="contained" style={{ float: 'right', margin: '2rem' }} type="submit">
              Save
            </Button>
          </form>
        </MainCard>
      )}

      {view.mode === 'Initial' && (
        <MainCard
          title="Customer DB"
          secondary={
            <div className="d-flex align-items-center ">
              <Box
                sx={{
                  ml: 2,
                  // mr: 3,
                  [theme.breakpoints.down('md')]: {
                    mr: 2
                  }
                }}
              >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.mediumAvatar,
                      transition: 'all .2s ease-in-out',
                      background: theme.palette.secondary.light,
                      color: theme.palette.secondary.dark,
                      '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light
                      }
                    }}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                  >
                    <IconPlus stroke={2} size="1.3rem" />
                  </Avatar>
                </ButtonBase>
              </Box>
            </div>
          }
        >
          <MaterialReactTable table={table} />
        </MainCard>
      )}
    </div>
  );
};
export default Customers;
