/*eslint-disable */

import 'react-calendar/dist/Calendar.css';
import './Overallattendance.css';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import InputBase from '@mui/material/InputBase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import AccessAlarmTwoToneIcon from '@mui/icons-material/AccessAlarmTwoTone';
import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import YearlyAttendance from './YearlyAttendance';
import TonalitySharpIcon from '@mui/icons-material/TonalitySharp';
import WatchLaterSharpIcon from '@mui/icons-material/WatchLaterSharp';
import {
  DAILY_ATTENDANCE,
  MONTHLY_ATTENDANCE,
  LEAVE_OB,
  Team,
  ATTENDANCE_BY_TEAM,
  TEAM_GET_ALL,
  LOCATION,
  LOCATION_GET,
  LOCATION_FORATTENDANCE,
  MONTHLY_POST,
  DAILY_FINALATTENDANCE,
  LEAVES_CHECK,
  LEAVE_BALANCE_ALL,
  PROFILES_GET_ID,
  PROFILES_GET,
  MONTHLY_LEAVEBAL
} from 'api/apiEndPoint';
import { fetchData } from 'utils/apiUtils';
import UpdateIcon from '@mui/icons-material/Update';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IconDownload } from '@tabler/icons';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import MonthlyCalendar from './finalMonthlyView';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { DateRangePicker } from 'rsuite';
import { DatePicker } from '@mui/lab';
import { fetchKeyValueById } from 'utils/FetchkeyValue';

const InTime = () => (
  <span role="img" aria-label="snail" style={{ marginTop: '10px', fontSize: '15px' }}>
    ⏱️
  </span>
);
const OutTime = () => (
  <span role="img" aria-label="snail" style={{ marginTop: '10px', fontSize: '15px' }}>
    💼
  </span>
);
const TotalWorkingHours = () => (
  <span role="img" aria-label="rocket" style={{ marginTop: '10px', fontSize: '15px' }}>
    ⌛
  </span>
);

const Overallattendance = () => {
  const [employeeDatas, setEmployeeDatas] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [Department, setDepartment] = useState('');
  const [teamData, setTeamData] = useState([]);
  const [localData, setLocalData] = useState();

  const handleChanged = (event) => {
    setDepartment(event.target.value);
  };

  const localStore = () => {
    const localDatas = localStorage.getItem('userData');
    if (localDatas) {
      const parsedData = JSON.parse(localDatas);
      setLocalData(parsedData);
    }
  };

  useEffect(() => {
    localStore();
    const fetchDataAndUpdate = async () => {
      try {
        if (localStore) {
          const date = new Date();
          const localDatas = localStorage.getItem('userData');
          const parsedData = JSON.parse(localDatas);
          setLocalData(parsedData);
          const team = parsedData?.team; // Assuming parsedData contains the team information
          const apiUrl = ATTENDANCE_BY_TEAM(date, team);
          console.log('dddddd', apiUrl);
          const data = await fetchData(apiUrl);
          const profilesData = await fetchData(PROFILES_GET, parsedData?.accessToken);
          setEmployeeDatas(data?.[0]);
          setProfiles(profilesData?.data);
          console.log('dataaaaaaaaaaaa', profilesData);
        }
      } catch (err) {
        console.error('Error fetching attendance data:', err);
      }
    };

    fetchDataAndUpdate();
  }, []);

  console.log(localData, 'local data from local storage');

  // useEffect(() => {

  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('LEAVE_OB');
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const responseData = await response.json();
  //       setFormData(responseData);
  //     } catch (error) {
  //       console.log('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const localStore = localStorage.getItem('userData');
        const parsed = JSON.parse(localStore);
        setLocalData(parsed);
        const data = await fetchData(TEAM_GET_ALL, parsed?.accessToken);
        console.log(data, 'data received from the server.');
        const teamActive = data.filter((data) => data.active === true);
        setTeamData(teamActive);
      } catch (error) {
        console.log('Error fetching teams:', error);
      }
    };
    fetchTeams();
  }, []);

  const [locationData, setLocationData] = useState();
  const [locationInfoData, setLocationInfoData] = useState([]);
  const [employeeDatass, setEmployeeDatass] = useState(null);
  const [location, setLocation] = useState();
  const [monthlyAttendance, setMonthlyAttendance] = useState([]);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');

  const currentYear = currentDate.getFullYear();

  const [selectedMonths, setSelectedMonths] = useState(month);
  const [selectedYears, setSelectedYears] = useState(year);
  const [selectedMonthNonZero, setSelectedMonthNonZero] = useState('');
  const [dataFromDB, setDataFromDB] = useState([]);
  // const localStoreforLocation = () => {
  //   const localDatas = localStorage.getItem('userData');
  //   if (localDatas) {
  //     const parsedData = JSON.parse(localDatas)
  //     setLocationInfoData(parsedData)
  //   }
  // }

  const filterByTeamLoc = useMemo(() => {
    console.log('leave :---->', dataFromDB);
    // Step 1: Update dataFromDB locations based on matching profile IDs
    const updatedDataFromDB = dataFromDB.map((obj) => {
      // Find the profile that matches the employee_id
      const matchingProfile = profiles?.find((profile) => profile._id === obj.employee_id);

      // If a matching profile is found, update the employee_location
      return {
        ...obj,
        employee_location: matchingProfile ? matchingProfile.Location : obj.employee_location
      };
    });
    console.log('updatedDataFromDB', updatedDataFromDB);

    // Step 2: Filter the updated data based on selected Department and location
    const filteredData = updatedDataFromDB.filter((obj) => {
      return (!Department || obj.employee_team === Department) && (!location || obj.employee_location === location);
    });

    return filteredData;
  }, [dataFromDB, profiles, Department, location]);

  console.log(dataFromDB, '<<<<------dataFromDB here');
  console.log(filterByTeamLoc, '<<<<------dataFromDB filter');
  console.log('selected area', location, Department);
  const handleLocationChanged = (event) => {
    setLocation(event.target.value);
  };
  console.log('selectedMonthNonZero', selectedMonthNonZero);
  useEffect(() => {
    localStore();
    const fetchDataAndUpdates = async () => {
      try {
        if (localStore) {
          const date = new Date();
          const location = locationData?.location;
          console.log(location, 'location');
          const apiUrl = LOCATION_FORATTENDANCE(date, location);
          console.log('ooooooooooooo', apiUrl);
          const data = await fetchData(apiUrl);

          setEmployeeDatass(data?.[0]);
          console.log('leave ===', data4Leave);
        }
      } catch (err) {
        console.error('Error fetching attendance data:', err);
      }
    };

    fetchDataAndUpdates();
  }, []);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const localStore = localStorage.getItem('userData');
        const parsed = JSON.parse(localStore);
        setLocationData(parsed);
        const data = await fetchData(LOCATION_GET, parsed?.accessToken);
        console.log(data, 'data received from the server.');
        setLocationInfoData(data);
      } catch (error) {
        console.log('Error fetching location:', error);
      }
    };
    fetchLocation();
  }, []);

  const employeeDate = `${year}-${month}-${day}`;

  const [attendanceData, setAttendanceData] = useState([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'employee_code',
        header: 'Employee Code',
        size: 100
      },
      {
        accessorKey: 'employee_name',
        header: 'Emp Name',
        size: 150
      },
      {
        accessorKey: 'employee_location',
        header: 'Emp Location',
        size: 150
      },
      {
        accessorKey: 'employee_team',
        header: 'Emp Team',
        size: 150
      },
      {
        accessorKey: 'employee_title',
        header: 'Employee Title ',
        size: 150
      },
      {
        accessorKey: 'date',
        header: 'Attendance Date',
        size: 150
      },
      {
        accessorKey: 'clock_in',
        header: 'In Time',
        size: 150
      },
      {
        accessorKey: 'clock_out',
        header: 'Out Time',
        size: 150
      },
      // {
      //   accessorKey: 'clock_outlocation',
      //   header: 'Clock OutLocation',
      //   size: 150
      // },
      // {
      //   accessorKey: 'late_attendance',
      //   header: 'Late Attendance',
      //   size: 150
      // },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 300,
        Cell: ({ value, row }) => (
          <div>
            {row?.original?.status === 'Present' && (
              <Button
                variant="outlined"
                style={{
                  borderRadius: '15px',
                  backgroundColor: '#b9f6ca',
                  color: '#00c857',
                  border: '2px solid #00c857'
                }}
              >
                Present
              </Button>
            )}
            {row?.original?.status === 'Absent' && (
              <Button
                variant="outlined"
                style={{
                  borderRadius: '15px',
                  backgroundColor: '#ef9a9a',
                  color: '#c6282f',
                  border: '2px solid #c6282f'
                }}
              >
                Absent
              </Button>
            )}
            {row?.original?.status === 'Compoff' && (
              <Button
                variant="outlined"
                style={{
                  borderRadius: '15px',
                  backgroundColor: '#fff8e1',
                  color: '#9b7b15',
                  border: '2px solid #9b7b15'
                }}
              >
                Comp-Off
              </Button>
            )}
            {row?.original?.status === 'On Duty' && (
              <Button
                variant="outlined"
                style={{
                  borderRadius: '15px',
                  backgroundColor: '#9af5e8',
                  color: '#067667',
                  border: '2px solid #067667'
                }}
              >
                On-Duty
              </Button>
            )}
            {row?.original?.status === 'CL' && (
              <Button
                variant="outlined"
                style={{
                  borderRadius: '15px',
                  backgroundColor: '#ede7f5',
                  color: '#673ab7',
                  border: '2px solid #673ab7'
                }}
              >
                CL
              </Button>
            )}
            {row?.original?.status === 'SL' && (
              <Button
                variant="outlined"
                style={{
                  borderRadius: '15px',
                  backgroundColor: '#e3f2fd',
                  color: '#0194ff',
                  border: '2px solid #0194ff'
                }}
              >
                SL
              </Button>
            )}
            {row?.original?.status === 'EL' && (
              <Button
                variant="outlined"
                style={{
                  borderRadius: '15px',
                  backgroundColor: '#f1baf1',
                  color: '#f30af3',
                  border: '2px solid #f30af3'
                }}
              >
                EL
              </Button>
            )}
          </div>
        )
      }
    ],
    []
  );
  const dataWithSNo = useMemo(() => {
    return attendanceData.map((obj, index) => ({ ...obj, SNo: index + 1 }));
  }, [attendanceData]);

  const handleExportDataForDaily = () => {
    const toLocalTime = (time, timeZone = 'Asia/Kolkata') => {
      if (time) {
        const date = new Date(time);
        const options = {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          timeZone
        };
        return date.toLocaleTimeString('en-US', options);
      }
      return '';
    };

    const filterData = (data) => {
      return data.map(
        ({
          _id,
          employee_id,
          logid,
          factor,
          sickleave,
          earnedleave,
          casualleave,
          compoff,
          holidaywork,
          __v,
          SNo,
          employee_code,
          employee_name,
          employee_team,
          employee_location,
          employee_title,
          date,
          clock_in,
          clock_out,
          status,
          ...rest
        }) => {
          const localClockIn = toLocalTime(clock_in);
          const localClockOut = toLocalTime(clock_out);

          // Calculate TotalHours assuming clock_in and clock_out are in milliseconds since the epoch
          const totalHours = (new Date(clock_out) - new Date(clock_in)) / (1000 * 60 * 60);

          // Convert totalHours to hh:mm:ss format
          const hours = Math.floor(totalHours);
          const minutes = Math.floor((totalHours - hours) * 60);
          const seconds = Math.floor(((totalHours - hours) * 60 - minutes) * 60);
          const formattedTotalHours = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;

          return {
            employee_code,
            employee_name,
            employee_team,
            employee_location,
            employee_title,
            date,
            clock_in: localClockIn,
            clock_out: localClockOut,
            TotalHours: formattedTotalHours,
            status,
            ...rest
          };
        }
      );
    };

    const filteredData = filterData(dataWithSNo);
    const csv = generateCsv(csvConfig)(filteredData);
    download(csvConfig)(csv);
  };

  console.log('adataWithSNo----->>>', dataWithSNo.slice(0, 10));
  const table = useMaterialReactTable({
    columns,
    data: dataWithSNo,
    columnFilterDisplayMode: 'popover'
  });

  useEffect(() => {
    const fetchDataAndUpdate = async (employeeDate) => {
      try {
        const localStore = localStorage.getItem('userData');
        if (localStore) {
          const parsedData = JSON.parse(localStore);
          const apiUrl = DAILY_FINALATTENDANCE(employeeDate);
          console.log('API Endpoint:', apiUrl);
          const data = await fetchData(apiUrl);
          setAttendanceData(data);
          console.log('Parsed Data:', parsedData);
        }
      } catch (err) {
        console.error('Error fetching attendance data:', err);
      }
    };

    fetchDataAndUpdate(employeeDate);
  }, []);

  console.log('dataFromDB', selectedMonths, selectedYears);

  useEffect(() => {
    const fetchDataAndUpdateMonthly = async (selectedMonths, selectedYear) => {
      try {
        const localStore = localStorage.getItem('userData');
        if (localStore) {
          const parsedData = JSON.parse(localStore);
          console.log(parsedData, 'localStore');
          const struct = parseInt(selectedMonths, 10);
          setSelectedMonthNonZero(struct - 1);
          const apiUrl = MONTHLY_ATTENDANCE(selectedMonths, selectedYear);
          // Convert selectedMonths to a number and then to a string to remove leading zeros
          // console.log('API Endpoint:', apiUrl);
          const data = await fetchData(apiUrl);
          console.log(data, '<-------------data');
          setDataFromDB(data);
          const filteredData = data.map((attendanceData) => ({
            ...attendanceData,
            attendance: attendanceData.attendance.map((attendance) => ({
              ...attendance,
              status: isPastMonth(selectedMonths, selectedYears) ? attendance.status : 'Leave'
            }))
          }));
          setMonthlyAttendance(filteredData);
        }
      } catch (err) {
        console.error('Error fetching attendance data:', err);
      }
    };
    fetchDataAndUpdateMonthly(selectedMonths, selectedYears);
  }, [selectedMonths, selectedYears]);

  const isPastMonth = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    console.log('currentMonth', currentMonth, currentYear);
    return selectedYears < currentYear || (selectedYears === currentYear && selectedMonths <= currentMonth);
  };

  const abbr = [
    { key: 'CL', value: 'Casual Leave' },
    { key: 'SL', value: 'Sick Leave' },
    { key: 'EL', value: 'Earned Leave' }
  ];

  const rowData = monthlyAttendance.map((attendanceData) => {
    console.log(attendanceData, 'attendanceData');
    const presentRecords = attendanceData.attendance.filter(
      (attendance) => attendance.status === 'Present' || attendance.status === 'On Duty' || attendance.status === 'Compoff Earnings'
    );

    const totalCE = attendanceData.attendance.filter((attendance) => attendance.status === 'Compoff Earnings').length;

    const presentDaysCount = new Set(presentRecords.map((record) => record.date)).size;

    console.log('attendanceData', attendanceData);

    return {
      name: attendanceData.employee_name,
      code: attendanceData.attendance.length > 0 ? attendanceData.attendance[0].employee_code : '',
      present: presentDaysCount,
      TotalCE: totalCE,
      InTime: attendanceData.attendance.length > 0 ? attendanceData.attendance[0].clock_in : '',
      outTime: attendanceData.attendance.length > 0 ? attendanceData.attendance[0].clock_out : '',
      values: attendanceData.attendance.map((attendance) => {
        switch (attendance.status) {
          case 'Leave':
            const leaveAbbr = abbr.find((ab) => ab.key === attendance.leave_type);
            return leaveAbbr ? leaveAbbr.key : 'L';
          case 'Holiday':
            return 'HL';
          case 'Casual Leave':
            return 'CL';
          case 'Sick Leave':
            return 'SL';
          case 'Earned Leave':
            return 'EL';
          case 'Compoff Adjustment':
            return 'CA';
          case 'Compoff Earnings':
            return 'CE';
          case 'CE':
            return 'CE';
          case 'On Duty':
            return 'OD';
          case 'Loss of Pay':
            return 'LOP';
          case 'Uninformed Leave':
            return 'UN';
          default:
            return 'P';
        }
      }),
      leaveData: {
        sickleave: attendanceData.attendance.length > 0 ? attendanceData.attendance[0].sickleave : '',
        casualleave: attendanceData.attendance.length > 0 ? attendanceData.attendance[0].casualleave : '',
        earnedleave: attendanceData.attendance.length > 0 ? attendanceData.attendance[0].earnedleave : ''
      }
    };
  });

  const getColorBasedOnContent = (content) => {
    switch (content) {
      case 'P':
        return '#b9f6ca'; // Present
      case 'HL':
        return '#e3f2fd'; // Holiday
      case 'SL':
        return '#ffdfea'; // Sick Leave
      case 'CL':
        return '#ede7f6'; // Casual Leave
      case 'EL':
        return '#ffece8'; // Earned Leave
      case 'CA':
        return '#f5c7c7';
      case 'CE':
        return '#f1baf1';
      case 'OD':
        return '#9af5e8';
      case 'LOP':
        return '#FFF8E1';
      case 'UN':
        return '#afb7f3';
      case null:
      case '':
        return '#f0f0f0'; // Some default color for empty cells
      default:
        return '#ffcccc'; // Leave for all other cases
    }
  };

  const rowsByColor = {
    '#b9f6ca': [], // P (Present)
    '#ffcccc': [], // Leave (All other values)
    '#e3f2fd': [], // HL (Holiday)
    '#ffdfea': [], // SL (Sick Leave)
    '#ede7f6': [], // CL (Casual Leave)
    '#ffece8': [], // EL (Earned Leave)
    '#d3f1a7': [],
    '#f1baf1': [], //CE
    '#9af5e8': [], //OD
    '#f5c7c7': [], // CA
    '#FFF8E1': [], // LOP
    '#afb7f3': [] // UN
  };

  // Now, categorize rows based on their color
  rowData.forEach((row) => {
    row.values.forEach((value, index) => {
      const color = getColorBasedOnContent(value);
      rowsByColor[color].push({
        name: row.name,
        code: row.code,
        status: value
      });
    });
  });

  // Organizing rows by code
  const rowsByCode = {};

  rowData.forEach((row) => {
    const { code } = row;
    if (!rowsByCode[code]) {
      rowsByCode[code] = [];
    }
    rowsByCode[code].push(row);
  });
  const [selectedYear, setSelectedYear] = useState(null);
  const [months, setMonths] = useState([]);
  const [dates, setDates] = useState([]);
  const [profilesData, setProfilesData] = useState();
  const [selectedMonth, setSelectedMonth] = useState(0);

  useEffect(() => {
    const monthNames = Array.from({ length: 12 }, (_, index) => {
      const date = new Date(0, index);
      return date.toLocaleString('default', { month: 'short' });
    });
    setMonths(monthNames);
    setSelectedYear(currentYear);
  }, [currentYear]);

  useEffect(() => {
    generateDates(selectedYear, selectedMonth);
  }, [selectedYear, selectedMonth]);

  const generateDates = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const datesArray = Array.from({ length: daysInMonth }, (_, index) => {
      const date = new Date(year, month, index + 1);
      return {
        date: date.toLocaleDateString('default', { day: 'numeric' }),
        day: date.toLocaleDateString('default', { weekday: 'short' })
      };
    });
    setDates(datesArray);
  };

  const handleArrowClick = (direction) => {
    let newMonth = selectedMonths;
    let newYear = selectedYears;
    if (direction === 'previous') {
      if (selectedMonths === '01') {
        newMonth = '12';
        newYear = parseInt(selectedYears, 10) - 1;
      } else {
        const prevMonth = parseInt(selectedMonths, 10) - 1;
        newMonth = prevMonth < 10 ? `0${prevMonth}` : `${prevMonth}`;
      }
    } else {
      if (selectedMonths === '12') {
        newMonth = '01';
        newYear = parseInt(selectedYears, 10) + 1;
      } else {
        const nextMonth = parseInt(selectedMonths, 10) + 1;
        newMonth = nextMonth < 10 ? `0${nextMonth}` : `${nextMonth}`;
      }
    }

    if (newMonth === '01' && direction === 'next') {
      newYear = parseInt(selectedYears, 10) + 1;
    } else if (newMonth === '12' && direction === 'previous') {
      newYear = parseInt(selectedYears, 10) - 1;
    }
    setSelectedMonths(newMonth);
    setSelectedYears(newYear);
  };
  console.log('selectedMonthNonZero', selectedMonthNonZero);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    console.log('handleYearChange', event);
  };

  const [Dept, setDept] = React.useState('');

  const handleChange = (event) => {
    setDept(event.target.value);
  };
  const [loc, setLoc] = React.useState('');

  const handleChanges = (event) => {
    setLoc(event.target.value);
  };

  const [view, setView] = useState('daily');

  const handleTabChange = (selectedView) => {
    setView(selectedView);
  };

  // Given in-time and out-time strings
  const inTime = '9:10 AM';
  const outTime = '17:10 PM';

  // Parse the time strings into hours and minutes
  const [inHour, inMinute] = inTime.split(/:| /).map(Number);
  const [outHour, outMinute] = outTime.split(/:| /).map(Number);

  // Calculate the total working hours
  const totalHours = outHour - inHour + (outMinute - inMinute) / 60;

  // Display the total working hours
  console.log(`Total Working Hours: ${totalHours.toFixed(2)} hrs`);

  // const handleExportData = () => {
  //   const tableData = rowData.map((row) => {
  //     const rowDataWithDates = {
  //       'Employee Code': row.code,
  //       'Employee Name': row.name
  //     };

  //     if (row.values) {
  //       const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  //       const currentYear = new Date().getFullYear();

  //       row.values.forEach((value, index) => {
  //         const date =
  //           value instanceof Date ? `${value.getDate().toString().padStart(2, '0')}-${months[value.getMonth()]}-${currentYear}` : value;
  //         const formattedDate = index === 0 && value instanceof Date ? formatDateWithMonth(value) : value;
  //         const monthString = value instanceof Date ? months[value.getMonth()] : ''; // Get month name from months array
  //         const yearString = value instanceof Date ? currentYear : ''; // Use current year if value is a Date object
  //         rowDataWithDates[` ${index + 1}-${selectedMonths || monthString}-${currentYear}`] = date; // Include current year
  //       });
  //       rowDataWithDates['Total Present'] = row.present;
  //       rowDataWithDates['Total CE'] = row.TotalCE;

  //       rowDataWithDates['SL'] = leavesCheck?.sickLeave;
  //       rowDataWithDates['CL'] = leavesCheck?.casualleave;
  //       rowDataWithDates['EL'] = leavesCheck?.earnedLeave;
  //     }

  //     return rowDataWithDates;
  //   });

  //   const csvConfig = mkConfig({
  //     fieldSeparator: ',',
  //     decimalSeparator: '.',
  //     useKeysAsHeaders: true
  //   });

  //   const csvContent = generateCsv(csvConfig)(tableData);

  //   download(csvConfig)(csvContent);
  // };

  const maxValuesLength = Math.max(...rowData.map((row) => row.values.length));

  const [leavesCheck, setLeavesCheck] = useState();

  useEffect(() => {
    const fetchDataAndUpdate = async () => {
      try {
        const localStore = localStorage.getItem('userData');
        if (localStore) {
          const parsedData = JSON.parse(localStore);
          const apiUrl = LEAVE_BALANCE_ALL;
          const data = await fetchData(apiUrl);
          console.log(data, 'data');
          const restructuredLeavesCheck = {};
          data.forEach((employee) => {
            const employeeId = employee.employeeId;
            const employeeLeaves = {
              sickLeave: employee.sickLeave,
              casualleave: employee.casualleave,
              earnedLeave: employee.earnedLeave
            };
            restructuredLeavesCheck[employeeId] = employeeLeaves;
          });

          setLeavesCheck(restructuredLeavesCheck);
        }
      } catch (err) {
        console.error('Error fetching and updating leave data:', err);
      }
    };

    fetchDataAndUpdate();
  }, []);
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true
  });

  const generateCSVData = () => {
    const csvData = [];

    // Add headers
    const headers = ['Name', 'Code', 'CL', 'SL', 'EL' /* add other headers here */];
    csvData.push(headers);

    // Add rows
    dataFromDB.forEach((employee) => {
      const rowData = [
        employee.employee_name,
        employee.employee_code,
        employee.balance?.Openingcl,
        employee.balance?.Openingsl,
        employee.balance?.Openingel
        /* add other data here */
      ];
      csvData.push(rowData);
    });

    return csvData;
  };

  console.log('dataFromDB2', profilesData);
  console.log('dataFromDB1', dataFromDB);
  const updateLocationAndTeamInDataFromDB = (dataFromDB, profilesData) => {
    const profilesMap = new Map();
  
    // Create a map for quick lookup using _id
    profilesData?.forEach(profile => {
      profilesMap.set(profile._id, { location: profile.Location, team: profile.Team });
    });
    // Update dataFromDB with the correct location and team
    const updatedDataFromDB = dataFromDB.map(employee => {
      const profileData = profilesMap.get(employee.employee_id);
  
      if (profileData) {
        // Update employee location and team if matching profile found
        employee.employee_location = profileData.location;
        employee.employee_team = profileData.team;
  
        // Also update the location and team in each attendance record
        if (employee.attendance && employee.attendance.length > 0) {
          employee.attendance.forEach(attendance => {
            attendance.employee_location = profileData.location;
            attendance.employee_team = profileData.team;
          });
        }
      }
  
      return employee;
    });
  
    return updatedDataFromDB;
  };
  // Example usage
  const updatedDataFromDB = updateLocationAndTeamInDataFromDB(dataFromDB, profilesData);
  console.log('Updated dataFromDB:', updatedDataFromDB);
  // Then you can use this updated data for further processing or exporting
  
  const handleExportData = () => {
    if (!dataFromDB || dataFromDB.length === 0) {
      console.error('No data available for export.');
      return;
    }

    // Sort dataFromDB by employee_code in ascending order
    dataFromDB.sort((a, b) => a.employee_code.localeCompare(b.employee_code));

    // Collect all unique dates from data
    const allDates = Array.from(
      new Set(
        updatedDataFromDB.reduce((dates, employee) => {
          return dates.concat(employee.attendance.map((a) => a.date));
        }, [])
      )
    );

    allDates.sort((a, b) => new Date(a) - new Date(b));
    console.log('Sorted dates:', allDates);

    const flattenedData = updatedDataFromDB.map((employee) => {
      let totalCL = 0;
      let totalSL = 0;
      let totalEL = 0;
      let totalPresent = 0;
      let totalHL = 0;
      let totalCE = 0;
      let totalLOP = 0;
      let totalCA = 0;

      const employeeData = {
        employee_code: employee.employee_code,
        employee_name: employee.employee_name,
        team: employee.employee_team,
        location: employee.employee_location
      };

      allDates.forEach((date) => {
        const attendanceRecord = employee.attendance.find((a) => a.date === date);
        let abbreviatedStatus = '';

        if (attendanceRecord) {
          const status = attendanceRecord.status;
          // Mapping status to abbreviated form
          switch (status) {
            case 'Present':
              abbreviatedStatus = 'P';
              totalPresent++;
              break;
            case 'Leave':
              abbreviatedStatus = 'L';
              break;
            case 'Casual Leave':
            case 'CL':
              abbreviatedStatus = 'CL';
              totalCL++;
              break;
            case 'Loss of Pay':
            case 'LOP':
              abbreviatedStatus = 'LOP';
              totalLOP++;
              break;
            case 'Sick Leave':
            case 'SL':
              abbreviatedStatus = 'SL';
              totalSL++;
              break;
            case 'Earned Leave':
            case 'EL':
              abbreviatedStatus = 'EL';
              totalEL++;
              break;
            case 'Holiday':
              abbreviatedStatus = 'HL';
              totalHL++;
              break;
            case 'On Duty':
              abbreviatedStatus = 'OD';
              totalPresent++;
              break;
            case 'Compoff Earnings':
            case 'Compoff Earning':
            case 'CE':
              abbreviatedStatus = 'CE';
              totalCE++;
              totalPresent++;
              break;
            case 'Compoff Adjustment':
              abbreviatedStatus = 'CA';
              totalCA++;
              totalPresent++;
              break;
            case 'Absent':
              abbreviatedStatus = 'A';
              break;
            default:
              abbreviatedStatus = status;
              break;
          }
        }

        employeeData[date] = abbreviatedStatus;
      });

      const closingCL = (employee.balance?.Openingcl ? Number(employee.balance.Openingcl) : 0) - totalCL;
      const closingSL = (employee.balance?.Openingsl ? Number(employee.balance.Openingsl) : 0) - totalSL;
      const closingEL = (employee.balance?.Openingel ? Number(employee.balance.Openingel) : 0) - totalEL;

      const totalLeave = totalCL + totalSL + totalEL;
      const TotalLOP = totalLOP;

      employeeData.TotalPresent = totalPresent;
      employeeData.TotalHL = totalHL;
      employeeData.TotalCLSLEL = totalLeave;
      const PayableDays = totalPresent + totalHL + totalLeave;
      employeeData.PayableDays = PayableDays;
      employeeData.TotalLOP = TotalLOP;

      // Add opening balances at the end
      employeeData.Openingcl = employee.balance ? employee.balance.Openingcl : '';
      employeeData.Openingsl = employee.balance ? employee.balance.Openingsl : '';
      employeeData.Openingel = employee.balance ? employee.balance.Openingel : '';

      employeeData.Closingcl = closingCL;
      employeeData.Closingsl = closingSL;
      employeeData.Closingel = closingEL;

      return employeeData;
    });

    console.log('Flattened data:', flattenedData);

    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Attendance Data');

    const wbBinary = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbBinary)], { type: 'application/octet-stream' });
    const csvURL = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = csvURL;
    link.setAttribute('download', `attendance_data_${year}_${month}.xlsx`);
    link.click();

    window.URL.revokeObjectURL(csvURL);
  };

  // Utility function to convert string to array buffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  function calculateTotalWorkingHours(inTime, outTime) {
    if (!inTime || !outTime) {
      return 0;
    } else {
      const inTimeDate = new Date(inTime);
      const outTimeDate = new Date(outTime);
      const timeDifferenceMs = outTimeDate - inTimeDate;
      const hours = timeDifferenceMs / (1000 * 60 * 60);
      return Math.floor(hours); // Round down to the nearest integer
    }
  }

  const renderCalendarView = () => {
    switch (view) {
      case 'daily':
        return (
          <div>
            <div style={{ marginTop: '40px', marginBottom: '10px' }}>
              {/* SNo, EmpCode, Date, Name, Shift, Intime, Outtime, Status, Action */}
              <MaterialReactTable table={table} />
            </div>
          </div>
        );
      case 'monthly':
        return (
          <div style={{ marginTop: '20px' }}>
            <MonthlyCalendar data={filterByTeamLoc} year={selectedYear} month={selectedMonthNonZero} />
          </div>
        );
      case 'yearly':
        return (
          <div>
            <YearlyAttendance />
          </div>
        );
      default:
        return null;
    }
  };

  console.log(selectedMonths, selectedYears, 'selectedbro');

  // const handleTabChange = (tab) => {
  //   setSelectedTab(tab);
  // };

  const [assignedDate, setAssignedDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleAssignedDateChange = (event) => {
    setAssignedDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const employeeData2 = async (item) => {
    console.log('Item in employeeData2:', item);

    let reportingManager = '';

    if (item.reportingManager) {
      try {
        console.log('Fetching reporting manager for ID:', item.reportingManager);
        reportingManager = await fetchKeyValueById(item.reportingManager, PROFILES_GET_ID, 'NameOfCandidate');
        console.log('Reporting manager fetched:', reportingManager);
      } catch (error) {
        console.error('Error fetching reporting manager:', error);
      }
    } else {
      console.log('Reporting manager ID is undefined.');
    }

    return {
      ...item,
      reportingManager
    };
  };

  const handleExport = async () => {
    console.log('Attendance Data:', dataFromDB);

    if (!assignedDate || !endDate) {
      console.error('Both dates must be selected');
      return;
    }

    // Ensure dates cover the full day range for accurate comparison
    const assignedDateObj = new Date(assignedDate);
    assignedDateObj.setUTCHours(0, 0, 0, 0);

    const endDateObj = new Date(endDate);
    endDateObj.setUTCHours(23, 59, 59, 999);

    console.log('Start Date:', assignedDateObj.toISOString());
    console.log('End Date:', endDateObj.toISOString());

    const filteredData = await Promise.all(
      dataFromDB?.flatMap(async (item) => {
        console.log('Processing item:', item);

        if (!Array.isArray(item.attendance)) {
          console.error('Attendance is not an array for item:', item);
          return [];
        }

        const updatedItem = await employeeData2(item);

        return item.attendance
          .filter((attendanceItem) => {
            const dateProp = attendanceItem.date;
            if (!dateProp) {
              console.error('Date property is missing in attendance item:', attendanceItem);
              return false;
            }

            const itemDate = new Date(dateProp).setUTCHours(0, 0, 0, 0);
            const withinRange = itemDate >= assignedDateObj.getTime() && itemDate <= endDateObj.getTime();

            if (!withinRange) {
              console.log(
                `Item date ${new Date(
                  itemDate
                ).toISOString()} is out of range (${assignedDateObj.toISOString()} - ${endDateObj.toISOString()})`
              );
            }

            return withinRange;
          })
          .map((filteredAttendanceItem) => {
            console.log('Processing Attendance Item:', filteredAttendanceItem);

            const clockIn = filteredAttendanceItem.clock_in;
            const clockOut = filteredAttendanceItem.clock_out;
            const date = filteredAttendanceItem.date;
            const status = filteredAttendanceItem.status;

            const formattedClockIn = clockIn
              ? new Date(clockIn).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
              : '';
            const formattedClockOut = clockOut
              ? new Date(clockOut).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
              : '';

            let totalTime = '';
            if (clockIn && clockOut) {
              const clockInDate = new Date(clockIn);
              const clockOutDate = new Date(clockOut);
              const diffMs = clockOutDate - clockInDate;
              const hours = Math.floor(diffMs / (1000 * 60 * 60));
              const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
              totalTime = `${hours} Hrs. ${minutes} Min.`;
            }

            const result = {
              EmployeeCode: updatedItem.employee_code,
              EmployeeName: updatedItem.employee_name,
              EmployeeTeam: updatedItem.employee_team,
              EmployeeLocation: updatedItem.employee_location,
              AttendanceApprover: updatedItem.reportingManager, // Changed key name
              AttendanceDate: date || undefined,
              Status: status || undefined,
              ClockIn: formattedClockIn || undefined,
              ClockOut: formattedClockOut || undefined,
              TotalWorkingHours: totalTime || undefined
            };

            return result;
          });
      })
    );

    const flatFilteredData = filteredData.flat();
    console.log('Filtered Data Before Sorting:', flatFilteredData);

    const validFilteredData = flatFilteredData.filter((item) => item.EmployeeCode);
    console.log('Valid Filtered Data:', validFilteredData);

    const groupedByDate = validFilteredData.reduce((acc, item) => {
      (acc[item.AttendanceDate] = acc[item.AttendanceDate] || []).push(item);
      return acc;
    }, {});

    console.log('Grouped Data:', groupedByDate);

    const sortedData = Object.keys(groupedByDate)
      .sort()
      .flatMap((date) =>
        groupedByDate[date].sort((a, b) => {
          if (!a.EmployeeCode || !b.EmployeeCode) {
            console.error('Missing EmployeeCode for sorting:', a, b);
            return 0;
          }
          return a.EmployeeCode.localeCompare(b.EmployeeCode);
        })
      );

    console.log('Sorted Data:', sortedData);

    const ws = XLSX.utils.json_to_sheet(sortedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Attendance Data');

    XLSX.writeFile(wb, 'AttendanceData.xlsx');

    console.log('Excel file has been generated.');
  };

  const fetchProf = async () => {
    const localStore = localStorage.getItem('userData');
    const parsedData = JSON.parse(localStore);
    const fetchProfile = await fetchData(PROFILES_GET, parsedData.accessToken);
    setProfilesData(fetchProfile?.data);
  };
  useEffect(() => {
    fetchProf();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between align-item-center">
        <h5>Employees</h5>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '25ch',
            border: '1px solid #ccc',
            padding: '5px',
            borderRadius: '20px',
            marginRight: '35rem',
            marginLeft: '10px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <IconButton
              onClick={() => {
                console.log('Previous arrow clicked');
                handleArrowClick('previous', selectedMonths, selectedYears);
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <div>
              {months[selectedMonths - 1]}
              {console.log('Selected month:', months[selectedMonths - 1])}
            </div>

            <Select
              value={selectedYear}
              onChange={handleYearChange}
              variant="outlined"
              input={<InputBase sx={{ border: 'none' }} />}
              sx={{ minWidth: '80px', border: 'none' }}
            >
              {[currentYear - 1, currentYear, currentYear + 1].map((year) => {
                console.log('Year:', year);
                return (
                  <MenuItem key={year} value={year}>
                    &nbsp; {year}
                  </MenuItem>
                );
              })}
            </Select>
            <IconButton
              onClick={() => {
                console.log('Next arrow clicked');
                handleArrowClick('next', selectedMonths, selectedYears);
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
        </Box>

        <div>
          <div className="butdiv d-flex align-item-center">
            {view === 'monthly' && (
              <div style={{ marginRight: '20px' }}>
                <Button variant="contained" color="primary" startIcon={<IconDownload />} onClick={handleExportData}>
                  Export
                </Button>
              </div>
            )}
            {view === 'daily' && (
              <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                <input
                  type="date"
                  value={assignedDate}
                  onChange={handleAssignedDateChange}
                  style={{ width: 150, marginRight: '10px', height: 35 }}
                  placeholder="Assigned Date"
                  name="assignedDate"
                />
                <input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  style={{ width: 150, height: 35, marginRight: '10px' }}
                  placeholder="End Date"
                  name="endDate"
                />
              </div>
            )}
            {view === 'daily' && (
              <div style={{ marginTop: '3px', marginRight: '10px' }}>
                <Button variant="contained" color="primary" onClick={handleExport}>
                  Export
                </Button>
              </div>
            )}
            {view === 'monthly' && (
              <>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-helper-label">Team</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={Department}
                      label="Department"
                      onChange={handleChanged}
                    >
                      {teamData?.map((item, index) => (
                        <MenuItem key={index} value={item?.Team}>
                          {item?.Team}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-helper-label">Location</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={location}
                      label="Location"
                      onChange={handleLocationChanged}
                    >
                      {locationInfoData?.map((item, index) => (
                        <MenuItem key={index} value={item?.name}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </>
            )}
            <ButtonGroup variant="outlined" aria-label="outlined primary button group" className="align-item-center">
              <Button
                onClick={() => handleTabChange('daily')}
                style={{ color: '#5e35b1', borderColor: '#5e35b1', backgroundColor: '#d6cdea' }}
              >
                Daily
              </Button>
              <Button
                onClick={() => handleTabChange('monthly')}
                style={{ color: '#5e35b1', borderColor: '#5e35b1', backgroundColor: '#d6cdea' }}
              >
                Monthly
              </Button>
              <Button
                onClick={() => handleTabChange('yearly')}
                style={{ color: '#5e35b1', borderColor: '#5e35b1', backgroundColor: '#d6cdea' }}
              >
                Yearly
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
      <div>{renderCalendarView()}</div>
    </div>
  );
};

export default Overallattendance;
