/* eslint-disable */
import { useSelector } from 'react-redux';
import './assets/css/globalStyles.css';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import 'suneditor/dist/css/suneditor.min.css';

// routing
import ThemeRoutes from 'routes'; // Change this line

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <ThemeRoutes /> {/* Use ThemeRoutes here */}
          </NavigationScroll>
        </ThemeProvider>
      </DndProvider>
    </StyledEngineProvider>
  );
};

export default App;
