/*eslint-disable */

// MainRoutes.js

import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import DashboardDefault from 'views/dashboard/Default';
import BusinessLeads from 'views/logics';
import BusinessRFQ from 'views/logics/rfq';
import Projects from 'views/logics/project';
import UtilsMaterialIcons from 'views/utilities/MaterialIcons';
import SamplePage from 'views/sample-page';
import Profiles from 'views/profiles';
import Access from 'views/access';
import TaskPanel from 'views/task';
import UserDetails from 'views/Employee/userDetails';
import Employee from 'views/Employee/Employee';
import Attendance from 'views/Attendance/Attendance';
import Manager from 'Manager/Manager';
import HR from 'views/HR/HR';
import Denied from 'views/denied';
import Credential from 'views/Employee/credential';
import Customers from 'views/logics/Customers';
import Procurement from 'views/Procurement/procurement';
import Finance from 'views/Finance Team/finance';
import Ams from 'views/AMS/ams';

const MainRoutes = () => {
  const [accessRole, setAccessRole] = useState([]);

  useEffect(() => {
    const accessFromLocalStorage = JSON.parse(localStorage.getItem('userData'));
    console.log('Access from local storage:', accessFromLocalStorage);
    if (accessFromLocalStorage && Array.isArray(accessFromLocalStorage?.access)) {
      setAccessRole(accessFromLocalStorage?.access);
    }
  }, []);

  console.log('accessRoledddddddd', accessRole.includes('Dashboard'));
  console.log('accessRoledddddddd', accessRole.includes('Manager Panel'));

  return (
    <Routes>
      <Route path="/*" element={<MainLayout />}>
        <Route index element={<DashboardDefault />} />
        {accessRole.includes('Dashboard') ? (
          <Route path="dashboard/default" element={<DashboardDefault />} />
        ) : (
          <Route path="dashboard/default" element={<Denied />} />
        )}
        {accessRole.includes('BusinessLogics') ? (
          <Route path="logics/customers" element={<Customers />} />
        ) : (
          <Route path="logics/customers" element={<Denied />} />
        )}
        {accessRole.includes('BusinessLogics') ? (
          <Route path="logics/leads" element={<BusinessLeads />} />
        ) : (
          <Route path="logics/leads" element={<Denied />} />
        )}
        {accessRole.includes('BusinessLogics') ? (
          <Route path="logics/rfq" element={<BusinessRFQ />} />
        ) : (
          <Route path="logics/rfq" element={<Denied />} />
        )}
        {accessRole.includes('BusinessLogics') ? (
          <Route path="logics/projects" element={<Projects />} />
        ) : (
          <Route path="logics/projects" element={<Denied />} />
        )}
        <Route path="icons/material-icons" element={<UtilsMaterialIcons />} />
        <Route path="sample-page" element={<SamplePage />} />
        {accessRole.includes('Profiles') ? <Route path="profile" element={<Profiles />} /> : <Route path="profile" element={<Denied />} />}
        {accessRole.includes('RolesAccess') ? (
          <Route path="roles-access" element={<Access />} />
        ) : (
          <Route path="roles-access" element={<Denied />} />
        )}
        {accessRole.includes('User') ? (
          <Route path="task-panel" element={<TaskPanel />} />
        ) : (
          <Route path="task-panel" element={<Denied />} />
        )}
        {accessRole.includes('User') ? (
          <Route path="user/user-detail" element={<UserDetails />} />
        ) : (
          <Route path="user/user-detail" element={<Denied />} />
        )}
        {accessRole.includes('User') ? (
          <Route path="user/change-password" element={<Credential />} />
        ) : (
          <Route path="user/user-detail" element={<Denied />} />
        )}
        <Route path="user/task-detail" element={<Employee />} />
        <Route path="attendance-Panel" element={<Attendance />} />
        {accessRole.includes('ManagerPanel') ? (
          <Route path="Manager-Panel" element={<Manager />} />
        ) : (
          <Route path="Manager-Panel" element={<Denied />} />
        )}
        {accessRole.includes('HRPanel') ? <Route path="HR-Panel" element={<HR />} /> : <Route path="HR-Panel" element={<Denied />} />}
        <Route path="procurement-Panel" element={<Procurement />}></Route>
        <Route path="finance-team" element={<Finance />}></Route>
        <Route path="assetmanagement-system" element={<Ams />}></Route>
      </Route>
    </Routes>
  );
};

export default MainRoutes;
