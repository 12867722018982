/*eslint-disable */

import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import FileUpload from 'react-material-file-upload';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Avatar from '@mui/material/Avatar';
import FileList from '../../utils/fileDownloader';
import './userDetails.css';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { Divider, Modal } from 'rsuite';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import Diversity2TwoToneIcon from '@mui/icons-material/Diversity2TwoTone';
import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import banner from '../../assets/images/employee banner.png';
import avatar from '../../assets/images/avatar.png';
import Family from './Details/Family';
import Skills from './Details/Skills';
import Bank from './Details/Bank';
import Documents from './Details/Documents';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import Application1 from './Details/Application1';
import Application2 from './Details/Application2';
import Attendance from '../Attendance/Attendance';
import HowToRegTwoToneIcon from '@mui/icons-material/HowToRegTwoTone';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import MonthlyAttendance from 'views/Attendance/MonthlyAttendance';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import EventRepeatTwoToneIcon from '@mui/icons-material/EventRepeatTwoTone';
import LeaveDetails from './LeaveDetails';
import { BASE_URL, COMP_OFF, LEAVES_CHECK, LEAVE_LOGS, LEAVE_OB, PERSONAL_DETAILS } from 'api/apiEndPoint';
import { fetchData, postData } from 'utils/apiUtils';
import { EmailTwoTone, PhonelinkRingTwoTone, PinDropTwoTone } from '@mui/icons-material';

const UserDetails = () => {
  const [leave, setLeave] = React.useState('');

  const handleChanges = (event) => {
    setLeave(event.target.value);
  };
  const [compoff, setCompoff] = React.useState('');

  const handleChangecompoff = (event) => {
    setCompoff(event.target.value);
  };

  const [open, setOpen] = useState(false);
  const handleDelete = () => {
    setOpen(true);
  };

  const [opened, setOpened] = useState(false);
  const handleDeletes = () => {
    setOpened(true);
  };

  const [value, setValue] = React.useState('1');
  const [localData, setLocalData] = useState();
  const [ProfileData, setProfileData] = useState([]);
  console.log(localData, 'locatdata');
  const handleTabChange = (event, newValue) => {
    console.log(event, newValue, 'handleTabChange');
    setValue(newValue);
  };
  const handleNext1 = () => {
    setValue('3');
  };
  const handleNext2 = () => {
    setValue('4');
  };
  const handleNext3 = () => {
    setValue('5');
  };
  const handleNext4 = () => {
    setValue('6');
  };
  const handleNext5 = () => {
    setValue('7');
  };
  console.log(value, 'setValue');
  const [opens, setOpens] = React.useState(false);
  const handleOpen = () => setOpens(true);
  const handleClose = () => setOpens(false);

  const [formData, setFormData] = useState({
    employeeid: '',
    leavetype: '',
    leavereason: '',
    leavefromdate: '',
    leavetodate: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const [values, setValues] = React.useState('1');

  const handlerequests = (event, newValue) => {
    setValues(newValue);
  };

  const fetchlocal = () => {
    const localStore = localStorage.getItem('userData');
    const parsed = JSON.parse(localStore);
    console.log(parsed, 'parsed');
    if (parsed) {
      setLocalData(parsed);
      setFormData((prevFormData) => ({
        ...prevFormData,
        employeeid: parsed?.employeeId // Update employeeid in formData
      }));
    }
  };
  useEffect(() => {
    fetchlocal();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted');

    try {
      console.log('sendinggg', formData);

      console.log('POST request successful', LEAVE_LOGS);
      const response = await postData(LEAVE_LOGS, formData, localData?.accessToken);
      setOpened(false);

      localStorage.setItem('postedFormData', JSON.stringify(formData));
      console.log(localStorage, 'postedFormData');

      console.log('View state updated', setView);
    } catch (error) {
      console.error('Error in handlePost:', error);
    }
  };

  const [leavesCheck, setLeavesCheck] = useState('');
  const fetchDataAndUpdate = async () => {
    try {
      const localStore = localStorage.getItem('userData');
      if (localStore) {
        const parsedData = JSON.parse(localStore);
        const apiUrl = LEAVES_CHECK(parsedData?.employeeId);
        console.log('dammit', apiUrl);
        const response = await fetch(apiUrl);
        const data = await response.json();
        // Assuming data is an array with a single object
        if (data && data.length > 0) {
          setLeavesCheck(data[0]);
        }
      }
    } catch (err) {
      console.error('Error fetching attendance data:', err);
    }
  };

  useEffect(() => {
    fetchDataAndUpdate();
  }, []);
  console.log('Leaves Check:', leavesCheck);

  const [fromDate, setFromDate] = useState('');

  const handleFromDate = (event) => {
    setFromDate(event.target.value);
  };
  const [toDate, setToDate] = useState('');

  const handleToDate = (event) => {
    setToDate(event.target.value);
  };
  const [work, setWork] = useState('');

  const handleWork = (event) => {
    setWork(event.target.value);
  };
  const [date, setDate] = useState('');

  const handleDate = (event) => {
    setDate(event.target.value);
  };
  const [reason, setReason] = useState('');

  const handleReason = (event) => {
    setReason(event.target.value);
  };

  const [compOff, setCompOff] = useState('');

  //   useEffect(() => {
  //     const fetchDataAndUpdate = async () => {
  //       try {
  //         const localStore = localStorage.getItem('userData');
  //         if (localStore) {
  //           const parsedData = JSON.parse(localStore);
  //           const apiUrl = COMP_OFF(parsedData?.employeeId);
  //           const response = await fetch(apiUrl);
  //           const data = await response.json();
  //           // Assuming data is an array with a single object
  //           if (data && data.length > 0) {
  //             setCompOff(data[0]);
  //           }
  //         }
  //       } catch (err) {
  //         console.error('Error fetching attendance data:', err);
  //       }
  //     };
  //     fetchDataAndUpdate();
  //   }, []);

  //Personal Details Tab
  const [gender, setGender] = React.useState('');
  const [formData1, setFormData1] = useState({
    name: '',
    surname: '',
    motherTongue: '',
    description: '',
    bloodGroup: '',
    educationQualification: '',
    yearOfGraduation: '',
    dateOfBirth: '',
    gender: '',
    contactNumber: '',
    emergencyContactNumber: '',
    personalEmailID: '',
    currentAddress: '',
    permanentAddress: '',
    aadhaarNumber: '',
    panNumber: '',
    drivingLicenseNumber: '',
    drivingLicenseExpiryDate: '',
    passportNumber: '',
    passportExpiryDate: ''
  });
  const handleInputChange1 = (event) => {
    const { name, value } = event.target;
    setFormData1({
      ...formData1,
      [name]: value
    });
  };
  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setFormData1({
      ...formData1,
      gender: event.target.value
    });
  };
  const handleSubmit1 = (event) => {
    console.log('personal details formData ---->', formData1);
    event.preventDefault();
    setValue('3');
    // You can perform further actions here, like submitting the form data
  };
  //familyDetails
  const [formData2, setFormData2] = useState({
    fatherName: '',
    motherName: '',
    hasSiblings: false,
    siblingType: [],
    siblingNames: [],
    maritalStatus: '',
    spouseName: '',
    spouseMobileNumber: ''
  });

  const handleChange2 = (e) => {
    const { name, value, type } = e.target;
    setFormData2((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? e.target.checked : value
    }));
  };

  const handleSiblingTypeChange = (index, e) => {
    const siblingType = [...formData2.siblingType];
    siblingType[index] = e.target.value;
    setFormData2((prevData) => ({
      ...prevData,
      siblingType
    }));
  };

  const handleSiblingNameChange = (index, e) => {
    const siblingNames = [...formData2.siblingNames];
    siblingNames[index] = e.target.value;
    setFormData2((prevData) => ({
      ...prevData,
      siblingNames
    }));
  };

  const handleAddSibling = () => {
    setFormData2((prevData) => ({
      ...prevData,
      siblingType: [...prevData.siblingType, ''],
      siblingNames: [...prevData.siblingNames, '']
    }));
  };

  const handleRemoveSibling = (index) => {
    const siblingType = [...formData2.siblingType];
    siblingType.splice(index, 1);
    const siblingNames = [...formData2.siblingNames];
    siblingNames.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      siblingType,
      siblingNames
    }));
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    // Process form data here, e.g., send to API
    console.log('Form data 2:', formData2);
    // Redirect to next page
    setValue('4');
  };

  //Skills and Experience Details
  const [formData3, setFormData3] = useState({
    companyName: '',
    fromDate: '',
    toDate: '',
    designation: '',
    roleDescription: '',
    softwareExposure: [],
    languages: []
  });
  const handleChange3 = (e) => {
    const { name, value } = e.target;
    setFormData3((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleSoftwareExposureChange = (e) => {
    const softwareExposure = e.target.value.split('\n').map((item) => item.trim());
    setFormData3((prevData) => ({
      ...prevData,
      softwareExposure
    }));
  };
  const handleLanguagesChange = (e) => {
    const languages = e.target.value.split('\n').map((item) => item.trim());
    setFormData3((prevData) => ({
      ...prevData,
      languages
    }));
  };
  const handleSubmit3 = (e) => {
    e.preventDefault();
    // Process form data here, e.g., send to API
    setValue('5');
    console.log('Form data 3:', formData3);
    // Redirect to next page
    // window.location.href = '/user-detail'; // Redirect code
  };
  //Bank Details
  const [formData4, setFormData4] = useState({
    bankName: '',
    branchName: '',
    accountNumber: '',
    ifscCode: '',
    epfoUanNumber: '',
    esicNumber: ''
  });
  const handleChange4 = (e) => {
    const { name, value } = e.target;
    setFormData4((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleSubmit4 = (e) => {
    e.preventDefault();
    // Process form data here, e.g., send to API
    console.log('Form data 5:', formData4);
    setValue('6');
    // Redirect to next page
    // window.location.href = '/user-detail'; // Redirect code
  };
  //Documents Details
  const [formData5, setFormData5] = useState({
    copyOfCV: null,
    academicCertificates: null,
    technicalCertificate: null,
    idProofs: [],
    residenceProof: null,
    lastResignationLetter: null,
    lastThreeMonthsSalarySlips: [],
    lastEmployerRelievingLetter: null,
    experienceLetter: null,
    immediateSupervisorContact: null,
    taxDeclaration: null,
    passportVisaCopy: null,
    bankPassbook: null,
    form16: null,
    uanPF: null,
    drivingLicenceFrontAndBack: [],
    profilePicture: null // New field for profile picture
  });

  const handleProfilePictureChange = (e) => {
    // e.preventDefault();
    const file = e.target.files[0];
    setFormData5((prevState) => ({
      ...prevState,
      profilePicture: file
    }));
  };
  const handleFileChange5 = (fieldName, file) => {
    setFormData5((prevState) => ({
      ...prevState,
      [fieldName]: file
    }));
  };
  const handleSubmit5 = async (e) => {
    e.preventDefault();
    // Display confirmation dialog
    const confirmed = window.confirm("Once you submit, you won't be able to change the form data. Are you sure you want to submit?");

    // Check if the user confirmed
    if (!confirmed) {
      // If not confirmed, exit the function
      return;
    }
    const formData = new FormData();
    formData.append('employeeId', localData?.employeeId);
    formData.append('surname', formData1.surname);
    formData.append('motherTongue', formData1.motherTongue);
    formData.append('description', formData1.description);
    formData.append('bloodGroup', formData1.bloodGroup);
    formData.append('educationQualification', formData1.educationQualification);
    formData.append('yearOfGraduation', formData1.yearOfGraduation);
    formData.append('gender', formData1.gender);
    formData.append('emergencyContact', formData1.emergencyContactNumber);
    formData.append('personalMail', formData1.personalEmailID);
    formData.append('currentAddress', formData1.currentAddress);
    formData.append('permanentAddress', formData1.permanentAddress);
    formData.append('aadharNumber', formData1.aadhaarNumber);
    formData.append('panNumber', formData1.panNumber);
    formData.append('drivingLicense.hasLicense', formData1.drivingLicenseNumber ? 'true' : 'false');
    formData.append('drivingLicense.licenseNumber', formData1.drivingLicenseNumber);
    formData.append('drivingLicense.expireDate', formData1.drivingLicenseExpiryDate);
    formData.append('passport.hasPassport', formData1.passportNumber ? 'true' : 'false');
    formData.append('passport.passportNumber', formData1.passportNumber);
    formData.append('passport.expiryDate', formData1.passportExpiryDate);
    formData.append('familyDetails.fatherName', formData2.fatherName);
    formData.append('familyDetails.motherName', formData2.motherName);
    formData.append('familyDetails.siblings.hasSiblings', formData2.hasSiblings ? 'true' : 'false');
    formData.append('familyDetails.siblings.siblingType', formData2.siblingType);
    formData2.siblingNames.forEach((name) => formData.append('familyDetails.siblings.siblingNames', name));
    formData.append('familyDetails.maritalStatus', formData2.maritalStatus);
    formData.append('familyDetails.spouseName', formData2.spouseName);
    formData.append('familyDetails.spouseMobileNumber', formData2.spouseMobileNumber);

    formData.append('skillAndExperience.companyName', formData3.companyName);
    formData.append('skillAndExperience.fromDate', formData3.fromDate);
    formData.append('skillAndExperience.toDate', formData3.toDate);
    formData.append('skillAndExperience.designation', formData3.designation);
    formData.append('skillAndExperience.roleDescription', formData3.roleDescription);
    formData.append('skillAndExperience.softwareExposure', formData3.softwareExposure);
    formData.append('skillAndExperience.languages', formData3.languages);

    formData.append('bankDetails.bankName', formData4.bankName);
    formData.append('bankDetails.branchName', formData4.branchName);
    formData.append('bankDetails.accountNumber', formData4.accountNumber);
    formData.append('bankDetails.ifscCode', formData4.ifscCode);
    formData.append('bankDetails.epfoUanNumber', formData4.epfoUanNumber);
    formData.append('bankDetails.esicNumber', formData4.esicNumber);

    // Add binary fields if they exist
    if (formData5?.copyOfCV) {
      formData.append('documentDetails.copyOfCV', formData5.copyOfCV[0]);
    }
    formData5?.academicCertificates?.forEach((file) => {
      if (file) {
        formData.append('documentDetails.academicCertificates', file);
      }
    });
    if (formData5?.technicalCertificate) {
      formData.append('documentDetails.technicalCertificate', formData5.technicalCertificate[0]);
    }
    if (formData5?.profilePicture) {
      formData.append('documentDetails.profilePicture', formData5?.profilePicture);
    }
    if (formData5?.idProofs) {
      formData5.idProofs.forEach((file) => {
        if (file) {
          formData.append('documentDetails.idProofs', file);
        }
      });
    }
    if (formData5?.residenceProof) {
      formData.append('documentDetails.residenceProof', formData5.residenceProof[0]);
    }
    if (formData5?.lastResignationLetter) {
      formData.append('documentDetails.lastResignationLetter', formData5.lastResignationLetter[0]);
    }
    formData5?.lastThreeMonthsSalarySlips?.forEach((file) => {
      if (file) {
        formData.append('documentDetails.lastThreeMonthsSalarySlips', file);
      }
    });
    if (formData5?.lastEmployerRelievingLetter) {
      formData.append('documentDetails.lastEmployerRelievingLetter', formData5.lastEmployerRelievingLetter[0]);
    }
    if (formData5?.experienceLetter) {
      formData.append('documentDetails.experienceLetter', formData5.experienceLetter[0]);
    }
    if (formData5?.immediateSupervisorContact) {
      formData.append('documentDetails.immediateSupervisorContact', formData5.immediateSupervisorContact[0]);
    }
    if (formData5?.taxDeclaration) {
      formData.append('documentDetails.taxDeclaration', formData5.taxDeclaration[0]);
    }
    if (formData5?.passportVisaCopy) {
      formData.append('documentDetails.passportVisaCopy', formData5.passportVisaCopy[0]);
    }
    if (formData5?.bankPassbook) {
      formData.append('documentDetails.bankPassbook', formData5.bankPassbook[0]);
    }
    if (formData5?.form16) {
      formData.append('documentDetails.form16', formData5.form16[0]);
    }
    if (formData5?.uanPF) {
      formData.append('documentDetails.uanPF', formData5.uanPF[0]);
    }
    formData5?.drivingLicenceFrontAndBack?.forEach((file) => {
      if (file) {
        formData.append('documentDetails.drivingLicenceFrontAndBack', file);
      }
    });
    console.log('configured formData', formData);

    try {
      const response = await fetch(PERSONAL_DETAILS, {
        method: 'POST',
        body: formData
      });
      if (response.ok) {
        fetchEmployeePersonalDetail();
        console.log('Form submitted successfully');
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const fetchEmployeePersonalDetail = async () => {
    try {
      const localStore = localStorage.getItem('userData');
      if (localStore) {
        const parsedData = JSON.parse(localStore);
        if (parsedData) {
          const apiEndPoint = `${PERSONAL_DETAILS}/${parsedData?.employeeId}/profile`;
          const response = await fetch(apiEndPoint);
          if (!response.ok) {
            // Handle non-OK response status (e.g., 404)
            throw new Error('Failed to fetch personal details');
          }
          const personalDetails = await response.json();
          console.log('personalDetails :', personalDetails);
          setProfileData(personalDetails);
        } else {
          setProfileData([]);
        }
      }
    } catch (error) {
      console.error('Error fetching personal details:', error);
      // Handle the error (e.g., display a message to the user)
    }
  };
  function splitLoginTime(loginTime) {
    console.log(loginTime, '<---------loginTime');
    // Convert loginTime string to a Date object
    const loginDate = new Date(loginTime);

    // Get date components (day, month, year)
    const day = loginDate.getDate().toString().padStart(2, '0');
    const month = (loginDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = loginDate.getFullYear();

    // Combine date components
    const loggedate = `${day}-${month}-${year}`;

    // Get time components (hours, minutes)
    const hours = loginDate.getHours().toString().padStart(2, '0');
    const minutes = loginDate.getMinutes().toString().padStart(2, '0');

    // Combine time components
    const loggedtime = `${hours}:${minutes}`;
    // Return date and time components
    return { loggedate, loggedtime };
  }

  useEffect(() => {
    fetchEmployeePersonalDetail();
  }, []);
  const { loggedate, loggedtime } = splitLoginTime(localData?.loginTime);

  console.log('ProfileData----->', ProfileData);
  return (
    <>
      {ProfileData.length !== 0 ? (
        <>
          {ProfileData && ProfileData?.personalDetails?.verifiedProfile === 'pending' && (
            <div>
              <Application1 />
            </div>
          )}
          {ProfileData && ProfileData?.personalDetails?.verifiedProfile === 'rejected' && (
            <div>
              <Application2 />
            </div>
          )}
          {ProfileData && ProfileData?.personalDetails?.verifiedProfile === 'verified' && (
            <Card style={{ height: '82vh', padding: '20px', overflowY: 'auto' }}>
              <div style={{ position: 'relative' }}>
                {ProfileData?.personalDetails?.documentDetails?.profilePicture?.length === 0 ? (
                  <Box
                    component="img"
                    sx={{
                      height: 233,
                      width: 233,
                      borderRadius: '10px'
                    }}
                    alt="avatar"
                    src={avatar}
                    className=""
                  />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      width: '100%',
                      height: '300px',
                      borderRadius: '10px'
                    }}
                    alt="avatar"
                    src={banner}
                    className=""
                  />
                )}
                <Card className="p-1" style={{ width: '18%', position: 'absolute', top: '3rem', right: '1.7rem' }}>
                  <div className="d-flex justify-content-center">
                    <Box
                      component="img"
                      sx={{
                        height: 233,
                        width: 233,
                        borderRadius: '10px'
                      }}
                      alt="avatar"
                      src={`${BASE_URL}/${ProfileData?.personalDetails?.documentDetails?.profilePicture[0]?.path}`}
                      className=""
                    />
                  </div>
                  {/* <div className="text-muted text-end m-1">
                    <p>
                      Logged In
                      <br />
                      {loggedate}-{loggedtime}
                    </p>
                  </div> */}
                  <div className="text-center mt-1 mb-1">
                    <h3>
                      {ProfileData?.employeeProfile?.NameOfCandidate}&nbsp;{ProfileData?.personalDetails?.familyDetails?.fatherName}
                    </h3>
                    <span>{ProfileData?.employeeProfile?.Designation}</span>
                  </div>
                  <div className="">
                    <Grid container>
                      <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted text-center mt-1">
                        Email
                      </Grid>
                      <Grid xs={8} className="text-muted text-center mt-1">
                        {ProfileData?.personalDetails?.personalMail}
                      </Grid>
                      <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted text-center mt-1">
                        Mobile
                      </Grid>
                      <Grid xs={8} className="text-muted text-center mt-1">
                        (+91) {ProfileData?.employeeProfile?.ContactNumber}
                      </Grid>
                      <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted text-center mt-1">
                        Location
                      </Grid>
                      <Grid xs={8} className="text-muted text-center mt-1">
                        {ProfileData?.employeeProfile?.Location}
                      </Grid>
                    </Grid>
                  </div>
                </Card>
                <Card className="p-1" style={{ width: '18%', position: 'absolute', top: '34rem', right: '1.7rem', marginBottom: '1rem' }}>
                  <div className="text-center mt-1 mb-1">
                    <h4>Skills & Experience</h4>
                  </div>
                  <Divider />
                  <div className="">
                    <Grid container>
                      <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted mt-1">
                        Company
                      </Grid>
                      <Grid xs={8} className="text-muted text-center mt-1">
                        {ProfileData?.personalDetails?.skillAndExperience?.companyName}
                      </Grid>
                      <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted  mt-1">
                        Designation
                      </Grid>
                      <Grid xs={8} className="text-muted text-center mt-1">
                        {ProfileData?.personalDetails?.skillAndExperience?.designation}
                      </Grid>
                      <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted mt-1">
                        From Date
                      </Grid>
                      <Grid xs={8} className="text-muted text-center mt-1">
                        {ProfileData?.personalDetails?.skillAndExperience?.fromDate?.slice(0, 10)}
                      </Grid>
                      <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted  mt-1">
                        To Date
                      </Grid>
                      <hr />
                      <Grid xs={8} className="text-muted text-center mt-1">
                        {ProfileData?.personalDetails?.skillAndExperience?.toDate?.slice(0, 10)}
                      </Grid>
                      <Grid xs={6} sx={{ fontWeight: 'bold' }} className="text-muted mt-1">
                        Languages
                        <div>
                          {ProfileData?.personalDetails?.skillAndExperience?.languages &&
                            ProfileData?.personalDetails?.skillAndExperience?.languages[0]
                              .split(',')
                              .map((language, idx) => <li> {language}</li>)}
                        </div>
                      </Grid>
                      <Grid xs={6} sx={{ fontWeight: 'bold' }} className="text-muted mt-1">
                        Software Exposure
                        {ProfileData?.personalDetails?.skillAndExperience?.softwareExposure &&
                          ProfileData?.personalDetails?.skillAndExperience?.softwareExposure[0]
                            .split(',')
                            .map((language, idx) => <li> {language}</li>)}
                      </Grid>

                      <Grid xs={8} className="text-muted text-center mt-1">
                        {ProfileData?.personalDetails?.skillAndExperience?.roleDescription}
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </div>
              <Grid container m={3} xs={9}>
                <p className="text-muted">{ProfileData?.personalDetails?.description}</p>
              </Grid>
              <Divider />
              <Grid container m={3} xs={10}>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Employee Code</label>
                  <p>{ProfileData?.employeeProfile?.EmployeeCode}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Name Of Candidate</label>
                  <p>{ProfileData?.employeeProfile?.NameOfCandidate}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Gender</label>
                  <p>{ProfileData?.personalDetails?.gender}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Emergency Contact</label>
                  <p>{ProfileData?.personalDetails?.emergencyContact}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Date Of Birth</label>
                  <p>{ProfileData?.employeeProfile?.DateOfBirth}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Blood Group</label>
                  <p>{ProfileData?.personalDetails?.bloodGroup}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Qualification</label>
                  <p>{ProfileData?.personalDetails?.educationQualification[0]}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Year of Graducation</label>
                  <p>{ProfileData?.personalDetails?.yearOfGraduation}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Current Address</label>
                  <p>{ProfileData?.personalDetails?.currentAddress}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Permanent Address</label>
                  <p>{ProfileData?.personalDetails?.permanentAddress}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Aadhar Number</label>
                  <p>{ProfileData?.personalDetails?.aadharNumber}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">PAN Number</label>
                  <p>{ProfileData?.personalDetails?.panNumber}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Designation</label>
                  <p>{ProfileData?.employeeProfile?.Designation}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Team</label>
                  <p>{ProfileData?.employeeProfile?.Team}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Role</label>
                  <p>{ProfileData?.employeeProfile?.Role}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Contact Number</label>
                  <p>{ProfileData?.employeeProfile?.ContactNumber}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Category</label>
                  <p>{ProfileData?.employeeProfile?.Category}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Status</label>
                  <p>{ProfileData?.employeeProfile?.status === true ? 'Current Employeee' : 'Resigned Employee'}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Father Name</label>
                  <p>{ProfileData?.personalDetails?.familyDetails?.fatherName}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Mother Name</label>
                  <p>{ProfileData?.personalDetails?.familyDetails?.motherName}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Has Siblings</label>
                  <p>{ProfileData?.personalDetails?.familyDetails?.siblings?.hasSiblings === true ? 'Yes' : 'No'}</p>
                </Grid>
                {ProfileData?.personalDetails?.familyDetails?.siblings?.hasSiblings &&
                  ProfileData?.personalDetails?.familyDetails?.siblings?.siblingType[0].split(',').map((siblingType, idx) => (
                    <Grid item xs={3} p={2}>
                      <label className="text-muted">
                        {siblingType} {idx + 1}
                      </label>
                      <p>{ProfileData?.personalDetails?.familyDetails?.siblings?.siblingNames[idx]}</p>
                    </Grid>
                  ))}
                <Grid xs={3} p={2}>
                  <label className="text-muted">Marital Status</label>
                  <p>{ProfileData?.personalDetails?.familyDetails?.maritalStatus === 'true' ? 'Yes' : 'No'}</p>
                </Grid>
                {ProfileData?.personalDetails?.familyDetails?.maritalStatus && (
                  <>
                    <Grid xs={3} p={2}>
                      <label className="text-muted">Spouse Name</label>
                      <p>{ProfileData?.personalDetails?.familyDetails?.spouseName}</p>
                    </Grid>
                    <Grid xs={3} p={2}>
                      <label className="text-muted">Spouse Mobile Number</label>
                      <p>{ProfileData?.personalDetails?.familyDetails?.spouseMobileNumber}</p>
                    </Grid>
                  </>
                )}
                <Grid xs={3} p={2}>
                  <label className="text-muted">Has Passport</label>
                  <p>{ProfileData?.personalDetails?.passport?.hasPassport === true ? 'Yes' : 'No'}</p>
                </Grid>
                {ProfileData?.personalDetails?.passport?.hasPassport && (
                  <>
                    <Grid xs={3} p={2}>
                      <label className="text-muted">Passport Number</label>
                      <p>{ProfileData?.personalDetails?.passport?.passportNumber}</p>
                    </Grid>
                    <Grid xs={3} p={2}>
                      <label className="text-muted">Passport Expiry Date</label>
                      <p>{ProfileData?.personalDetails?.passport?.expiryDate?.slice(0, 10)}</p>
                    </Grid>
                  </>
                )}
                <Grid xs={3} p={2}>
                  <label className="text-muted">Has Driving License</label>
                  <p>{ProfileData?.personalDetails?.drivingLicense?.hasLicense === true ? 'Yes' : 'No'}</p>
                </Grid>
                {ProfileData?.personalDetails?.drivingLicense?.hasLicense && (
                  <>
                    <Grid xs={3} p={2}>
                      <label className="text-muted">License Number</label>
                      <p>{ProfileData?.personalDetails?.drivingLicense?.licenseNumber}</p>
                    </Grid>
                    <Grid xs={3} p={2}>
                      <label className="text-muted">License Expiry Date</label>
                      <p>{ProfileData?.personalDetails?.drivingLicense?.expireDate?.slice(0, 10)}</p>
                    </Grid>
                  </>
                )}
                <Grid xs={3} p={2}>
                  <label className="text-muted">Bank Name</label>
                  <p>{ProfileData?.personalDetails?.bankDetails?.bankName}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Branch Name</label>
                  <p>{ProfileData?.personalDetails?.bankDetails?.branchName}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Account Number</label>
                  <p>{ProfileData?.personalDetails?.bankDetails?.accountNumber}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">IFSC Code</label>
                  <p>{ProfileData?.personalDetails?.bankDetails?.ifscCode}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">EPFO UAN Number</label>
                  <p>{ProfileData?.personalDetails?.bankDetails?.epfoUanNumber}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">ESIC Number</label>
                  <p>{ProfileData?.personalDetails?.bankDetails?.esicNumber}</p>
                </Grid>
              </Grid>
              <Divider />
              <div className="mt-2">
                <FileList documentDetails={ProfileData?.personalDetails?.documentDetails} />
              </div>
            </Card>
          )}
        </>
      ) : (
        <>
          <MainCard>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <div>
                    <TabList onChange={handleTabChange} visibleScrollbar>
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AccountCircleTwoToneIcon style={{ marginRight: '15px' }} />
                            Profile
                          </div>
                        }
                        value="1"
                        style={{ fontSize: '15px' }}
                      />{' '}
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DescriptionTwoToneIcon style={{ marginRight: '15px' }} />
                            Personal Details
                          </div>
                        }
                        value="2"
                        style={{ fontSize: '15px' }}
                      />
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Diversity2TwoToneIcon style={{ marginRight: '15px' }} />
                            Family Details
                          </div>
                        }
                        value="3"
                        style={{ fontSize: '15px' }}
                      />
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <EngineeringTwoToneIcon style={{ marginRight: '15px' }} />
                            Skill & Experience Details
                          </div>
                        }
                        value="4"
                        style={{ fontSize: '15px' }}
                      />
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AccountBalanceTwoToneIcon style={{ marginRight: '15px' }} />
                            Bank Details
                          </div>
                        }
                        value="5"
                        style={{ fontSize: '15px' }}
                      />
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ArticleTwoToneIcon style={{ marginRight: '15px' }} />
                            Document Details
                          </div>
                        }
                        value="6"
                        style={{ fontSize: '15px' }}
                      />
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CheckCircleOutlineTwoToneIcon style={{ marginRight: '15px' }} />
                            Application Status
                          </div>
                        }
                        value="7"
                        style={{ fontSize: '15px' }}
                      />
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CheckCircleOutlineTwoToneIcon style={{ marginRight: '15px' }} />
                            Application Status
                          </div>
                        }
                        value="8"
                        style={{ fontSize: '15px' }}
                      />
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <HowToRegTwoToneIcon style={{ marginRight: '15px' }} />
                            Attendance
                          </div>
                        }
                        value="10"
                        style={{ fontSize: '15px' }}
                      />
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <HowToRegTwoToneIcon style={{ marginRight: '15px' }} />
                            CV
                          </div>
                        }
                        value="11"
                        style={{ fontSize: '15px' }}
                      />
                      <div className="float-end">
                        <Dialog
                          fullWidth
                          maxWidth="sm" // Adjust the size as needed
                          open={open}
                          onClose={handleClose}
                        >
                          <DialogTitle className="d-flex justify-content-between m-0">
                            <Typography variant="h3">Change Password</Typography>
                            <ClearIcon onClick={() => setOpen(false)} />
                          </DialogTitle>

                          <Divider />
                          <DialogContent className="m-1">
                            <br />

                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField label="Current Password" variant="outlined" fullWidth />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField label="New Password" variant="outlined" fullWidth />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField label="Confirm Password" variant="outlined" fullWidth />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions className="d-flex justify-content-center mb-1">
                            <Button variant="outlined" onClick={() => setOpen(false)}>
                              Clear
                            </Button>
                            <Button variant="contained">Change Password</Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </TabList>
                  </div>
                </Box>
                <TabPanel value="1">
                  <div className="d-flex align-item-start">
                    <div style={{}}>
                      <MainCard
                        style={{
                          backgroundColor: '#ede7f6',
                          marginLeft: '30px',
                          maxWidth: '600px',
                          color: '#623ab3',
                          borderColor: '#623ab3',
                          borderWidth: '2px',
                          borderStyle: 'solid'
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '42px' }}>
                          <StickyNote2Icon style={{ color: '#623ab3' }} />
                          <h3 style={{ color: '#623ab3', marginLeft: '10px', marginTop: '10px' }}> Type of Leaves!</h3>
                        </div>

                        <div className="name-list " style={{ marginLeft: '100px' }}>
                          <Grid container className="d-flex justify-content-between align-items-center">
                            <Grid item xs={4} md={3}>
                              <strong>SL</strong>
                            </Grid>
                            <Grid item xs={1}>
                              -
                            </Grid>
                            <Grid item xs={7} md={8}>
                              Sick Leave
                            </Grid>
                          </Grid>
                          <Grid container className="d-flex justify-content-between align-items-center">
                            <Grid item xs={4} md={3}>
                              <strong>P</strong>
                            </Grid>
                            <Grid item xs={1}>
                              -
                            </Grid>
                            <Grid item xs={7} md={8}>
                              {' '}
                              Present
                            </Grid>
                          </Grid>
                          <Grid container className="d-flex justify-content-between align-items-center">
                            <Grid item xs={4} md={3}>
                              <strong>WK</strong>
                            </Grid>
                            <Grid item xs={1}>
                              -
                            </Grid>
                            <Grid item xs={7} md={8}>
                              WeekEnd
                            </Grid>
                          </Grid>
                          <Grid container className="d-flex justify-content-between align-items-center">
                            <Grid item xs={4} md={3}>
                              <strong>CL</strong>
                            </Grid>
                            <Grid item xs={1}>
                              -
                            </Grid>
                            <Grid item xs={7} md={8}>
                              {' '}
                              Casual Leave
                            </Grid>
                          </Grid>
                          <Grid container className="d-flex justify-content-between align-items-center">
                            <Grid item xs={4} md={3}>
                              <strong>LOP</strong>
                            </Grid>
                            <Grid item xs={1}>
                              -
                            </Grid>
                            <Grid item xs={7} md={8}>
                              Loss of Pay{' '}
                            </Grid>
                          </Grid>
                          <Grid container className="d-flex justify-content-between align-items-center">
                            <Grid item xs={4} md={3}>
                              <strong>UN</strong>
                            </Grid>
                            <Grid item xs={1}>
                              -
                            </Grid>
                            <Grid item xs={7} md={8}>
                              UN Informed Leave
                            </Grid>
                          </Grid>
                          <Grid container className="d-flex justify-content-between align-items-center">
                            <Grid item xs={4} md={3}>
                              <strong>PC </strong>
                            </Grid>
                            <Grid item xs={1}>
                              -
                            </Grid>
                            <Grid item xs={7} md={8}>
                              Present Comp-Off
                            </Grid>
                          </Grid>
                          <Grid container className="d-flex justify-content-between align-items-center">
                            <Grid item xs={4} md={3}>
                              <strong>EL</strong>
                            </Grid>
                            <Grid item xs={1}>
                              -
                            </Grid>
                            <Grid item xs={7} md={8}>
                              Earned Leave
                            </Grid>
                          </Grid>
                          <Grid container className="d-flex justify-content-between align-items-center">
                            <Grid item xs={4} md={3}>
                              <strong>CC</strong>
                            </Grid>
                            <Grid item xs={1}>
                              -
                            </Grid>
                            <Grid item xs={7} md={8}>
                              Compensatory Off (Comp-off)
                            </Grid>
                          </Grid>
                        </div>
                      </MainCard>
                    </div>
                    <div className="ms-1">
                      <Button
                        // style={{ marginLeft: '480px' }}
                        sx={{
                          backgroundColor: '#ede7f6',
                          color: '#5e35b1',
                          marginTop: '1rem',
                          transition: 'background-color 0.3s, color 0.3s',
                          '&:hover': {
                            backgroundColor: '#5e35b1',
                            color: '#ede7f6'
                          }
                        }}
                        onClick={handleDeletes}
                      >
                        Request For leave / OD
                      </Button>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <form onSubmit={handleSubmit1}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextField label="Name" name="name" value={formData1.name} onChange={handleInputChange1} fullWidth required />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Surname"
                          name="surname"
                          value={formData1.surname}
                          onChange={handleInputChange1}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Mother Tongue"
                          name="motherTongue"
                          value={formData1.motherTongue}
                          onChange={handleInputChange1}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Blood Group"
                          name="bloodGroup"
                          value={formData1.bloodGroup}
                          onChange={handleInputChange1}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Education Qualification"
                          name="educationQualification"
                          value={formData1.educationQualification}
                          onChange={handleInputChange1}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Year Of Graduation"
                          name="yearOfGraduation"
                          value={formData1.yearOfGraduation}
                          onChange={handleInputChange1}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          type="textarea"
                          label="Bio Description"
                          name="description"
                          value={formData1.description}
                          onChange={handleInputChange1}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Date Of Birth"
                          name="dateOfBirth"
                          type="date"
                          value={formData1.dateOfBirth}
                          onChange={handleInputChange1}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <InputLabel id="gender-label">Gender</InputLabel>
                          <Select labelId="gender-label" name="gender" value={gender} onChange={handleGenderChange} fullWidth required>
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Contact Number"
                          name="contactNumber"
                          value={formData1.contactNumber}
                          onChange={handleInputChange1}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Emergency Contact Number"
                          name="emergencyContactNumber"
                          value={formData1.emergencyContactNumber}
                          onChange={handleInputChange1}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Personal Email ID"
                          name="personalEmailID"
                          value={formData1.personalEmailID}
                          onChange={handleInputChange1}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Current Address"
                          name="currentAddress"
                          value={formData1.currentAddress}
                          onChange={handleInputChange1}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Permanent Address"
                          name="permanentAddress"
                          value={formData1.permanentAddress}
                          onChange={handleInputChange1}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Aadhaar Number"
                          name="aadhaarNumber"
                          value={formData1.aadhaarNumber}
                          onChange={handleInputChange1}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Pan Number"
                          name="panNumber"
                          value={formData1.panNumber}
                          onChange={handleInputChange1}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Driving License Number"
                          name="drivingLicenseNumber"
                          value={formData1.drivingLicenseNumber}
                          onChange={handleInputChange1}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Driving License Expiry Date"
                          name="drivingLicenseExpiryDate"
                          type="date"
                          value={formData1.drivingLicenseExpiryDate}
                          onChange={handleInputChange1}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Passport Number"
                          name="passportNumber"
                          value={formData1.passportNumber}
                          onChange={handleInputChange1}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Passport Expiry Date"
                          name="passportExpiryDate"
                          type="date"
                          value={formData1.passportExpiryDate}
                          onChange={handleInputChange1}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} role="button" type="submit">
                          <Button
                            sx={{
                              backgroundColor: '#ede7f6',
                              color: '#5e35b1',
                              transition: 'background-color 0.3s, color 0.3s',
                              '&:hover': {
                                backgroundColor: '#5e35b1',
                                color: '#ede7f6'
                              }
                            }}
                            type="submit"
                          >
                            Next
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </TabPanel>
                <TabPanel value="3">
                  <form onSubmit={handleSubmit2}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextField
                          name="fatherName"
                          label="Father Name"
                          placeholder="Father Name"
                          fullWidth
                          value={formData2.fatherName}
                          onChange={handleChange2}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="motherName"
                          label="Mother Name"
                          placeholder="Mother Name"
                          fullWidth
                          value={formData2.motherName}
                          onChange={handleChange2}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <InputLabel id="has-siblings-label">Has Siblings</InputLabel>
                          <Select
                            labelId="has-siblings-label"
                            id="has-siblings"
                            name="hasSiblings"
                            value={formData2.hasSiblings}
                            onChange={handleChange2}
                          >
                            <MenuItem value={false}>No</MenuItem>
                            <MenuItem value={true}>Yes</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {formData2.hasSiblings && (
                        <Grid item xs={12} className="mt-1">
                          {formData2.siblingType.map((type, index) => (
                            <Grid container spacing={2} key={index} className="mt-1">
                              <Grid item xs={3}>
                                <FormControl fullWidth>
                                  <InputLabel id={`sibling-type-label-${index}`}>Sibling Type</InputLabel>
                                  <Select
                                    labelId={`sibling-type-label-${index}`}
                                    id={`sibling-type-${index}`}
                                    value={formData2.siblingType[index]}
                                    onChange={(e) => handleSiblingTypeChange(index, e)}
                                  >
                                    <MenuItem value="Brother">Brother</MenuItem>
                                    <MenuItem value="Sister">Sister</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  label="Sibling Name"
                                  placeholder="Sibling Name"
                                  fullWidth
                                  value={formData2.siblingNames[index]}
                                  onChange={(e) => handleSiblingNameChange(index, e)}
                                />
                              </Grid>
                            </Grid>
                          ))}
                          <Button onClick={handleAddSibling} variant="contained" color="primary" className="mt-1">
                            Add Sibling
                          </Button>
                        </Grid>
                      )}
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <InputLabel id="marital-status-label">Marital Status</InputLabel>
                          <Select
                            labelId="marital-status-label"
                            id="marital-status"
                            name="maritalStatus"
                            value={formData2.maritalStatus}
                            onChange={handleChange2}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="spouseName"
                          label="Spouse Name"
                          placeholder="Spouse Name"
                          fullWidth
                          value={formData2.spouseName}
                          onChange={handleChange2}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="spouseMobileNumber"
                          label="Spouse Contact Number"
                          placeholder="Spouse Contact Number"
                          fullWidth
                          value={formData2.spouseMobileNumber}
                          onChange={handleChange2}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} role="button" type="submit">
                          <Button
                            sx={{
                              backgroundColor: '#ede7f6',
                              color: '#5e35b1',
                              transition: 'background-color 0.3s, color 0.3s',
                              '&:hover': {
                                backgroundColor: '#5e35b1',
                                color: '#ede7f6'
                              }
                            }}
                            type="submit"
                          >
                            Next
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </TabPanel>
                <TabPanel value="4">
                  <form onSubmit={handleSubmit3}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextField
                          name="companyName"
                          label="Name Of The Company"
                          placeholder="Name Of The Company"
                          fullWidth
                          required
                          value={formData3.companyName}
                          onChange={handleChange3}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="fromDate"
                          label="From Date"
                          type="date"
                          fullWidth
                          value={formData3.fromDate}
                          onChange={handleChange3}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="toDate"
                          label="To Date"
                          type="date"
                          fullWidth
                          value={formData3.toDate}
                          onChange={handleChange3}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="designation"
                          label="Designation"
                          placeholder="Designation"
                          fullWidth
                          value={formData3.designation}
                          onChange={handleChange3}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="roleDescription"
                          label="Role Description"
                          placeholder="Role Description"
                          fullWidth
                          value={formData3.roleDescription}
                          onChange={handleChange3}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="softwareExposure"
                          label="Software Exposure"
                          placeholder="Enter each option on a new line"
                          fullWidth
                          multiline
                          rows={4}
                          value={formData3.softwareExposure.join('\n')}
                          onChange={handleSoftwareExposureChange}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="languages"
                          label="Languages"
                          placeholder="Enter each option on a new line"
                          fullWidth
                          multiline
                          rows={4}
                          value={formData3.languages.join('\n')}
                          onChange={handleLanguagesChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} role="button" type="submit">
                          <Button
                            sx={{
                              backgroundColor: '#ede7f6',
                              color: '#5e35b1',
                              transition: 'background-color 0.3s, color 0.3s',
                              '&:hover': {
                                backgroundColor: '#5e35b1',
                                color: '#ede7f6'
                              }
                            }}
                            type="submit"
                          >
                            Next
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </TabPanel>
                <TabPanel value="5">
                  <form onSubmit={handleSubmit4}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextField
                          name="bankName"
                          label="Bank Name"
                          placeholder="Bank Name"
                          fullWidth
                          required
                          value={formData4.bankName}
                          onChange={handleChange4}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="branchName"
                          label="Bank Branch Name"
                          placeholder="Bank Branch Name"
                          fullWidth
                          required
                          value={formData4.branchName}
                          onChange={handleChange4}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="accountNumber"
                          label="Bank Account Number"
                          placeholder="Bank Account Number"
                          fullWidth
                          required
                          value={formData4.accountNumber}
                          onChange={handleChange4}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="ifscCode"
                          label="IFSC Code"
                          placeholder="IFSC Code"
                          fullWidth
                          required
                          value={formData4.ifscCode}
                          onChange={handleChange4}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="epfoUanNumber"
                          label="EPFO UAN Number"
                          placeholder="EPFO UAN Number"
                          fullWidth
                          value={formData4.epfoUanNumber}
                          onChange={handleChange4}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="esicNumber"
                          label="ESIC Number"
                          placeholder="ESIC Number"
                          fullWidth
                          value={formData4.esicNumber}
                          onChange={handleChange4}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} role="button" type="submit">
                          <Button
                            sx={{
                              backgroundColor: '#ede7f6',
                              color: '#5e35b1',
                              transition: 'background-color 0.3s, color 0.3s',
                              '&:hover': {
                                backgroundColor: '#5e35b1',
                                color: '#ede7f6'
                              }
                            }}
                            type="submit"
                          >
                            Next
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </TabPanel>
                <TabPanel value="6">
                  <form onSubmit={handleSubmit5}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} p={6} style={{ marginLeft: '555px' }}>
                        <label htmlFor="profilePictureInput" style={{ cursor: 'pointer' }}>
                          Select Profile Picture:
                          <input
                            type="file"
                            id="profilePictureInput"
                            accept="image/*"
                            onChange={handleProfilePictureChange}
                            style={{ display: 'none' }}
                          />
                        </label>

                        {formData5.profilePicture && (
                          <div>
                            <img
                              src={URL.createObjectURL(formData5.profilePicture)}
                              alt="Selected"
                              style={{ maxWidth: '100%', maxHeight: '200px' }}
                            />
                          </div>
                        )}
                        {!formData5.profilePicture && <p>No profile picture selected.</p>}
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Copy of CV"
                          value={formData5.copyOfCV}
                          onChange={(file) => handleFileChange5('copyOfCV', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Academic Certificates"
                          value={formData5.academicCertificates}
                          onChange={(file) => handleFileChange5('academicCertificates', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Technical Certificate"
                          value={formData5.technicalCertificate}
                          onChange={(file) => handleFileChange5('technicalCertificate', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Residence Proof"
                          value={formData5.residenceProof}
                          onChange={(file) => handleFileChange5('residenceProof', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Last Resignation Letter"
                          value={formData5.lastResignationLetter}
                          onChange={(file) => handleFileChange5('lastResignationLetter', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Last Employer Relieving Letter"
                          value={formData5.lastEmployerRelievingLetter}
                          onChange={(file) => handleFileChange5('lastEmployerRelievingLetter', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Experience Letter"
                          value={formData5.experienceLetter}
                          onChange={(file) => handleFileChange5('experienceLetter', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Immediate Supervisor Contact"
                          value={formData5.immediateSupervisorContact}
                          onChange={(file) => handleFileChange5('immediateSupervisorContact', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Tax Declaration"
                          value={formData5.taxDeclaration}
                          onChange={(file) => handleFileChange5('taxDeclaration', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Passport Visa Copy"
                          value={formData5.passportVisaCopy}
                          onChange={(file) => handleFileChange5('passportVisaCopy', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Bank Passbook"
                          value={formData5.bankPassbook}
                          onChange={(file) => handleFileChange5('bankPassbook', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Form 16"
                          value={formData5.form16}
                          onChange={(file) => handleFileChange5('form16', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="UAN PF"
                          value={formData5.uanPF}
                          onChange={(file) => handleFileChange5('uanPF', file)}
                          multiFile={false}
                          maxFileSize={10}
                          maxUploadFiles={1}
                        />
                      </Grid>
                      <Grid item xs={3} p={2}>
                        <FileUpload
                          title="Driving Licence Front & Back"
                          value={formData5.drivingLicenceFrontAndBack}
                          onChange={(file) => handleFileChange5('drivingLicenceFrontAndBack', file)}
                          multiFile={true}
                          maxFileSize={10}
                          maxUploadFiles={Infinity}
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} role="button" type="submit">
                      <div>
                        <Button
                          sx={{
                            backgroundColor: '#ede7f6',
                            color: '#5e35b1',
                            transition: 'background-color 0.3s, color 0.3s',
                            '&:hover': {
                              backgroundColor: '#5e35b1',
                              color: '#ede7f6'
                            }
                          }}
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value="7">
                  <Application1 />
                </TabPanel>
                <TabPanel value="8">
                  <Application2 />
                </TabPanel>
                <TabPanel value="10">
                  <Attendance />
                  <br />
                  <MonthlyAttendance />
                </TabPanel>
                <TabPanel value="11"></TabPanel>
              </TabContext>
            </Box>
            <Dialog
              maxWidth="md"
              open={opened}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: '500px', // Set your preferred width
                  maxHeight: '80vh' // Set your preferred max height
                }
              }}
            >
              <form onSubmit={handleSubmit}>
                <DialogTitle className="d-flex justify-content-between m-0">
                  <Typography variant="h3">Leave Request</Typography>
                  <ClearIcon onClick={() => setOpened(false)} />
                </DialogTitle>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={values}>
                    <Box className="pe-1" sx={{ borderBottom: 1, borderColor: 'divider', alignItems: 'center' }}>
                      <TabList onChange={handlerequests}>
                        <Tab
                          label={<div style={{ display: 'flex', alignItems: 'center' }}>Leaves</div>}
                          value="1"
                          style={{ fontSize: '15px' }}
                        />
                        {/* <Tab label={<div style={{ display: 'flex', alignItems: 'center' }}>OD</div>} value="2" style={{ fontSize: '15px' }} />
            <Tab
              label={<div style={{ display: 'flex', alignItems: 'center' }}> Comp-Off</div>}
              value="3"
              style={{ fontSize: '15px' }}
            /> */}
                      </TabList>
                      <Divider />
                      <TabPanel value="1">
                        <DialogContent className="m-1">
                          <div className="d-flex justify-content-evenly align-items-center mr-0">
                            {' '}
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                <Avatar alt="Profile" sx={{ width: 60, height: 60, backgroundColor: '#fff8e1', color: '#efba2b' }}>
                                  {leavesCheck?.sickLeave}{' '}
                                </Avatar>
                                <Typography variant="h5" sx={{ margin: 0, textAlign: 'center', marginTop: '1rem' }}>
                                  SL
                                </Typography>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                <Avatar alt="Profile" sx={{ width: 60, height: 60, backgroundColor: '#b9f6ca', color: '#00c853' }}>
                                  {leavesCheck?.casualleave}
                                </Avatar>
                                <Typography variant="h5" sx={{ margin: 0, textAlign: 'center', marginTop: '1rem' }}>
                                  CL
                                </Typography>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                <Avatar alt="Profile" sx={{ width: 60, height: 60, backgroundColor: '#e7e0f4', color: '#8255d3' }}>
                                  {leavesCheck?.earnedLeave}
                                </Avatar>
                                <Typography variant="h5" sx={{ margin: 0, textAlign: 'center', marginTop: '1rem' }}>
                                  EL
                                </Typography>
                              </div>
                            </div>
                          </div>

                          <Box sx={{ minWidth: 120 }} xs={4} p={2}>
                            <FormControl fullWidth>
                              <InputLabel>Leave</InputLabel>
                              <Select label="Leave" value={formData.leavetype} name="leavetype" onChange={handleChange}>
                                <MenuItem value={'SL'}>SL - Sick Leave</MenuItem>
                                <MenuItem value={'CL'}>CL - Casual Leave</MenuItem>
                                <MenuItem value={'EL'}>EL - Earned Leave</MenuItem>
                                <MenuItem value={'Comp-Off'}>Comp-Off</MenuItem>
                                <MenuItem value={'OD'}>OD - On Duty</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          {formData.leavetype === 'Comp-Off' && (
                            <Box sx={{ minWidth: 120 }} xs={4} p={2}>
                              <FormControl fullWidth>
                                <InputLabel>Comp-Off</InputLabel>
                                <Select value={compoff} label="Comp-Off" onChange={handleChangecompoff}>
                                  <MenuItem value={`${compOff.holidaydate} - ${compOff.expirydate}`}>
                                    Holiday Date - {compOff?.holidaydate} &nbsp; Expiry Date - {compOff?.expirydate}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          )}

                          <Grid xs={3} p={2}>
                            <TextField
                              id="to-date-picker"
                              label="From Date"
                              type="date"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={formData.leavefromdate}
                              name="leavefromdate"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid xs={3} p={2}>
                            <TextField
                              id="to-date-picker"
                              label="To Date"
                              type="date"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={formData.leavetodate}
                              name="leavetodate"
                              onChange={handleChange}
                            />
                          </Grid>

                          <Box sx={{ minWidth: 120 }} xs={4} p={2}>
                            <FormControl fullWidth>
                              <TextField
                                label="Description"
                                multiline
                                rows={4}
                                style={{ backgroundColor: 'transparent !important' }}
                                value={formData.leavereason}
                                name="leavereason"
                                onChange={handleChange}
                              />
                            </FormControl>
                          </Box>

                          {/* <Alert
                  variant="outlined"
                  severity="warning"
                  style={{
                      borderColor: '#c6282f',
                      backgroundColor: '#ef9a9a',
                      color: '#c6282f',
                      marginBottom: '20px',
                      display: 'flex',
                      alignItems: 'center',
                  }}
                  icon={<WarningRoundedIcon sx={{ fontSize: 20, marginRight: 1, color: '#c6282f' }} />} // Customize icon here
              >
                  You don't have a
                  leave balance. If you take leave, it will be considered as LOP (Loss Of Pay).
              </Alert> */}
                        </DialogContent>
                        <br />
                        <DialogActions className="d-flex justify-content-center mb-1">
                          <Button variant="outlined" onClick={() => setOpened(false)}>
                            Clear
                          </Button>
                          <Button variant="contained" type="submit">
                            Request
                          </Button>
                        </DialogActions>
                      </TabPanel>
                      <TabPanel value="2">
                        <Grid xs={3} p={2}>
                          <TextField
                            id="to-date-picker"
                            label="From Date"
                            type="date"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={fromDate}
                            name="leavefromdate"
                            onChange={handleFromDate}
                          />
                        </Grid>
                        <Grid xs={3} p={2}>
                          <TextField
                            id="to-date-picker"
                            label="To Date"
                            type="date"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={toDate}
                            name="leavetodate"
                            onChange={handleToDate}
                          />
                        </Grid>
                        <Grid xs={3} p={2}>
                          <TextField id="outlined-basic" label="Location" variant="outlined" sx={{ width: '100%' }} />
                        </Grid>
                        <Box sx={{ minWidth: 120 }} xs={4} p={2}>
                          <FormControl fullWidth>
                            <TextField
                              label="Work"
                              multiline
                              rows={4}
                              style={{ backgroundColor: 'transparent !important' }}
                              value={work}
                              name="leavereason"
                              onChange={handleWork}
                            />
                          </FormControl>
                        </Box>
                        <DialogActions className="d-flex justify-content-center mb-1">
                          <Button variant="outlined" onClick={() => setOpened(false)}>
                            Clear
                          </Button>
                          <Button variant="contained" type="submit">
                            Request
                          </Button>
                        </DialogActions>
                      </TabPanel>
                      <TabPanel value="3">
                        <Grid xs={3} p={2}>
                          <TextField
                            id="to-date-picker"
                            label=" Date"
                            type="date"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={date}
                            name="leavefromdate"
                            onChange={handleDate}
                          />
                        </Grid>
                        <Box sx={{ minWidth: 120 }} xs={4} p={2}>
                          <FormControl fullWidth>
                            <TextField
                              label="Reason"
                              multiline
                              rows={4}
                              style={{ backgroundColor: 'transparent !important' }}
                              value={reason}
                              name="leavereason"
                              onChange={handleReason}
                            />
                          </FormControl>
                        </Box>

                        <DialogActions className="d-flex justify-content-center mb-1">
                          <Button variant="outlined" onClick={() => setOpened(false)}>
                            Clear
                          </Button>
                          <Button variant="contained" type="submit">
                            Request
                          </Button>
                        </DialogActions>
                      </TabPanel>
                    </Box>
                  </TabContext>
                </Box>
              </form>
            </Dialog>
          </MainCard>
        </>
      )}
    </>
  );
};

export default UserDetails;
