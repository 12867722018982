/*eslint-disable */
import {
  Avatar,
  Box,
  ButtonBase,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  tableCellClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Input
} from '@mui/material';
import './Attendancerequest.css';
import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  IconDots,
  IconDotsVertical,
  IconDownload,
  IconEdit,
  IconEye,
  IconHistoryToggle,
  IconPlus,
  IconTrash,
  IconUpload
} from '@tabler/icons';
// import React, { useState, useEffect } from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable, MRT_EditActionButtons } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import * as XLSX from 'xlsx';
import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';
import {
  ATTENDANCE_POSTMONTHLY,
  DAILYATTENDANCE_POST,
  DAILY_ATTENDANCE,
  DAILY_FINALATTENDANCE,
  EMPLOYEE_STATUS,
  MANAGERBASED_ATTENDANCE,
  MONTHLY_ATTENDANCE,
  MONTHLY_POST,
  PROFILES_GET,
  PROFILES_GETBY_CAE,
  PROFILES_GETBY_STATUS,
  PROFILES_GET_ID,
  UNLOGGED_USER
} from 'api/apiEndPoint';
import { fetchData, postData } from 'utils/apiUtils';
import { useMemo } from 'react';
import { useFormik } from 'formik';
import { DatePicker } from 'rsuite';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { format, parse, parseISO, toDate } from 'date-fns';
import { FormattedDate } from 'rsuite/esm/CustomProvider';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Attendance = () => {
  const [employeeDatas, setEmployeeDatas] = useState(null);
  const [localData, setLocalData] = useState();
  const [activeEmp, setActiveEmp] = useState([]);
  const [empId, setEmpId] = useState();
  const [empCode, setEmpCode] = useState();
  const [status, setstatus] = useState([
    { value: 'Present', factor: 1, casualleave: false, earnedleave: false, sickleave: false, lop: false, compoff: false },
    { value: 'Absent', factor: 0, casualleave: false, earnedleave: false, sickleave: false, lop: true, compoff: false },
    { value: 'CL', factor: 0, casualleave: true, earnedleave: false, sickleave: false, lop: false, compoff: false },
    { value: 'SL', factor: 0, casualleave: false, earnedleave: false, sickleave: true, lop: false, compoff: false },
    { value: 'EL', factor: 0, casualleave: false, earnedleave: true, sickleave: false, lop: false, compoff: false },
    { value: 'Compoff', factor: 1, casualleave: false, earnedleave: false, sickleave: false, lop: false, compoff: true },
    { value: 'Compoff Earning', factor: 1, casualleave: false, earnedleave: false, sickleave: false, lop: false, compoff: true },
    { value: 'Compoff Adjustment', factor: 0, casualleave: false, earnedleave: false, sickleave: false, lop: false, compoff: false },
    { value: 'On Duty', factor: 1, casualleave: false, earnedleave: false, sickleave: false, lop: false, compoff: false },
    ,
  ]);
  const [formData, setFormData] = useState({
    employeeName: '',
    employeeCode: '',
    assignedDate: null, // or any initial value you want
    clockIn: dayjs(), // or any initial value you want
    clockOut: dayjs(), // or any initial value you want
    status: ''
  });
  const [formData1, setFormData1] = useState({
    curdate: null,
    empid: '',
    clockin: null,
    clockout: null,
    status: '',
    logid: '',
    factor: 0,
    sickleave: false,
    earnedleave: false,
    casualleave: false,
    compoff: false,
    holidaywork: false
  });

  function useCreateUser() {
    return (user) => {
      console.log('Creating user:', user);
    };
  }

  const createUser = useCreateUser();

  const handleCreateUser = async ({ values, table }) => {
    await createUser(values);
    table.setCreatingRow(null);
  };

  const handleSaveUser = async ({ values, table }) => {
    await updateUser(values);
    table.setEditingRow(null);
  };

  const [opened, setOpened] = useState(false);
  const [selectedName, setSelectedName] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedName({ ...selectedName, [name]: value });
  };

  const handleEdit = (name, value) => {
    setSelectedName({ ...selectedName, [name]: value });
  };

  const [viewData, setViewData] = useState([]);

  const [open, setOpen] = useState(false);
  const handleView = (id) => {
    setOpen(true);
    setViewData(id?.original);
    console.log(id?.original, 'viewData');
    let intime = new Date(id.original.attdate + ' ' + id.original.clock_in).toISOString();
    let outtime =
      id.original.clock_out !== 'Invalid Date'
        ? new Date(id.original.attdate + ' ' + id.original.clock_out).toISOString()
        : new Date(id.original.attdate + ' ' + new Date().toTimeString()).toISOString();

    setFormData1({
      ...formData1,
      empid: id.original.employeeid,
      curdate: id.original.attdate,
      status: id.original.status,
      clockin: intime,
      clockout: outtime,
      logid: id.original._id
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('action', {
        header: 'Action',
        Cell: ({ row }) => (
          <div style={{ marginLeft: '-10px' }}>
            <IconButton onClick={() => handleEdit(row)}>
              <ModeEditOutlineTwoToneIcon style={{ color: '#2196f3' }} />
            </IconButton>
          </div>
        )
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row && row.original && row.original.employeename && (
              <Avatar alt="Profile" style={{ marginRight: '8px' }}>
                {row.original.employeename.charAt(0)}
              </Avatar>
            )}
            {row && row.original && row.original.employeename}
          </div>
        )
      }),
      columnHelper.accessor('employeeCode', {
        header: 'Employee Code'
      }),
      columnHelper.accessor('attdate', {
        header: 'Attendance Date',
        Cell: ({ row }) => (
          <div>
            {format(new Date(row.original.attdate), 'dd-MM-yyyy')}
          </div>
        )
      }),
      columnHelper.accessor('clock_in', {
        header: 'In Time'
      }),
      columnHelper.accessor('clock_out', {
        header: 'Out Time'
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleView(row)}
              sx={{
                backgroundColor: '#ede7f6',
                color: '#5e35b1',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: '#5e35b1',
                  color: '#ede7f6'
                }
              }}
            >
              View
            </Button>
          </div>
        )
      })
    ],
    []
  );
  const UnLoggedUsers = useMemo(
    () => [
      // columnHelper.accessor('action', {
      //   header: 'Action',
      //   Cell: ({ row }) => (
      //     <div style={{ marginLeft: '-10px' }}>
      //       <IconButton onClick={() => handleEdit(row)}>
      //         <ModeEditOutlineTwoToneIcon style={{ color: '#2196f3' }} />
      //       </IconButton>
      //     </div>
      //   )
      // }),
      columnHelper.accessor('NameOfCandidate', {
        header: 'Employee Name'
      }),
      columnHelper.accessor('EmployeeCode', {
        header: 'Employee Code'
      }),
      columnHelper.accessor('Category', {
        header: 'Category'
      }),
      columnHelper.accessor('ContactNumber', {
        header: 'ContactNumber'
      }),
      columnHelper.accessor('Designation', {
        header: 'Designation'
      }),
      columnHelper.accessor('Location', {
        header: 'Location'
      }),
      columnHelper.accessor('Role', {
        header: 'Role'
      }),
      columnHelper.accessor('Team', {
        header: 'Team'
      }),

    ],
    []
  );

  const fileInputRef = React.createRef();
  const handleImportClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        await uploadToServer(parsedData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const uploadToServer = async (data) => {
    try {
      const response = await fetch('http://localhost:3001/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data })
      });

      console.log('Upload successful:', response);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  const [view, setView] = useState({
    visible: false,
    mode: 'Initial'
  });

  const theme = useTheme();
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(dataWithSNo);
    download(csvConfig)(csv);
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleEmployee = (selectedEmployee) => {
    console.log('handleEmployee called with:', selectedEmployee); // Log the selected employee
    setEmpId(selectedEmployee?._id);
    setFormData({
      ...formData,
      employeeName: selectedEmployee ? selectedEmployee.NameOfCandidate : '',
      employeeCode: selectedEmployee?.EmployeeCode
    });
  };

  // Filter employees based on search term
  const filteredEmp = activeEmp.filter((emp) =>
    emp.EmployeeCode?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  console.log(filteredEmp, 'activeEmp');

  console.log(handleEmployee, 'handleEmployee');

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Function to handle changes in TimePicker
  const handleTimePickerChange = (name, time) => {
    setFormData({
      ...formData,
      [name]: time // Store the Dayjs object directly
    });
  };
  const [akey, setakey] = useState(0);
  const reset = () => {
    setakey(Math.random());
  };
  const postedData = {
    curdate: formData?.assignedDate,
    empid: empId,
    clockin: formData?.clockIn,
    clockout: formData?.clockOut,
    status: formData?.status, //status text
    logid: '',
    factor: 1, //0 - leave ,0.5 half day
    sickleave: false,
    earnedleave: false,
    casualleave: false,
    compoff: false,
    holidaywork: false
  };

  const resetForm = () => {
    setFormData({
      ...formData,
      employeeName: '', // Reset employeeName field
      employeeCode: '',
      assignedDate: null,
      clockIn: null,
      clockOut: null,
      status: ''
    });
    setEmpId('');
    reset();
    fetchDataAndUpdate();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postDatatoDB = postData(DAILYATTENDANCE_POST, postedData);
    setOpenedd(false);
    resetForm();

    setFormData({
      ...formData,
      employeeName: '',
      employeeCode: '',
      assignedDate: null, // or any initial value you want
      clockIn: null, // or any initial value you want
      clockOut: null, // or any initial value you want
      status: ''
    });
  };

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const employeeDate = `${year}-${month}-${day}`;
  const [attendanceData, setAttendanceData] = useState([]);
  const [dataWithSNo, setDataWithSNo] = useState([]);

  const fetchDataAndUpdate = async (employeeDate) => {
    console.log('Effect', employeeDate);
    try {
      const localStore = localStorage.getItem('userData');
      console.log('LocalStorage data:', localStore);

      if (localStore) {
        const parsedData = JSON.parse(localStore);
        // console.log('Parsed LocalStorage data:', parsedData);
        console.log('Parsed Employee id:', parsedData.employeeId);

        const employeeId = parsedData.employeeId || parsedData.employeeid; // Ensure correct property name
        if (employeeId) {
          console.log('Employee ID:', employeeId); // Log the employeeId

          const apiUrl = MANAGERBASED_ATTENDANCE(employeeId); // Use the employeeId in the API URL
          console.log('managerbased attendance ', apiUrl);

          const data = await fetchData(apiUrl, parsedData.accessToken); // Ensure you're passing the token if required
          setAttendanceData(data);
          console.log('Fetched Attendance Data:', data);
        } else {
          console.error('Employee ID not found in parsed data');
        }
      }
    } catch (err) {
      console.error('Error fetching attendance data:', err);
    }
  };

  console.log('resetedFormdata', formData);
  console.log('attendanceData', attendanceData);

  useEffect(() => {
    console.log('Effect triggered');
    if (localData && localData.team) {
      const filteredData = attendanceData
        .filter((employee) => employee.employeeTeam === localData.team)
        .map((obj, index) => ({
          ...obj,
          ...obj.balance,
          SNo: index + 1
        }));
      setDataWithSNo(filteredData);
      console.log('Filtered Data:', filteredData);
      // Now you can set the filtered data to state or use it as needed
    }
    fetchDataAndUpdate(employeeDate);
  }, [localData]);

  // useEffect(() => {
  //   fetchDataAndUpdate(employeeDate);
  // }, []);

  const [employee, setEmployee] = useState([]);

  const allEmployees = async () => {
    try {
      const apiUrl = PROFILES_GET;

      const localStore = localStorage.getItem('userData');
      console.log('LocalStorage data:', localStore);
      const parsedData = JSON.parse(localStore);
      console.log(parsedData.accessToken, "apiUrlffffffff");
      const data = await fetchData(apiUrl, parsedData.accessToken);
      console.log('all employeessss:', data);
      const TeamBasedEmp = data?.data.filter(emp => emp.Team === parsedData.team);
      setEmployee(TeamBasedEmp);
      console.log(TeamBasedEmp, "employeesOfLoggedInUserTeam");

    } catch (err) {
    }
  };

  useEffect(() => {
    allEmployees()
  }, [])
  console.log(employee, "employee");



  const [unmatchedEmployees, setUnmatchedEmployees] = useState([]);

  useEffect(() => {
    const compareData = async (date, id) => {
      try {
        const apiUrl = UNLOGGED_USER(date, id);
        console.log('Daily unlogged user URL:', apiUrl);

        const localStore = localStorage.getItem('userData');
        console.log('LocalStorage data:', localStore);
        const parsedData = JSON.parse(localStore);
        console.log('Parsed Data:', parsedData);

        const data = await fetchData(apiUrl);
        console.log('Unlogged user data:', data);

        setUnmatchedEmployees(data);
      } catch (err) {
        console.error('Error fetching attendance data:', err);
      }
    };

    const localStore = localStorage.getItem('userData');
    const parsedData = JSON.parse(localStore);
    const employeeId = parsedData.employeeId;

    compareData(employeeDate, employeeId);
  }, [employeeDate]);


  // useEffect(() => {
  //   const compareData = async (employeeDate) => {
  //     try {
  //       if (employee.length > 0 && attendanceData.length > 0) {
  //         const employeeIdsFromAttendance = attendanceData.map(emp => emp.employeeCode);
  //         console.log(employeeIdsFromAttendance, "employeeIdsFromAttendance");
  //         const apiUrl = UNLOGGED_USER(employeeDate);
  //         console.log(apiUrl, "unlogged users");
  //         const data = await fetchData(apiUrl);
  //         console.log(data, "unloggeddata");
  //         const unmatched = employee.filter(emp => !employeeIdsFromAttendance.includes(emp.EmployeeCode));
  //         console.log(unmatched, "unmatched");
  //         setUnmatchedEmployees(unmatched);
  //       }
  //     } catch (err) {
  //       console.error('Error comparing data:', err);
  //     }
  //   };

  //   // Assuming localData contains employeeDate
  //   if (localData && localData.employeeDate) {
  //     compareData(localData.employeeDate);
  //   }
  // }, [localData]);


  console.log(unmatchedEmployees, "unmatchedEmployees");





  const [openedd, setOpenedd] = useState(false);
  const handleCloseNewUser = () => {
    console.log('handleCloseNewUser called');
    setOpenedd(false);
  };

  const [unloggedUser, setUnloggedUser] = useState(false);

  const handUnloggedUsers = () => {
    setUnloggedUser(!unloggedUser);
  };

  const UnLoggedUser = useMaterialReactTable({
    columns: UnLoggedUsers,
    data: unmatchedEmployees,
    positionToolbarAlertBanner: 'bottom',
    onCreatingRowSave: handleCreateUser,
    onEditingRowSave: handleSaveUser,
    renderTopToolbarCustomActions: () => (
      <>

        <div style={{ marginLeft: '-26.5rem' }}>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} accept=".xls,.xlsx" />
          {/* <Button onClick={handleExportData} variant="contained" color="primary" startIcon={<IconDownload />}>
            Export
          </Button> */}
        </div>

        <div style={{ marginLeft: '-36rem' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenedd(true);
              resetForm();
              // table.setCreatingRow(true);
            }}
          >
            Create User Attendance
          </Button>
        </div>

        <div style={{ marginLeft: '-59rem' }}>
          <Button variant="contained" color="primary" onClick={handUnloggedUsers}>
            UnLogged Users
          </Button>
        </div>
      </>
    )
  });




  const table = useMaterialReactTable({
    columns,
    data: attendanceData,
    positionToolbarAlertBanner: 'bottom',
    onCreatingRowSave: handleCreateUser,
    onEditingRowSave: handleSaveUser,
    renderTopToolbarCustomActions: () => (
      <>

        <div style={{ marginLeft: '-26.5rem' }}>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} accept=".xls,.xlsx" />
          {/* <Button onClick={handleExportData} variant="contained" color="primary" startIcon={<IconDownload />}>
            Export
          </Button> */}
        </div>

        <div style={{ marginLeft: '-36rem' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenedd(true);
              resetForm();
              // table.setCreatingRow(true);
            }}
          >
            Create User Attendance
          </Button>
        </div>

        <div style={{ marginLeft: '-59rem' }}>
          <Button variant="contained" color="primary" onClick={handUnloggedUsers}>
            UnLogged Users
          </Button>
        </div>
      </>
    )
  });

  // const updatedValues = {
  //   // "curdate": formData?.curdate,17:52 22-04-2024
  //   // "empid": formData?.empid,
  //   // "clockin": selectedTime,
  //   // "clockout": selectedTimeOut,
  //   // "status": status, //status text
  //   "logid": "",
  //   "factor": 1, //0 - leave ,0.5 half day
  //   "sickleave": false,
  //   "earnedleave": false,
  //   "casualleave": false,
  //   "compoff": false,
  //   "holidaywork": false
  // }
  const [requestData, setRequestData] = React.useState({
    curdate: formData.curdate,
    empid: formData.empid ? formData.empid : '',
    clockin: formData.clockin,
    clockout: formData.clockout,
    status: formData.status,
    logid: formData.logid,
    factor: formData.factor,
    sickleave: formData.sickleave,
    earnedleave: formData.earnedleave,
    casualleave: formData.casualleave,
    compoff: formData.compoff,
    holidaywork: formData.holidaywork
  });

  const monthlyPost = async () => {
    const currentDate = new Date();

    // Extract date, month, and year from the current date
    const date = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const year = currentDate.getFullYear();
    try {
      let data = {
        month: month,
        year: year,
        date: date,
        employeeid: requestData.empid
      };
      await postData(ATTENDANCE_POSTMONTHLY, data);
      await postData(MONTHLY_POST, data);
      console.log(postData, 'hhhhhhh');
      setOpened(false);
      localStorage.setItem('postedFormData', JSON.stringify(requestData));
      console.log(localStorage, 'postedFormData');
    } catch (error) {
      console.error('Error in monthlyPost:', error);
    }
  };
  console.log(monthlyPost, 'monthlyPost');

  const handleApprove = async () => {
    try {
      const resp = { ...requestData };
      console.log('requestData', requestData);
      const apiUrl = DAILYATTENDANCE_POST;
      console.log('API Endpoint:', apiUrl);
      const data = await postData(apiUrl, requestData);
      console.log(data, 'for final attendance');
      setOpen(false);
      fetchDataAndUpdate(employeeDate);
      console.log('requestData------------>>>', requestData);
      await monthlyPost();
    } catch (err) {
      console.error('Error approving attendance:', err);
    }
  };

  console.log(handleApprove, 'handleApprove');

  //For Attendence
  console.log('localData', activeEmp);

  useEffect(() => {
    const fetchDataAndUpdate = async (employeeDate) => {
      try {
        const localStore = localStorage.getItem('userData');
        if (localStore) {
          const parsedData = JSON.parse(localStore);
          const apiUrl = DAILY_ATTENDANCE(employeeDate);
          console.log('dailyyyyyy', apiUrl);
          const data = await fetchData(apiUrl);
          setEmployeeDatas(data?.[0]);
          console.log('Parsed Data:', data?.[0]);
        }
      } catch (err) {
        console.error('Error fetching attendance data:', err);
      }
    };

    fetchDataAndUpdate(employeeDate);
  }, [employeeDate]);
  useEffect(() => {
    setRequestData({
      curdate: formData1.curdate,
      empid: formData1.empid ? formData1.empid : '',
      clockin: formData1.clockin,
      clockout: formData1.clockout,
      status: formData1.status,
      logid: formData1.logid,
      factor: formData1.factor,
      sickleave: formData1.sickleave,
      earnedleave: formData1.earnedleave,
      casualleave: formData1.casualleave,
      compoff: formData1.compoff,
      holidaywork: formData1.holidaywork
    });
  }, [formData1]);

  useEffect(() => {
    let isMounted = true;

    const fetchDataAndUpdate = async () => {
      try {
        const locals = localStorage.getItem('userData');
        if (!locals) {
          return;
        }

        const parsedValue = JSON.parse(locals);
        console.log(parsedValue, 'parsedValue');
        if (!parsedValue) {
          return;
        }

        const endpoint = PROFILES_GETBY_STATUS('active');
        console.log(endpoint, 'endpoint');
        const fetchEmployees = await fetchData(endpoint, parsedValue?.accessToken);
        console.log(parsedValue?.accessToken, 'fetchEmployees');
        if (isMounted) {
          setActiveEmp(fetchEmployees?.data);
          console.log(fetchEmployees?.data, 'fetchEmployees?.data');
        }

        const endpointId = PROFILES_GET_ID(empId);

        if (isMounted) {
          setLocalData(parsedValue);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchDataAndUpdate();

    return () => {
      isMounted = false;
    };
  }, [empId]);

  const [inputValue, setInputValue] = useState(viewData?.clock_in || '');

  useEffect(() => {
    setInputValue(viewData?.clock_in || '');
  }, [viewData]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    let day = date.getDate();
    day = day < 10 ? '0' + day : day;
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (name, value) => {
    try {
      const parsedValue = typeof value === 'string' ? parseTimeString(value) : value;
      const formattedValue = parsedValue instanceof Date ? formatDate(parsedValue) : parsedValue;
      console.log('formattedValue', formattedValue);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedValue
      }));
    } catch (error) {
      console.error('Error in handleInputChange:', error);
    }
  };

  const [clockOutValue, setClockOutValue] = useState(viewData?.clock_out || '');

  useEffect(() => {
    setClockOutValue(viewData?.clock_out || '');
  });

  // const handleClockOutChange = (e) => {
  //     setClockOutValue(e.target.value);
  // };
  const handleTimeChange = (time) => {
    // setSelectedTime(time);
    if (time) {
      const updatedRequestData = { ...requestData, clockin: time.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') };
      setRequestData(updatedRequestData);
    }
  };

  const handleTimeChangeOut = (time) => {
    //  setSelectedTimeOut(time);
    console.log("time",JSON.stringify(time))
    if (time) {
    //  const updatedRequestData = { ...requestData, clockout: time.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') };
      const updatedRequestData = { ...requestData, clockout: new Date(time.format('YYYY-MM-DD HH:mm:ss')).toISOString() };
      setRequestData(updatedRequestData);
    }
  };
  const handleStatus = (time) => {
    let dd = time.target.value;
    console.log('time', dd);
    let stat = status.findIndex((x) => x.value === dd);

    console.log('stat', status[stat]);
    let final = status[stat];

    const updatedRequestData = {
      ...requestData,
      status: final.value,
      factor: final.factor,
      sickleave: final.sickleave,
      earnedleave: final.earnedleave,
      casualleave: final.casualleave,
      compoff: final.compoff
    };
    setRequestData(updatedRequestData);
  };

  return (
    <div className="max">
      {!unloggedUser && (
        <div className="justify-content-end ">{view.mode === 'Initial' && <MaterialReactTable table={table} />}</div>)}
      <Dialog open={open} TransitionComponent={Transition} keepMounted fullWidth maxWidth="md">
        <DialogContent>
          <div className="section-style m-1 conrainer">
            <div className=" row  m-0">
              <Grid container className="align-items-center" spacing={2}>
                <Grid item xs={3}>
                  <div className=" col-3">
                    <div className="image-container mt-2">
                      <img
                        src={viewData?.clock_inimageurl}
                        alt="clock in"
                        className="rounded"
                        style={{ width: '100px', height: '100px', fontSize: '36px', backgroundColor: '#90caf9' }}
                      />
                    </div>
                    <div className="level mt-1">
                      <p style={{ padding: '5px 10px', marginLeft: '-5px' }}>{viewData?.employeeCode}</p>
                    </div>
                    <p className="name text-center">{viewData?.employeename}</p>
                    {/* <p className="job_title text-center">{viewData?.employee_title}</p> */}
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div className="border-left p-2">
                    <Grid container className="align-items-center">
                      <Grid xs={5} className="text-muted mt-1">
                        Project
                      </Grid>
                      <Grid xs={2} className="mt-1">
                        :
                      </Grid>
                      <Grid xs={5} className={viewData?.projectname === '' ? 'mt-1 text-danger' : 'mt-1'}>
                        {viewData?.projectname === '' ? 'Not Mentioned' : viewData?.projectname}
                      </Grid>

                      <Grid xs={5} className="text-muted mt-1">
                        Date
                      </Grid>
                      <Grid xs={2} className="mt-1">
                        :
                      </Grid>
                      <Grid xs={5} className="mt-1">
                        {formData1?.curdate ? format(new Date(formData1.curdate), 'dd-MM-yyyy') : ''}
                      </Grid>
                      <Grid xs={5} className="text-muted mt-1">
                        In-Time
                      </Grid>
                      <Grid xs={2} className="mt-1">
                        :
                      </Grid>
                      {/* <Grid xs={5} className='mt-1'>{viewData?.clock_in}</Grid> */}
                      <Grid xs={5} className="mt-1">
                        <FormControl item>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']}>
                              <TimePicker
                                label="Clock In"
                                value={dayjs(formData1?.clockin)}
                                onChange={handleTimeChange}
                                sx={{ width: '100%' }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                      <Grid xs={5} className="text-muted mt-1">
                        In-Location
                      </Grid>
                      <Grid xs={2} className="mt-1">
                        :
                      </Grid>
                      <Grid xs={5} className="mt-1">
                        {viewData?.clock_inlocation}
                      </Grid>
                      <Grid xs={5} className="text-muted mt-1">
                        Out-Time
                      </Grid>
                      <Grid xs={2} className="mt-1">
                        :
                      </Grid>
                      <Grid item xs={5} className="mt-1">
                        <FormControl item>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']}>
                              <TimePicker
                                label="Clock Out"
                                value={dayjs(formData1?.clockout)}
                                onChange={handleTimeChangeOut}
                                sx={{ width: '100%' }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                      <Grid xs={5} className="text-muted mt-1">
                        Out-Location
                      </Grid>
                      <Grid xs={2} className="mt-1">
                        :
                      </Grid>
                      <Grid xs={5} className="mt-1">
                        {viewData?.clock_outlocation}
                      </Grid>
                      <Grid xs={5} className="text-muted mt-1">
                        Status
                      </Grid>
                      <Grid xs={2} className="mt-1">
                        :
                      </Grid>
                      <Grid xs={5} className="mt-1">
                        {/* {viewData?.status} */}
                        <Select
                          // id="emp-name-select"
                          displayEmpty
                          fullWidth
                          onChange={(e) => handleStatus(e)}
                          value={viewData?.status}
                        // label="Status"
                        >
                          {status?.map((data, index) => (
                            <MenuItem value={data?.value} key={index}>
                              {data?.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                    <hr />
                    <div className="d-flex justify-content-center">
                      <div className="me-1">
                        <Button className="custom-red" onClick={handleClose}>
                          Declined
                        </Button>
                      </div>
                      <div>
                        <Button className="custom-green" onClick={handleApprove}>
                          Approve
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="md"
        open={openedd}
        keepMounted
        PaperProps={{
          style: {
            width: '500px', //Set your preferred width
            maxHeight: '80vh' //Set your preferred max height
          }
        }}
      >
        <DialogTitle variant="h3" sx={{ marginTop: '15px' }}>
          Create New User
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent container spacing={2} sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '10px' }}>
            <FormControl item xs={8}>

              <TextField id="outlined-basic" label="" placeholder="Employee Name " variant="outlined" value={formData?.employeeName} disabled name="Employee Name" />

            </FormControl>

            <FormControl item xs={8}>
              <Autocomplete
                key={akey}
                disablePortal
                id="emp-name-select"
                options={filteredEmp}
                getOptionLabel={(option) => option?.EmployeeCode}
                onChange={(event, newValue) => handleEmployee(newValue)}
                Pass
                selected
                employee
                directly
                renderInput={(params) => <TextField {...params} placeholder="Select EmployeeCode" value={formData?.employeeCode} />}
              />
            </FormControl>
            <FormControl item xs={8}>
              <DatePicker
                oneTap
                style={{ width: 200 }}
                placeholder="Assigned Date"
                name="assignedDate"
                onChange={(date) => handleDateChange('assignedDate', date)}
                value={formData.assignedDate ? new Date(formData.assignedDate) : null}
              >
                {(params) => <TextField {...params} renderInput={(params) => <TextField {...params} value={formData.employeeName} />} />}
              </DatePicker>
            </FormControl>

            <FormControl item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Clock In"
                  sx={{ width: '100%' }}
                  onChange={(time) => handleTimePickerChange('clockIn', time)}
                  value={formData.clockIn} // Ensure value is a Dayjs object
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Clock Out"
                  sx={{ width: '100%' }}
                  onChange={(time) => handleTimePickerChange('clockOut', time)}
                  value={formData.clockOut} // Ensure value is a Dayjs object
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl item xs={12}>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                displayEmpty
                variant="outlined"
                name="status"
                onChange={handleFormChange}
                value={formData.status}
              >
                <MenuItem value="Present">Present</MenuItem>
                <MenuItem value="Absent">Absent</MenuItem>
                <MenuItem value="CL">CL</MenuItem>
                <MenuItem value="SL">SL</MenuItem>
                <MenuItem value="EL">EL</MenuItem>
                <MenuItem value="Compoff Earning">Compoff Earning</MenuItem>
                <MenuItem value="Compoff Adjustment">Compoff Adjustment</MenuItem>
                <MenuItem value="On Duty">On Duty</MenuItem>
                <MenuItem value="Holiday">Holiday</MenuItem>
                <MenuItem value="LOP">LOP</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" sx={{ borderRadius: '10px' }} onClick={handleCloseNewUser}>
              {' '}
              Cancel
            </Button>
            <Button type="submit" variant="contained" sx={{ borderRadius: '10px' }}>
              {' '}
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {unloggedUser && <MaterialReactTable table={UnLoggedUser} />}
    </div>
  );
};
export default Attendance;
