/*eslint-disable */
import { Button, ButtonBase, FormControl, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import React, { forwardRef, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { LEAVE_APPROVED, LEAVE_DECLINED } from 'api/apiEndPoint';
import { useMemo } from 'react';
import { fetchData } from 'utils/apiUtils';
import { Box } from '@mui/system';



const columnHelper = createMRTColumnHelper();


const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true
});



const approvedColumns = [
    columnHelper.accessor('employee_code', {
        header: 'Employee Code',
    }),
    columnHelper.accessor('employee_name', {
        header: 'Employee Name'
    }),
    columnHelper.accessor('employee_team', {
        header: 'Employee Team'
    }),
    columnHelper.accessor('employee_location', {
        header: 'Employee Location'
    }),
    columnHelper.accessor('leavefromdate', {
        header: 'From Date'
    }),
    columnHelper.accessor('leavetodate', {
        header: 'To Date'
    }),
    columnHelper.accessor('leavetype', {
        header: 'Leave Type'
    }),
    columnHelper.accessor('leavereason', {
        header: 'Leave Reason'
    }),
    columnHelper.accessor('status', {
        header: 'Status',
        Cell: ({ row }) => (
            <div
                style={{
                    display: 'flex',
                    gap: '10px',

                }}
            >

                <Button
                    onClick={() => handleApprove()}
                    sx={{
                        backgroundColor: '#b9f6ca',
                        color: '#00d5b7',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: '#97edaf',
                            color: '#00d5b7',
                        }
                    }}
                >
                    Approved
                </Button>
            </div>

        )
    })
];


const declinedColumns = [
    columnHelper.accessor('employee_code', {
        header: 'Employee Code',
    }),
    columnHelper.accessor('employee_name', {
        header: 'Employee Name'
    }),
    columnHelper.accessor('employee_team', {
        header: 'Employee Team'
    }),
    columnHelper.accessor('employee_location', {
        header: 'Employee Location'
    }),
    columnHelper.accessor('leavefromdate', {
        header: 'From Date'
    }),
    columnHelper.accessor('leavetodate', {
        header: 'To Date'
    }),
    columnHelper.accessor('leavetype', {
        header: 'Leave Type'
    }),
    columnHelper.accessor('leavereason', {
        header: 'Leave Reason'
    }),
    columnHelper.accessor('rejectedreason', {
        header: 'Rejected Reason'
    }),
    columnHelper.accessor('status', {
        header: 'Status',
        Cell: ({ row }) => (
            <div
                style={{
                    display: 'flex',
                    gap: '10px',

                }}
            >
                <Button
                    onClick={() => handleClose()}
                    sx={{
                        backgroundColor: '#e5a6a6',
                        color: '#c62d41',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: '#df8590',
                            color: '#c62d41',
                        }
                    }}
                >
                    Declined
                </Button>


            </div>

        )
    })
];





const LeaveInfo = () => {

    const [viewMode, setViewMode] = useState('Approved Leaves');
    const theme = useTheme();

    const [approved, setApproved] = useState([]);
    const [declined, setDeclined] = useState([]);

    useEffect(() => {
        const fetchDataAndUpdate = async () => {
            try {
                const localStore = localStorage.getItem('userData');
                if (localStore) {
                    const parsedData = JSON.parse(localStore);
                    const apiUrl = LEAVE_APPROVED;
                    const data = await fetchData(apiUrl);
                    setApproved(data);
                }
            } catch (err) {
                console.error('Error fetching attendance data:', err);
            }
        };
        fetchDataAndUpdate();
    }, []);


    useEffect(() => {
        const fetchDataAndUpdate = async () => {
            try {
                const localStore = localStorage.getItem('userData');
                if (localStore) {
                    const parsedData = JSON.parse(localStore);
                    const apiUrl = LEAVE_DECLINED;
                    const data = await fetchData(apiUrl);
                    setDeclined(data);
                }
            } catch (err) {
                console.error('Error fetching attendance data:', err);
            }
        };
        fetchDataAndUpdate();
    }, []);

    const approvedDataWithSNo = useMemo(() => {
        return approved.map((obj, index) => ({ ...obj, SNo: index + 1 }));
    }, [approved]);

    const approvedTable = useMaterialReactTable({
        columns: approvedColumns,
        data: approvedDataWithSNo,
        columnFilterDisplayMode: 'popover',
    });

    const declinedDataWithSNo = useMemo(() => {
        return declined.map((obj, index) => ({ ...obj, SNo: index + 1 }));
    }, [declined]);

    const declinedTable = useMaterialReactTable({
        columns: declinedColumns,
        data: declinedDataWithSNo,
        columnFilterDisplayMode: 'popover',
    });

    const handleFilter = (event) => {
        const selectedValue = event.target.value;
        setViewMode(selectedValue);
    };

    return (
        <div className="max">
            <div>
                <Box
                    sx={{
                        ml: 2,
                        [theme.breakpoints.down('md')]: {
                            mr: 2
                        }
                    }}
                >
                    <ButtonBase>
                        <div class="select-dropdown">
                            <select onChange={handleFilter}>
                                <option value="Approved Leaves">Approved Leaves</option>
                                <option value="Declined Leaves">Declined Leaves</option>
                            </select>
                        </div>
                    </ButtonBase>
                </Box>
            </div>
            {viewMode === 'Approved Leaves' && approved && approved.length > 0 && (
                <div>
                    <MaterialReactTable table={approvedTable} />
                </div>
            )}

            {viewMode === 'Declined Leaves' && (
                <div>
                    <MaterialReactTable table={declinedTable} />
                </div>
            )}
        </div>
    );
};

export default LeaveInfo;
