import React from 'react'
import denied from '../assets/images/denied.png'
const Denied = () => {
    return (
        <div className='d-flex justify-content-center'>
            <img src={denied} alt='' width={600} height={600} />
        </div>
    )
}

export default Denied