import React from 'react';
import { Button, Card, Grid, Typography } from '@mui/material';
import { BASE_URL } from 'api/apiEndPoint';
import pdf from '../assets/images/PDF.svg';
import Img from '../assets/images/Img.svg';
import doc from '../assets/images/Document.svg';
import { Divider } from 'rsuite';

const FileDownloader = ({ documentDetails }) => {
  const getIconForFileType = (mimetype) => {
    switch (mimetype) {
      case 'application/pdf':
        return <img src={pdf} alt="pdf" width={'80px'} />;
      case 'image/png':
      case 'image/jpeg':
        return <img src={Img} alt="pdf" width={'80px'} style={{ margin: '12px' }} />;
      default:
        return <img src={doc} alt="pdf" width={'80px'} style={{ margin: '12px' }} />;
    }
  };

  const handleDownload = (filePath, fileName) => {
    console.log('Attempting to download:', fileName);
    try {
      const anchor = document.createElement('a');
      anchor.href = `${BASE_URL}/${filePath}`;
      anchor.target = '_blank';
      anchor.download = fileName;

      // Use a synthetic click event to trigger the download
      const event = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      });
      anchor.dispatchEvent(event);
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('There was an error downloading the file. Please try again.');
    }
  };

  return (
    <Grid container className="">
      {Object.keys(documentDetails).map(
        (key) =>
          documentDetails[key].length > 0 && (
            <Grid xs={2} key={key} className="justify-content-center mt-1">
              <Card>
                <Typography variant="h5" gutterBottom className="text-muted text-center m-1">
                  {key.replace(/([A-Z])/g, ' $1').trim()}
                </Typography>
                <Divider />
                {documentDetails[key].map((file, index) => (
                  <div key={index}>
                    <Grid item className="d-flex justify-content-center">
                      {getIconForFileType(file.mimetype)}
                    </Grid>
                    <p className="text-muted text-center m-0 p-0">{file.originalname}</p>
                    <div className="d-flex justify-content-center m-1">
                      <Button variant="outlined" color="secondary" onClick={() => handleDownload(file.path, file.originalname)}>
                        Download
                      </Button>
                    </div>
                  </div>
                ))}
              </Card>
            </Grid>
          )
      )}
    </Grid>
  );
};

export default FileDownloader;
