/*eslint-disable */

import { CreateTwoTone, Delete, DeleteRounded, DeleteTwoTone, KeyboardBackspaceRounded, VisibilityTwoTone } from '@mui/icons-material';
import {
  Card,
  IconButton,
  Button,
  TextField,
  Modal,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Slide,
  ButtonBase,
  Avatar,
  Grid
} from '@mui/material';
import { useTheme } from '@mui/system';
import {
  CUSTOMER_GET,
  MASTERLIST_FINANCE,
  MASTERLIST_FINANCE_DELETE,
  MASTERLIST_FINANCE_GET,
  MASTERLIST_FINANCE_GTEBYID,
  MASTERLIST_FINANCE_PUT
} from 'api/apiEndPoint';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useState, forwardRef } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { deleteData, fetchData, postData, updateData } from 'utils/apiUtils';

const Transition = forwardRef(function transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MasterList = () => {
  const [formData, setFormData] = useState({ label: '', options: [] });
  const [options, setOptions] = useState([]);

  const resetForm = () => {
    setFormData({
      label: '',
      options: []
    });
    setOptions([]);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...formData.options];
    updatedOptions[index] = value;
    setFormData({ ...formData, options: updatedOptions });
  };

  const handleAddOption = () => {
    const updatedOptions = [...formData.options, ''];
    setFormData({ ...formData, options: updatedOptions });
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = formData.options.filter((_, i) => i !== index);
    setFormData({ ...formData, options: updatedOptions });
  };

  const handleClose = () => {
    setView({
      visible: true,
      mode: 'Initial'
    });
  };

  //POST METHOD

  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState();

  const handleEditClick = async (row) => {
    console.log(row?._id, 'row');

    setEditMode(row?._id);
    setFormData({
      label: row.label,
      options: row.options
    });
    setVisible(true);
  };

  const handlePost = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = editMode ? MASTERLIST_FINANCE_PUT(editMode) : MASTERLIST_FINANCE;
      console.log('API URL:', apiUrl);

      const localData = localStorage.getItem('userData');
      const parsedData = JSON.parse(localData);
      const token = parsedData?.accessToken;
      console.log(token, 'token');

      let data;
      if (editMode) {
        data = await updateData(apiUrl, formData, token);
      } else {
        data = await postData(apiUrl, formData, token);
      }
      console.log('Received Data:', data);
      resetForm();
      fetchMasterList();
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const handleLabelChange = (e) => {
    setFormData({ ...formData, label: e.target.value });
  };

  // GET Method
  const theme = useTheme();
  const [view, setView] = useState({
    visible: false,
    mode: 'Initial' // 'add', 'edit', 'view'
  });

  const [masterList, setMasterList] = useState([]);

  const [userDetailsVisible, setUserDetailsVisible] = useState(false);
  const handleView = (row) => {
    setView({ mode: 'View', data: row.original });
    console.log('here full data:', row.original);
    setViewData(row.original);
    setUserDetailsVisible(true);
    handleViewClick(row);
  };

  const columns = useMemo(() => [{ accessorKey: 'label', header: 'label' }], []);

  const dataForMasterList = useMemo(() => {
    return masterList?.map((obj, index) => ({ ...obj, SNo: index + 1 }));
  }, [masterList]);

  const table = useMaterialReactTable({
    columns,
    data: dataForMasterList,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderRowActions: ({ row }) => (
      <div style={{ display: 'flex' }}>
        <IconButton onClick={() => handleopenModel(row)}>
          <DeleteRounded style={{ color: '#2196f3' }} />
        </IconButton>
        <IconButton onClick={() => handleView(row)}>
          <VisibilityTwoTone style={{ color: '#2196f3' }} />
        </IconButton>
        <IconButton>
          <CreateTwoTone
            style={{ color: '#2196f3' }}
            onClick={() => {
              setVisible(true);
              setEditMode(row.original._id);
              handleEditClick(row.original);
              console.log('EditMode set to:', row.original?._id);
            }}
          />
        </IconButton>
      </div>
    )
  });

  const fetchMasterList = async () => {
    try {
      const apiUrl = MASTERLIST_FINANCE_GET;
      const localData = localStorage.getItem('userData');
      const parsedData = JSON.parse(localData);
      const token = parsedData?.accessToken;
      console.log(token, 'token');
      const data = await fetchData(apiUrl, token);
      console.log('data', data);
      setMasterList(data || []);
    } catch (err) {
      console.error('Error fetching master list data:', err);
    }
  };

  useEffect(() => {
    fetchMasterList();
  }, []);

  // DELETE method

  const [opeModel, setopeModel] = useState();
  const [open, setOpen] = useState(false);

  const handleopenModel = async (id) => {
    console.log(id, 'idddddddd');
    setOpen(!open);
    setopeModel(id);
  };

  const handleDelete = async (id) => {
    try {
      const apiUrl = MASTERLIST_FINANCE_DELETE(id?.original?._id);
      console.log(id?.original?._id, 'originalidaaa');
      const localData = localStorage.getItem('userData');
      const parsedData = JSON.parse(localData);
      const token = parsedData?.accessToken;
      const data = await deleteData(apiUrl, token);
      console.log('test data', data);
      fetchMasterList();
      setOpen(false);
    } catch (err) {
      console.error('Error deleting instrument:', err);
    }
  };

  // VIEW MODE
  const [viewData, setViewData] = useState([]);
  const handleViewClick = async (row) => {
    if (!row || !row.original || !row.original._id) {
      console.error('Invalid row data or missing ID');
      return;
    }

    console.log(row.original, 'row.original');
    console.log(row.original._id, 'row.original._id');

    const id = row.original._id;

    try {
      const apiUrl = MASTERLIST_FINANCE_GTEBYID(id);

      const localStore = localStorage.getItem('userData');
      if (!localStore) {
        throw new Error('User data not found in local storage');
      }

      const parsed = JSON.parse(localStore);

      const data = await fetchData(apiUrl, parsed.token);
      console.log('Fetched view data:', data);

      setViewData(data || {});
    } catch (err) {
      console.error('Error fetching view data:', err);
    }
  };

  console.log(viewData, 'dataview');

  useEffect(() => {
    handleViewClick();
  }, []);

  return (
    <div>
      {view.mode === 'Initial' && (view.data || true) && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <div style={{ flex: 1 }}>
            <MaterialReactTable table={table} />
          </div>
          <form style={{ width: '500px', padding: '20px', overflowY: 'auto' }} onSubmit={handlePost}>
            <TextField label="Form Label" value={formData.label} onChange={handleLabelChange} fullWidth style={{ marginBottom: '10px' }} />
            <Button variant="contained" onClick={handleAddOption}>
              Add Option
            </Button>
            <br />
            <br />
            {formData.options.map((option, index) => (
              <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={`Option ${index + 1}`}
                  value={option.name || option} // Handle option.name if option is an object, or just the string
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  fullWidth
                />
                <Button
                  variant="contained"
                  onClick={() => handleRemoveOption(index)}
                  style={{
                    fontSize: '12px',
                    padding: '2px 7px',
                    height: '35px',
                    lineHeight: '1',
                    marginLeft: '10px'
                  }}
                >
                  -
                </Button>
                <Button
                  variant="contained"
                  onClick={handleAddOption}
                  style={{
                    fontSize: '12px',
                    padding: '2px 7px',
                    height: '35px',
                    lineHeight: '1',
                    marginLeft: '10px'
                  }}
                >
                  +
                </Button>
              </div>
            ))}

            <Button variant="contained" type="submit">
              Save
            </Button>
          </form>
        </div>
      )}
      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography variant="h3">Delete Lead</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className="d-flex justify-content-center align-item-center">
            <div className="bg-light rounded ">
              <Delete style={{ fontSize: '32' }} />
            </div>
          </DialogContentText>
          <Typography variant="h4" className="muted" display="block" gutterBottom style={{ textAlign: 'center' }} mt={2}>
            Are you want to Delete ?
          </Typography>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-1">
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleDelete(opeModel);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {view.mode === 'View' && view.data && (
        <>
          <MainCard
            title="Master List"
            secondary={
              <Box
                sx={{
                  ml: 2,
                  [theme.breakpoints.down('md')]: {
                    mr: 2
                  }
                }}
              >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.mediumAvatar,
                      transition: 'all .2s ease-in-out',
                      background: theme.palette.secondary.light,
                      color: theme.palette.secondary.dark,
                      '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light
                      }
                    }}
                    aria-haspopup="true"
                    onClick={handleClose}
                    color="inherit"
                  >
                    <KeyboardBackspaceRounded stroke={2} size="1.3rem" />
                  </Avatar>
                </ButtonBase>
              </Box>
            }
          >
            <Grid container m={3}>
              <Grid xs={3} p={2}>
                <label className="text-muted">Label</label>
                <p>{viewData.label || 'N/A'}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Options</label>
                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    {viewData.options ? (
                      Array.isArray(viewData.options) && viewData.options.length > 0 ? (
                        viewData.options.map((option, index) => (
                          <li key={index}>{typeof option === 'object' && option.name ? option.name : option}</li>
                        ))
                      ) : (
                        <li>No options available</li>
                      )
                    ) : (
                      <li>No options available</li>
                    )}
                  </ul>
                </div>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Created At</label>
                <p>{viewData.createdAt ? new Date(viewData.createdAt).toLocaleString() : 'N/A'}</p>
              </Grid>
              <Grid xs={3} p={2}>
                <label className="text-muted">Updated At</label>
                <p>{viewData.updatedAt ? new Date(viewData.updatedAt).toLocaleString() : 'N/A'}</p>
              </Grid>
            </Grid>
          </MainCard>
        </>
      )}
    </div>
  );
};

export default MasterList;
