/*eslint-disable */

import { Avatar, Button, ButtonBase, Card, Divider, Grid } from '@mui/material';
import React, { useState, useRef } from 'react';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import PhonelinkRingTwoToneIcon from '@mui/icons-material/PhonelinkRingTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import KeyboardBackspaceRounded from '@mui/icons-material/KeyboardBackspaceRounded';
import { Box, useTheme } from '@mui/system';
import FileList from '../../utils/fileDownloader';
import banner from '../../assets/images/employee banner.png';
import avatar from '../../assets/images/avatar.png';
import { BASE_URL, PERSONAL_DETAILS, PERSONAL_DETAILS_UPDATE } from 'api/apiEndPoint';
import { updateData } from 'utils/apiUtils';

const UserDetailsforHR = ({ handleBack, data }) => {
  console.log(data, 'data');
  const theme = useTheme();

  const handleVerify = (e) => {
    const localStore = localStorage.getItem('userData');
    const parsed = JSON.parse(localStore);
    console.log(parsed, 'parsed');
    if (parsed) {
      const endpoint = PERSONAL_DETAILS_UPDATE(data?._id);
      console.log('endpoint---->', endpoint);
      const updateStatus = updateData(endpoint, { status: e });
      handleBack();
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-between m-1 ">
        <p
          className={
            (data?.verifiedProfile === 'verified' && 'banner-blue') ||
            (data?.verifiedProfile === 'pending' && 'banner-yellow') ||
            (data?.verifiedProfile === 'rejected' && 'banner-red')
          }
          style={{ padding: '7px 12px' }}
        >
          Verification Status : {data?.verifiedProfile}
        </p>
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            aria-haspopup="true"
            onClick={handleBack}
            color="inherit"
          >
            <KeyboardBackspaceRounded stroke={2} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </div>
      <Card style={{ height: '', padding: '20px' }}>
        <div style={{ position: 'relative' }}>
          {' '}
          <Box
            component="img"
            sx={{
              width: '100%',
              height: '300px',
              borderRadius: '10px'
            }}
            alt="avatar"
            src={banner}
            className=""
          />
          <Card className="p-1" style={{ width: '18%', position: 'absolute', top: '3rem', right: '1.7rem' }}>
            <div className="d-flex justify-content-center">
              {data?.documentDetails?.profilePicture?.length === 0 ? (
                <Box
                  component="img"
                  sx={{
                    height: 233,
                    width: 233,
                    borderRadius: '10px'
                  }}
                  alt="avatar"
                  src={avatar}
                  className=""
                />
              ) : (
                <Box
                  component="img"
                  sx={{
                    height: 233,
                    width: 233,
                    borderRadius: '10px'
                  }}
                  alt="avatar"
                  src={`${BASE_URL}/${data?.documentDetails?.profilePicture[0]?.path}`}
                  className=""
                />
              )}
            </div>

            <div className="text-center mt-1 mb-1">
              <h3>
                {data?.employeeId?.NameOfCandidate}&nbsp;{data?.familyDetails?.fatherName}
              </h3>
              <span>{data?.employeeId?.Designation}</span>
            </div>
            <div className="">
              <Grid container>
                <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted text-center mt-1">
                  Email
                </Grid>
                <Grid xs={8} className="text-muted text-center mt-1">
                  {data?.personalMail}
                </Grid>
                <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted text-center mt-1">
                  Mobile
                </Grid>
                <Grid xs={8} className="text-muted text-center mt-1">
                  (+91) {data?.employeeId?.ContactNumber}
                </Grid>
                <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted text-center mt-1">
                  Location
                </Grid>
                <Grid xs={8} className="text-muted text-center mt-1">
                  {data?.employeeId?.Location}
                </Grid>
              </Grid>
            </div>
          </Card>
          <Card className="p-1" style={{ width: '18%', position: 'absolute', top: '34rem', right: '1.7rem', marginBottom: '1rem' }}>
            <div className="text-center mt-1 mb-1">
              <h4>Skills & Experience</h4>
            </div>
            <Divider />
            <div className="">
              <Grid container>
                <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted mt-1">
                  Company
                </Grid>
                <Grid xs={8} className="text-muted text-center mt-1">
                  {data?.skillAndExperience?.companyName}
                </Grid>
                <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted  mt-1">
                  Designation
                </Grid>
                <Grid xs={8} className="text-muted text-center mt-1">
                  {data?.skillAndExperience?.designation}
                </Grid>
                <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted mt-1">
                  From Date
                </Grid>
                <Grid xs={8} className="text-muted text-center mt-1">
                  {data?.skillAndExperience?.fromDate?.slice(0, 10)}
                </Grid>
                <Grid xs={4} sx={{ fontWeight: 'bold' }} className="text-muted  mt-1">
                  To Date
                </Grid>
                <hr />
                <Grid xs={8} className="text-muted text-center mt-1">
                  {data?.skillAndExperience?.toDate?.slice(0, 10)}
                </Grid>
                <Grid xs={6} sx={{ fontWeight: 'bold' }} className="text-muted mt-1">
                  Languages
                  <div>
                    {data?.skillAndExperience?.languages &&
                      data?.skillAndExperience?.languages[0].split(',').map((language, idx) => <li> {language}</li>)}
                  </div>
                </Grid>
                <Grid xs={6} sx={{ fontWeight: 'bold' }} className="text-muted mt-1">
                  Software Exposure
                  {data?.skillAndExperience?.softwareExposure &&
                    data?.skillAndExperience?.softwareExposure[0].split(',').map((language, idx) => <li> {language}</li>)}
                </Grid>

                <Grid xs={8} className="text-muted text-center mt-1">
                  {data?.skillAndExperience?.roleDescription}
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
        <Grid container m={3} xs={10}>
          <Grid container m={3} xs={9}>
            <p className="text-muted">{data?.description}</p>
          </Grid>
          <Divider />
          <Grid container m={3} xs={10}>
            <Grid xs={3} p={2}>
              <label className="text-muted">Employee Code</label>
              <p>{data?.employeeId?.EmployeeCode}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Name Of Candidate</label>
              <p>{data?.employeeId?.NameOfCandidate}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Gender</label>
              <p>{data?.gender}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Emergency Contact</label>
              <p>{data?.emergencyContact}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Date Of Birth</label>
              <p>{data?.employeeId?.DateOfBirth}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Blood Group</label>
              <p>{data?.bloodGroup}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Qualification</label>
              <p>{data?.educationQualification[0]}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Year of Graducation</label>
              <p>{data?.yearOfGraduation}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Current Address</label>
              <p>{data?.currentAddress}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Permanent Address</label>
              <p>{data?.permanentAddress}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Aadhar Number</label>
              <p>{data?.aadharNumber}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">PAN Number</label>
              <p>{data?.panNumber}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Designation</label>
              <p>{data?.employeeId?.Designation}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Team</label>
              <p>{data?.employeeId?.Team}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Role</label>
              <p>{data?.employeeId?.Role}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Contact Number</label>
              <p>{data?.employeeId?.ContactNumber}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Category</label>
              <p>{data?.employeeId?.Category}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Status</label>
              <p>{data?.employeeId?.status === true ? 'Current Employeee' : 'Resigned Employee'}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Father Name</label>
              <p>{data?.familyDetails?.fatherName}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Mother Name</label>
              <p>{data?.familyDetails?.motherName}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Has Siblings</label>
              <p>{data?.familyDetails?.siblings?.hasSiblings === true ? 'Yes' : 'No'}</p>
            </Grid>
            {data?.familyDetails?.siblings?.hasSiblings &&
              data?.familyDetails?.siblings?.siblingType[0].split(',').map((siblingType, idx) => (
                <Grid item xs={3} p={2}>
                  <label className="text-muted">
                    {siblingType} {idx + 1}
                  </label>
                  <p>{data?.familyDetails?.siblings?.siblingNames[idx]}</p>
                </Grid>
              ))}
            <Grid xs={3} p={2}>
              <label className="text-muted">Marital Status</label>
              <p>{data?.familyDetails?.maritalStatus === 'true' ? 'Yes' : 'No'}</p>
            </Grid>
            {data?.familyDetails?.maritalStatus && (
              <>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Spouse Name</label>
                  <p>{data?.familyDetails?.spouseName}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Spouse Mobile Number</label>
                  <p>{data?.familyDetails?.spouseMobileNumber}</p>
                </Grid>
              </>
            )}
            <Grid xs={3} p={2}>
              <label className="text-muted">Has Passport</label>
              <p>{data?.passport?.hasPassport === true ? 'Yes' : 'No'}</p>
            </Grid>
            {data?.passport?.hasPassport && (
              <>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Passport Number</label>
                  <p>{data?.passport?.passportNumber}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">Passport Expiry Date</label>
                  <p>{data?.passport?.expiryDate?.slice(0, 10)}</p>
                </Grid>
              </>
            )}
            <Grid xs={3} p={2}>
              <label className="text-muted">Has Driving License</label>
              <p>{data?.drivingLicense?.hasLicense === true ? 'Yes' : 'No'}</p>
            </Grid>
            {data?.drivingLicense?.hasLicense && (
              <>
                <Grid xs={3} p={2}>
                  <label className="text-muted">License Number</label>
                  <p>{data?.drivingLicense?.licenseNumber}</p>
                </Grid>
                <Grid xs={3} p={2}>
                  <label className="text-muted">License Expiry Date</label>
                  <p>{data?.drivingLicense?.expireDate?.slice(0, 10)}</p>
                </Grid>
              </>
            )}
            <Grid xs={3} p={2}>
              <label className="text-muted">Bank Name</label>
              <p>{data?.bankDetails?.bankName}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Branch Name</label>
              <p>{data?.bankDetails?.branchName}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">Account Number</label>
              <p>{data?.bankDetails?.accountNumber}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">IFSC Code</label>
              <p>{data?.bankDetails?.ifscCode}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">EPFO UAN Number</label>
              <p>{data?.bankDetails?.epfoUanNumber}</p>
            </Grid>
            <Grid xs={3} p={2}>
              <label className="text-muted">ESIC Number</label>
              <p>{data?.bankDetails?.esicNumber}</p>
            </Grid>
          </Grid>
          <Divider />
        </Grid>
        {/* Removed FileList component */}
        <FileList documentDetails={data?.documentDetails} />
        {data?.verifiedProfile !== 'verified' ? (
          <div className="d-flex justify-content-end">
            <button className="banner-red me-1 p-1" onClick={() => handleVerify('rejected')}>
              Reject
            </button>
            <button className="banner-blue" onClick={() => handleVerify('verified')}>
              Verify
            </button>
          </div>
        ) : null}
      </Card>
    </div>
  );
};

export default UserDetailsforHR;
