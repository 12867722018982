import { LockOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, ButtonBase, Grid, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { CHANGE_PASSWORD } from 'api/apiEndPoint';
import { postData } from 'utils/apiUtils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const Credential = () => {
  const [localStore, setlocalStore] = useState();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const theme = useTheme();
  const [formData, setFormData] = useState({
    employeeId: localStorage?.employeeId,
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Clear validation error when user starts typing
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.oldPassword.trim()) {
      errors.oldPassword = 'Current Password is required';
    }

    if (!formData.newPassword.trim()) {
      errors.newPassword = 'New Password is required';
    }

    if (!formData.confirmPassword.trim()) {
      errors.confirmPassword = 'Confirm Password is required';
    } else if (formData.newPassword !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const apiEndPoint = CHANGE_PASSWORD;
        const postedData = await postData(apiEndPoint, formData);
        console.log('Form submitted:', formData, postedData);
        // Assuming postData returns the response from the API
        // Show success message if the API call was successful
        toast.success('Password changed successfully');
        localStorage.removeItem('userData');
        localStorage.removeItem('Skipped');
        // Navigate to the desired route
        navigate('/', { replace: true });
        window.location.reload();
        // Reset the form
        setFormData({
          employeeId: '',
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
      } catch (error) {
        // Handle API errors here
        console.error('Error submitting form:', error);
        // Display error message using toast or any other UI component
        // toast.error('Failed to change password. Please try again.');
      }
    }
  };

  useEffect(() => {
    const localData = localStorage.getItem('userData');
    const parsed = JSON.parse(localData);
    setlocalStore(parsed);
    setFormData((prevState) => ({
      ...prevState,
      employeeId: parsed?.employeeId || ''
    }));
  }, []);

  return (
    <MainCard
      title={
        <div className="d-flex align-item-center">
          <p>Credential Update</p>
        </div>
      }
      secondary={
        <Box sx={{ ml: 2 }} className="d-flex align-item-center">
          <ButtonBase sx={{ borderRadius: '12px' }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              aria-haspopup="true"
              // onClick={handleBoard}
              color="inherit"
            >
              <LockOutlined />
            </Avatar>
          </ButtonBase>
        </Box>
      }
    >
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ justifyContent:"space-evenly", alignItems: 'center' }}>
          <Grid container spacing={2} xs={6} p={4}>
            <Grid item xs={12}>
              <TextField
                label="Current Password"
                variant="outlined"
                name="oldPassword"
                fullWidth
                onChange={handleChange}
                error={!!errors.oldPassword}
                helperText={errors.oldPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="New Password"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                name="newPassword"
                error={!!errors.newPassword}
                helperText={errors.newPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                name="confirmPassword"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button variant="contained" type="submit" disableElevation>
                Change
              </Button>
            </Grid>
          </Grid>
          {!localStore?.isReseted && (
            <Grid container spacing={2} xs={5} p={4} className="warning-info m-1">
              Dear &nbsp;<span className="strong">{localStore?.name},</span>
              <br />
              <br />
              Your password is currently set to your Date of Birth (DOB). While convenient, this presents a significant security risk. If
              someone gains access to your DOB, they can potentially compromise your account. To enhance the security of your account and
              protect your confidential information, we strongly recommend changing your password immediately. <br />
              <br /> Thank you for your attention to this matter.
              <br />
              <br />
              Best regards,
              <br />
              CAP-CP Org.
            </Grid>
          )}
        </Grid>
      </form>
    </MainCard>
  );
};

export default Credential;
