const { fetchData } = require("./apiUtils");

/**
 * Fetches a key value by ID from a specified API.
 * 
 * @param {number} id - The ID to fetch.
 * @param {string} api - The API endpoint to fetch data from.
 * @param {string} key - The key to extract from the fetched data.
 * @returns {Promise<string>} - The value of the specified key.
 */
const fetchKeyValueById = async (id, api, key) => {
  console.log(id, api, key, "dataassssss");

  try {
    const apiValue = api(id);
    const localStore = localStorage.getItem('userData');
    const parsedData = JSON.parse(localStore);

    // const parsedData = JSON.parse(localDatas);

    const response = await fetchData(apiValue, parsedData?.accessToken);
    return response[key];
  } catch (error) {
    console.error(`Error fetching ${key} for ID ${id}:`, error);
    return null;
  }
};

module.exports = { fetchKeyValueById };
