/*eslint-disable */

import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './Calender.css';
import ControlPointSharpIcon from '@mui/icons-material/ControlPointSharp';
import FlutterDashRoundedIcon from '@mui/icons-material/FlutterDashRounded';
import CommitIcon from '@mui/icons-material/Commit';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Delete } from '@mui/icons-material';
import { Stack } from 'immutable';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import nodata from '../../assets/images/NoEvent.png';
import EventIcon from '@mui/icons-material/Event';
const Calendar = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handledeleteOpen = () => setOpenDelete(true);
  const handledeleteClose = () => setOpenDelete(false);

  useEffect(() => {
    const today = new Date();
    setCurrentMonth(today.getMonth());
    setCurrentYear(today.getFullYear());

    const savedEvents = JSON.parse(localStorage.getItem('events')) || {};
    setEvents(savedEvents);
  }, []);

  // if (Object.keys(updatedEvents).length > 0) {
  //     localStorage.setItem('events', JSON.stringify(updatedEvents));
  // }

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const startingDay = new Date(currentYear, currentMonth, 1).getDay();

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const moveToPreviousMonth = () => {
    setCurrentMonth((prevMonth) => {
      if (prevMonth === 0) {
        setCurrentYear((year) => year - 1);
        return 11;
      } else {
        return prevMonth - 1;
      }
    });
    setSelectedDate(undefined); // Add this line to reset selectedDate
  };

  const moveToNextMonth = () => {
    setCurrentMonth((prevMonth) => {
      if (prevMonth === 11) {
        setCurrentYear((year) => year + 1);
        return 0;
      } else {
        return prevMonth + 1;
      }
    });
    setSelectedDate(undefined); // Add this line to reset selectedDate
  };

  const numRows = Math.ceil((daysInMonth + startingDay) / 7);

  const cellSize = 60;

  const [monthYear, setMonthYear] = useState('');

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (/^\d{0,2}\/?\d{0,4}$/.test(value)) {
      setMonthYear(value);
      const [inputMonth, inputYear] = value.split('/');
      console.log('Input Month:', inputMonth);
      console.log('Input Year:', inputYear);
      const parsedMonth = parseInt(inputMonth, 10);
      const parsedYear = parseInt(inputYear, 10);
      console.log('Parsed Month:', parsedMonth);
      console.log('Parsed Year:', parsedYear);
      if (!isNaN(parsedMonth) && !isNaN(parsedYear) && parsedMonth >= 1 && parsedMonth <= 12 && parsedYear >= 1000 && parsedYear <= 9999) {
        setCurrentMonth(parsedMonth - 1);
        setCurrentYear(parsedYear);
      }
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Backspace' && monthYear.endsWith('/')) {
      setMonthYear(monthYear.slice(0, -1));
    }
  };

  const goToToday = () => {
    const today = new Date();
    setCurrentMonth(today.getMonth());
    setCurrentYear(today.getFullYear());
  };

  const [selectedDate, setSelectedDate] = useState();
  const [events, setEvents] = useState({});

  const handleDateClick = (day) => {
    setSelectedDate(day);
    const dateKey = `${currentYear}-${currentMonth + 1}-${day}`;
    const eventData = events[dateKey];
    if (eventData) {
      setEventName(eventData.eventName);
      setEventTimeFrom(eventData.eventTimeFrom);
      setEventTimeTo(eventData.eventTimeTo);
      setDescription(eventData.description);
      setStatus(eventData.status);
    } else {
      // If no event is associated with the selected date, reset the event details
      setEventName('');
      setEventTimeFrom('');
      setEventTimeTo('');
      setDescription('');
      setStatus('');
    }
  };

  const CalendarSideView = ({ selectedDate, selectedMonth }) => {
    const currentDate = new Date().getDate();
    return (
      <div className="calendersideview">
        {selectedDate ? (
          <p>
            {selectedDate} {selectedMonth}
          </p>
        ) : (
          <p>
            {' '}
            {currentDate} {selectedMonth}
          </p>
        )}
      </div>
    );
  };

  const [eventName, setEventName] = useState('');
  const [eventTimeFrom, setEventTimeFrom] = useState('');
  const [eventTimeTo, setEventTimeTo] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');

  const [updatedEvents, setUpdatedEvents] = useState();

  const handleAddEvent = () => {
    handleClose();
    const dateKey = `${currentYear}-${currentMonth + 1}-${selectedDate}`;
    const updatedEvents = { ...events, [dateKey]: { eventName, eventTimeFrom, eventTimeTo, description, status } };
    setEvents(updatedEvents);
    localStorage.setItem('events', JSON.stringify(updatedEvents));
  };

  const handleDelete = () => {
    const updatedEvents = { ...events };
    const dateKey = `${currentYear}-${currentMonth + 1}-${selectedDate}`;
    delete updatedEvents[dateKey];
    setEvents(updatedEvents);
    localStorage.setItem('events', JSON.stringify(updatedEvents));
    handledeleteClose();
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  return (
    <div>
      <MainCard sx={{ backgroundColor: '#e1f0fc' }}>
        <div className="d-flex">
          <div>
            <Card
              sx={{ width: 800, height: 690, marginTop: '20px', marginLeft: '30px', marginBottom: '20px', backgroundColor: '#05044b ' }}
            >
              <Card sx={{ width: 780, height: 690, backgroundColor: '#07b5e8' }}>
                <Card sx={{ width: 760, height: 690 }}>
                  <div className="d-flex justify-content-between mt-1 align-items-center " style={{ marginLeft: '30px' }}>
                    <KeyboardArrowLeftIcon onClick={moveToPreviousMonth} />
                    <Typography sx={{ fontSize: '1.4rem', fontFamily: 'Georgia, serif' }}>
                      {months[currentMonth]} &nbsp; {currentYear}
                    </Typography>
                    <KeyboardArrowRightIcon onClick={moveToNextMonth} />
                  </div>
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                    {daysOfWeek.map((day, index) => (
                      <Typography key={index} variant="subtitle2" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
                        {day}
                      </Typography>
                    ))}
                  </div>
                  <br />
                  {Array.from({ length: numRows }, (_, rowIndex) => (
                    <div key={rowIndex} className="d-flex flex-wrap justify-content-start">
                      {Array.from({ length: 7 }, (_, dayIndex) => {
                        const dayNumber = rowIndex * 7 + dayIndex + 1 - startingDay;
                        const isSelectedDate = dayNumber === selectedDate;
                        const isEventAssigned = events && events[dayNumber];

                        return (
                          <Typography
                            key={dayIndex}
                            className={
                              dayNumber === new Date().getDate() &&
                              currentMonth === new Date().getMonth() &&
                              currentYear === new Date().getFullYear()
                                ? 'bgcolorforcurrentdate'
                                : ''
                            }
                            onClick={() => handleDateClick(dayNumber)}
                            variant="body1"
                            sx={{
                              fontFamily: 'Arial, sans-serif',
                              textAlign: 'center',
                              width: `${cellSize}px`,
                              height: `${cellSize}px`,
                              border: '1px solid #ccc',
                              padding: '5px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            {dayNumber > 0 && dayNumber <= daysInMonth ? dayNumber : ''}
                            {events && events[`${currentYear}-${currentMonth + 1}-${dayNumber}`] && (
                              <CommitIcon style={{ marginTop: '35px', marginLeft: '-15px', color: '#8c65eb' }} />
                            )}{' '}
                          </Typography>
                        );
                      })}
                    </div>
                  ))}

                  <div className="d-flex justify-content-between align-items-center">
                    <Paper
                      component="form"
                      sx={{
                        p: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        width: 400,
                        marginLeft: '10px',
                        marginTop: '30px',
                        border: '1px solid',
                        borderColor: '#ced4da',
                        boxShadow: 'none'
                      }}
                    >
                      <InputBase
                        value={monthYear}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyDown}
                        placeholder="MM / YYYY"
                        inputProps={{ 'aria-label': 'MM / YYYY' }}
                        sx={{ ml: 1, flex: 1 }}
                      />
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <IconButton id="nextInput" style={{ color: '#8c65eb' }}>
                        GO
                      </IconButton>
                    </Paper>
                    <Button
                      variant="outlined"
                      style={{ marginBottom: '-25px', borderColor: '#9d89db', color: '#8c65eb' }}
                      onClick={goToToday}
                    >
                      Today
                    </Button>
                  </div>
                </Card>
              </Card>
            </Card>
          </div>
          <div className="calendersideview">
            <CalendarSideView selectedDate={selectedDate} selectedMonth={`${months[currentMonth]} ${currentYear}`} />

            <ControlPointSharpIcon className="claendericonplus" onClick={handleOpen} />
            <div>
              <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    marginLeft: '10rem',
                    borderRadius: '15px'
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ fontFamily: 'cursive', color: '#8c65eb' }}>
                      Add Event
                    </Typography>
                    <EventIcon sx={{ width: '40px', height: '20px', color: '#9570ec' }} onClick={handleClose} />
                  </div>
                  <hr style={{ width: '24.5rem', marginLeft: '-26px' }} />
                  <TextField
                    fullWidth
                    label="Event Name"
                    variant="standard"
                    style={{ marginBottom: '20px' }}
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    label="Event Time From"
                    variant="standard"
                    style={{ marginBottom: '20px' }}
                    value={eventTimeFrom}
                    onChange={(e) => setEventTimeFrom(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    label="Event Time To"
                    variant="standard"
                    style={{ marginBottom: '20px' }}
                    value={eventTimeTo}
                    onChange={(e) => setEventTimeTo(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    label="Description"
                    variant="standard"
                    style={{ marginBottom: '20px' }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <TextField
                    select
                    fullWidth
                    label="Status"
                    variant="standard"
                    style={{ marginBottom: '20px' }}
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <MenuItem value="Upcoming">Upcoming</MenuItem>
                    <MenuItem value="Finished">Finished</MenuItem>
                    <MenuItem value="Rescheduled">Rescheduled</MenuItem>
                    {/* Add more options as needed */}
                  </TextField>
                  <br />
                  <br />
                  <div className="d-flex justify-content-between  m-1">
                    <Button variant="outlined" onClick={handleClose} style={{ borderColor: '#8c65eb', color: '#8c65eb' }}>
                      Cancel
                    </Button>
                    <Button variant="outlined" style={{ borderColor: '#8c65eb', color: '#8c65eb' }} onClick={handleAddEvent}>
                      Add Event
                    </Button>
                  </div>
                </Box>
              </Modal>

              {selectedDate !== 0 && (
                <Grid style={{ marginTop: '-30rem', marginRight: '50px' }}>
                  {events && events[`${currentYear}-${currentMonth + 1}-${selectedDate}`] ? (
                    <div>
                      <Grid container className="d-flex justify-content-between align-items-center">
                        <Grid item xs={6} md={4}>
                          <strong style={{ fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            Event Name
                          </strong>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                        >
                          :
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          md={3}
                          style={{
                            fontSize: '0.9rem',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginLeft: '202px',
                            marginTop: '-22px'
                          }}
                        >
                          {eventName}
                        </Grid>
                      </Grid>
                      <Grid container className="d-flex justify-content-between align-items-center">
                        <Grid item xs={4} md={5}>
                          <strong style={{ fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            Event Time From
                          </strong>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                        >
                          :
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          md={7}
                          style={{
                            fontSize: '0.9rem',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginLeft: '202px',
                            marginTop: '-22px'
                          }}
                        >
                          {eventTimeFrom}
                        </Grid>
                      </Grid>
                      <Grid container className="d-flex justify-content-between align-items-center">
                        <Grid item xs={4} md={4}>
                          <strong style={{ fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            Event Time To
                          </strong>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                        >
                          :
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          md={7}
                          style={{
                            fontSize: '0.9rem',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginLeft: '202px',
                            marginTop: '-22px'
                          }}
                        >
                          {eventTimeTo}
                        </Grid>
                      </Grid>
                      <Grid container className="d-flex justify-content-between align-items-center">
                        <Grid item xs={4} md={4}>
                          <strong style={{ fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            Description
                          </strong>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                        >
                          :
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          md={7}
                          style={{
                            fontSize: '0.9rem',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginLeft: '202px',
                            marginTop: '-22px'
                          }}
                        >
                          {description}
                        </Grid>
                      </Grid>
                      <Grid container className="d-flex justify-content-between align-items-center">
                        <Grid item xs={4} md={4}>
                          <strong style={{ fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            Status
                          </strong>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                        >
                          :
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          md={7}
                          style={{
                            fontSize: '0.9rem',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginLeft: '202px',
                            marginTop: '-22px'
                          }}
                        >
                          {status}
                        </Grid>
                      </Grid>
                      <div>
                        <Button
                          variant="outlined"
                          style={{ marginBottom: '-25px', borderColor: '#9d89db', color: '#8c65eb', marginLeft: '15rem' }}
                          onClick={handledeleteOpen}
                        >
                          Delete
                        </Button>
                        <Modal open={openDelete} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: 400,
                              bgcolor: 'background.paper',
                              boxShadow: 24,
                              p: 4,
                              marginLeft: '10rem'
                            }}
                          >
                            <div style={{ fontWeight: 'bolder' }}>
                              <Typography id="modal-modal-title" variant="h3" component="h2">
                                Delete The Event
                              </Typography>
                            </div>
                            <hr style={{ width: '24.5rem', marginLeft: '-26px' }} />
                            <div>
                              <Avatar
                                style={{
                                  backgroundColor: '#e7e0f4',
                                  color: '#7e58c2',
                                  marginLeft: '8rem',
                                  width: '60px',
                                  height: '60px',
                                  marginRight: '5rem'
                                }}
                              >
                                <DeleteRoundedIcon />
                              </Avatar>
                              <br />
                              <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: 'center' }}>
                                Are You Want To Delete?
                              </Typography>
                            </div>
                            <div className="d-flex justify-content-center m-1">
                              <Button variant="outlined" onClick={handledeleteClose} style={{ borderColor: '#8c65eb', color: '#8c65eb' }}>
                                Cancel
                              </Button>
                              <Button
                                variant="outlined"
                                onClick={handleDelete}
                                style={{ borderColor: '#8c65eb', color: '#8c65eb', marginLeft: '10px' }}
                              >
                                Delete
                              </Button>
                            </div>
                          </Box>
                        </Modal>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={nodata}
                      alt="No event assigned for this date"
                      style={{ marginLeft: '-5rem', marginTop: '1rem', width: '350px', height: '270px' }}
                    />
                  )}
                </Grid>
              )}
            </div>
          </div>
        </div>
      </MainCard>
    </div>
  );
};

export default Calendar;
