//base api
export const BASE_URL = 'https://org.capcp.in/orgapi/capcp';
export const PRODUCTION_URL = 'https://org.capcp.in/orgapiatt/capcp';
// https://org.capcp.in/orgapi/capcp
// http://localhost:5000/capcp
// https://org.capcp.in/orgapiatt/capcp
//FOR LOGIN API HERE
export const LOGIN = `${BASE_URL}/login`;

export const MANAGERBASED_ATTENDANCE = (id) => `${PRODUCTION_URL}/attendance/dailyattendancemanager?managerid=${id}`;

export const COUNTRYWITH_FLAG = `https://restcountries.com/v3.1/all`;
export const IFSC_CODE = (code) => `https://ifsc.razorpay.com/${code}`;

//Customer DataBase
export const CUSTOMER_POST = `${BASE_URL}/customers`;
// Petycash and reimbursement
export const REQUEST_TO_CASH = `${BASE_URL}/pettyCashPayment`;
export const REQUEST_TO_CASH_POST = `${BASE_URL}/pettyCashPayment`;

// Finance Supplier
export const SUPPLIER_POST = `${BASE_URL}/supplier`;
export const SUPPLIER_GET = `${BASE_URL}/supplier`;
export const SUPPLIER_GETBYID = (id) => `${BASE_URL}/supplier/${id}`;
export const SUPPLIER_DELETE = (id) => `${BASE_URL}/supplier/${id}`;
export const SUPPLIER_PUT = (id) => `${BASE_URL}/supplier/${id}`;

//Finance
export const MASTERLIST_FINANCE = `${BASE_URL}/masterlist`;
export const MASTERLIST_FINANCE_GET = `${BASE_URL}/masterlist`;
export const MASTERLIST_FINANCE_DELETE = (id) => `${BASE_URL}/masterlist/${id}`;
export const MASTERLIST_FINANCE_PUT = (id) => `${BASE_URL}/masterlist/${id}`;
export const MASTERLIST_FINANCE_GTEBYID = (id) => `${BASE_URL}/masterlist/${id}`;

//FOR LEADS API HERE
export const LEAD_CREATION = `${BASE_URL}/lead/create`;
export const LEAD_GET = `${BASE_URL}/lead/get`;
export const LEAD_GET_BY_CODE = (id) => `${BASE_URL}/lead/getbyserial/${id}`;
export const LEAD_GET_BY_HOLD = `${BASE_URL}/lead/getbyhold`;
export const LEAD_GET_BY_REJECT = `${BASE_URL}/lead/getbyreject`;
export const LEAD_GET_BY_PENDINGS = `${BASE_URL}/lead/getbypendings`;
export const LEAD_GET_ID = (id) => `${BASE_URL}/lead/getbyid/${id}`;
export const LEAD_UPDATE = (id) => `${BASE_URL}/lead/update/${id}`;
export const LEAD_DELETE = (id) => `${BASE_URL}/lead/delete/${id}`;

//FOR ATTENDANCE
export const INDIVIDUAL_ATTENDANCE = (id) => `${PRODUCTION_URL}/attendance/last10attendanceemp?empid=${id}`;
export const LEAVES_CHECK = (id) => `${PRODUCTION_URL}/attendance/leavebalance?employeeid=${id}`;
export const COMP_OFF = (id) => `${PRODUCTION_URL}/attendance/compoffs?employeeid=${id}`;
export const DAILY_ATTENDANCE = (date) => `${PRODUCTION_URL}/attendance/dailyattendanceall?attdate=${date}`;
export const DAILY_FINALATTENDANCE = (date) => `${PRODUCTION_URL}/attendance/dailyfinalattendanceall?attdate=${date}`;
export const LEAVE_LOGS = `${PRODUCTION_URL}/attendance/leaverequest`;
export const LEAVES_LOGSGET = `${PRODUCTION_URL}/attendance/leavelogs`;
export const LEAVE_OB = `${PRODUCTION_URL}/attendance/leaveob`;
export const LEAVE_BALANCE_ALL = `${PRODUCTION_URL}/attendance/leavebalanceall`;
export const ATTENDANCE_POSTMONTHLY = `${PRODUCTION_URL}/attendance/finalholpost`;
export const UNLOGGED_USER = (date, id) => `${PRODUCTION_URL}/attendance/dailynoentry?attdate=${date}&managerid=${id}`;

export const MONTHLY_LEAVEBAL = (month, year) => `${PRODUCTION_URL}/attendance/monthlyleavebalance?month=${month}&year=${year}`;

export const MONTHLY_POST = `${PRODUCTION_URL}/attendance/finalmonthpost`;
export const DAILYATTENDANCE_POST = `${PRODUCTION_URL}/attendance/finalattendance`;
export const LEAVE_APPROVAL = `${PRODUCTION_URL}/attendance/leaveapproval`;
export const LEAVE_APPROVED = `${PRODUCTION_URL}/attendance/approvedleavelogs`;
export const LEAVE_DECLINED = `${PRODUCTION_URL}/attendance/rejectedleavelogs`;
export const MONTHLY_ATTENDANCE = (month, year) => `${PRODUCTION_URL}/attendance/monthlyattendancefinalall?month=${month}&year=${year}`;
export const ATTENDANCE_BY_TEAM = (date, team) => `${PRODUCTION_URL}/attendance/dailyteamfinalattendanceall?curdate=${date}&team=${team}`;
export const LOCATION_FORATTENDANCE = (date, location) =>
  `${PRODUCTION_URL}/attendance/dailylocationfinalattendanceall?curdate=${date}&location=${location}`;
export const MONTHLY_ATTENDANCEFORPRESENT = (month, year) =>
  `${PRODUCTION_URL}/attendance/monthlyattendancefinalall?month=${month}&year=${year}`;

//FOR RFQ API HERE
export const RFQ_CREATION = `${BASE_URL}/rfq/create`;
export const RFQ_GET = `${BASE_URL}/rfq/get`;
export const RFQ_GET_BY_LOST = `${BASE_URL}/rfq/getLosted`;
export const RFQ_GET_BY_PENDINGS = `${BASE_URL}/rfq/getPending`;
export const RFQ_GET_BY_CODE = (id) => `${BASE_URL}/rfq/getbycode/${id}`;
export const RFQ_GET_STATUS = `${BASE_URL}/rfq/status/Business%20Award`;
export const RFQ_GET_ID = (id) => `${BASE_URL}/rfq/getbyid/${id}`;
export const RFQ_UPDATE = (id) => `${BASE_URL}/rfq/update/${id}`;
export const RFQ_DELETE = (id) => `${BASE_URL}/rfq/delete/${id}`;

//FOR PROFILES
export const PROFILES_GET = `${BASE_URL}/employee/getall`;
export const PROFILES_GET_ID = (id) => `${BASE_URL}/employee/getbyid/${id}`;
export const PROFILES_GET_ROLE = (id) => `${BASE_URL}/employees/role/${id}`;
export const PROFILES_GET_CODE = (id) => `${BASE_URL}/employees/code/${id}`;
export const PROFILES_CREATE = `${BASE_URL}/employee/add`;
export const PROFILES_UPLOAD = `${BASE_URL}/employee/bulk`;
export const PROFILES_UPDATE = (id) => `${BASE_URL}/employee/update/${id}`;
export const PROFILES_DELETE = (id) => `${BASE_URL}/employee/delete/${id}`;
export const PROFILES_BULK_DELETE = `${BASE_URL}/employee/bulk/delete`;
export const PROFILES_GETBY_CAE = `${BASE_URL}/employee/getbydirecthire`;
export const PROFILES_GETBY_CAC = `${BASE_URL}/employee/getbyconsultanthire`;
export const PROFILES_GETBY_STATUS = (id) => `${BASE_URL}/employee/status/${id}`;
export const EMPLOYEE_STATUS = `${BASE_URL}/employee/status/active`;

//FOR PROJECT
export const PROJECT_GET = `${BASE_URL}/project/get-all`;
export const PROJECT_CREATE = `${BASE_URL}/project/create`;
export const PROJECT_DELETE = (id) => `${BASE_URL}/project/delete/${id}`;
export const PROJECT_UPDATE = (id) => `${BASE_URL}/project/update/${id}`;

//FOR CATEGORY
export const CATEGORY_CREATE = `${BASE_URL}/categories`;
export const CATEGORY_GET = `${BASE_URL}/categories`;

//FOR MILESTONE
export const MILESTONE_GET = `${BASE_URL}/milestones`;
export const MILESTONE_CREATE = `${BASE_URL}/milestone`;

//FOR LOCATION
export const LOCATION_GET = `${BASE_URL}/locations`;
export const LOCATION_CREATE = `${BASE_URL}/location`;

//FOR TEAM
export const TEAM_CREATION = `${BASE_URL}/roleaccess/add`;
export const TEAM_GET_ALL = `${BASE_URL}/roleaccess/getall`;
export const TEAM_DELETE = (id) => `${BASE_URL}/roleaccess/delete/${id}`;
export const TEAM_UPDATE = (id) => `${BASE_URL}/roleaccess/update/${id}`;
export const TEAM_GET_BY_ID = (id) => `${BASE_URL}/roleaccess/getbyid/${id}`;

//FOR TASK
export const TASKS_GET_ALL = `${BASE_URL}/tasks`;
export const TASKS_CREATE = `${BASE_URL}/tasks/manual`;
export const TASKS_FILTER = `${BASE_URL}/tasks/:employees`;
export const TASK_UPDATE = (id) => `${BASE_URL}/tasks/${id}`;
export const TASK_GET_BY_ID = (id) => `${BASE_URL}/tasks/${id}`;

//FOR TCO NUMBER
export const TCO_NUMBER = `${BASE_URL}/tco`;

//FOR HOLLIDAY
export const HOLIDAY = (date) => `${PRODUCTION_URL}/attendance/postholiday?holidaydate=${date}&holiday=true`;
export const HOLIDAY_GETALL = `${PRODUCTION_URL}/attendance/holidaylist`;

//FOR CREDENTIAL
export const CHANGE_PASSWORD = `${BASE_URL}/employee/changepassword`;
export const RESET_PASSWORD = (id) => `${BASE_URL}/employee/resetpassword/${id}`;

//FOR PERSONAL DETAILS
export const PERSONAL_DETAILS = `${BASE_URL}/personal-details`;
export const GET_ID_PERSONAL = (id) => `${BASE_URL}/personal-details/${id}/profile`;
export const PERSONAL_DETAILS_UPDATE = (id) => `${BASE_URL}/personal-details/${id}/verification-status`;
