// AuthenticationRoutes.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MinimalLayout from 'layout/MinimalLayout';
import AuthLogin3 from 'views/pages/authentication/authentication3/Login3';
import AuthRegister3 from 'views/pages/authentication/authentication3/Register3';

const AuthenticationRoutes = () => {
  console.log('AuthenticationRoutes is rendered'); // Add this log
  return (
    <Routes>
      <Route path="/*" element={<MinimalLayout />}>
        <Route index element={<AuthLogin3 />} />
        <Route path="login" element={<AuthLogin3 />} />
        <Route path="register" element={<AuthRegister3 />} />
      </Route>
    </Routes>
  );
};

export default AuthenticationRoutes;
