/*eslint-disable */

import 'react-calendar/dist/Calendar.css';
// import './Overallattendance.css';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import InputBase from '@mui/material/InputBase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Tooltip, Typography, styled } from '@mui/material';
import AccessAlarmTwoToneIcon from '@mui/icons-material/AccessAlarmTwoTone';
import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
// import YearlyAttendance from './YearlyAttendance';
import TonalitySharpIcon from '@mui/icons-material/TonalitySharp';
import WatchLaterSharpIcon from '@mui/icons-material/WatchLaterSharp';
import { DAILY_ATTENDANCE, MONTHLY_ATTENDANCE } from 'api/apiEndPoint';
import { fetchData } from 'utils/apiUtils';
import UpdateIcon from '@mui/icons-material/Update';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { mkConfig, generateCsv, download } from 'export-to-csv';


const SnailIcon = () => (
  <span role="img" aria-label="snail" style={{ marginTop: '10px', fontSize: '15px' }}>
    🐌
  </span>
);
const RocketIcon = () => (
  <span role="img" aria-label="rocket" style={{ marginTop: '10px', fontSize: '15px' }}>
    🚀
  </span>
);



const MonthlyAttendance = () => {

  const [employeeDatas, setEmployeeDatas] = useState(null);
  const [Department, setDepartment] = useState("");
  const [teamData, setTeamData] = useState([]);
  const [localData, setLocalData] = useState();

  const handleChanged = (event) => {
    setDepartment(event.target.value);
  };

  const localStore = () => {
    const localDatas = localStorage.getItem('userData');
    if (localDatas) {
      const parsedData = JSON.parse(localDatas)
      setLocalData(parsedData)
    }
  }

  useEffect(() => {
    localStore()
    const fetchDataAndUpdate = async () => {
      try {
        if (localStore) {
          const date = new Date();
          const team = localData?.team; // Assuming parsedData contains the team information
          const apiUrl = ATTENDANCE_BY_TEAM(date, team);
          console.log('dddddd', apiUrl);
          const data = await fetchData(apiUrl);
          setEmployeeDatas(data?.[0]);
          console.log('dataaaaaaaaaaaa', data);
        }
      } catch (err) {
        console.error('Error fetching attendance data:', err);
      }
    };

    fetchDataAndUpdate();
  }, []);

  console.log(localData, "local data from local storage");

  const [formData, setFormData] = useState({
    employeeid: '',
    sickleave: 1,
    casualleave: 0,
    earnedleave: 0
  });

  // useEffect(() => {

  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('LEAVE_OB');
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const responseData = await response.json();
  //       setFormData(responseData);
  //     } catch (error) {
  //       console.log('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);



  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const localStore = localStorage.getItem('userData')
        const parsed = JSON.parse(localStore)
        setLocalData(parsed)
        const data = await fetchData(TEAM_GET_ALL, parsed?.accessToken);
        const teamActive = data.filter((data) => data.active === true);
        console.log(data, 'data received from the server.');
        setTeamData(teamActive);
      } catch (error) {
        console.log('Error fetching teams:', error);

      }
    };
    fetchTeams()
  }, [])







  const [locationData, setLocationData] = useState();
  const [locationInfoData, setLocationInfoData] = useState([]);
  const [employeeDatass, setEmployeeDatass] = useState(null);
  const [location, setLocation] = useState();

  // const localStoreforLocation = () => {
  //   const localDatas = localStorage.getItem('userData');
  //   if (localDatas) {
  //     const parsedData = JSON.parse(localDatas)
  //     setLocationInfoData(parsedData)
  //   }
  // }


  const handleLocationChanged = (event) => {
    setLocation(event.target.value);
  };

  useEffect(() => {
    localStore()
    const fetchDataAndUpdates = async () => {
      try {
        if (localStore) {
          const date = new Date();
          const location = locationData?.location;
          console.log(location, "location");
          const apiUrl = LOCATION_FORATTENDANCE(date, location);
          console.log('ooooooooooooo', apiUrl);
          const data = await fetchData(apiUrl);
          setEmployeeDatass(data?.[0]);
          console.log('dataaaaaaaaaaaa', data);
        }
      } catch (err) {
        console.error('Error fetching attendance data:', err);
      }
    };

    fetchDataAndUpdates();
  }, []);


  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const localStore = localStorage.getItem('userData')
        const parsed = JSON.parse(localStore)
        setLocationData(parsed)
        const data = await fetchData(LOCATION_GET, parsed?.accessToken);
        console.log(data, 'data received from the server.');
        setLocationInfoData(data);
      } catch (error) {
        console.log('Error fetching location:', error);

      }
    };
    fetchLocation()
  }, [])









  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');

  const employeeDate = `${year}-${month}-${day}`;



  const [attendanceData, setAttendanceData] = useState([]);


  const columns = useMemo(() => [
    {
      accessorKey: 'employeeCode',
      header: 'Employee Code',
      size: 100
    },
    {
      accessorKey: 'employeename',
      header: 'Emp Name',
      size: 150
    },
    {
      accessorKey: 'employeeLocation',
      header: 'Emp Location',
      size: 150
    },
    {
      accessorKey: 'employeeTeam',
      header: 'Emp Team',
      size: 150
    },
    {
      accessorKey: 'attdate',
      header: 'Attendance Date',
      size: 150
    },
    {
      accessorKey: 'clock_in',
      header: 'In Time',
      size: 150
    },
    {
      accessorKey: 'clock_inlocation',
      header: 'Clock InLocation',
      size: 150
    },
    {
      accessorKey: 'clock_out',
      header: 'Out Time',
      size: 150
    },
    {
      accessorKey: 'clock_outlocation',
      header: 'Clock OutLocation',
      size: 150
    },
    {
      accessorKey: 'late_attendance',
      header: 'Late Attendance',
      size: 150
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 300,
      Cell: ({ value, row }) => (
        <div>
          {row?.original?.status === 'Present' && (
            <Button
              variant="outlined"
              style={{
                borderRadius: '15px',
                backgroundColor: '#b9f6ca',
                color: '#00c857',
                border: '2px solid #00c857'
              }}
            >
              Present
            </Button>
          )}
          {row?.original?.status === 'Leave' && (
            <Button
              variant="outlined"
              style={{
                borderRadius: '15px',
                backgroundColor: '#ef9a9a',
                color: '#c6282f',
                border: '2px solid #c6282f'
              }}
            >
              Leave
            </Button>
          )}
        </div>
      )
    }
  ], []);
  const dataWithSNo = useMemo(() => {
    return attendanceData.map((obj, index) => ({ ...obj, SNo: index + 1 }));
  }, [attendanceData]);
  const table = useMaterialReactTable({
    columns,
    data: dataWithSNo,
    columnFilterDisplayMode: 'popover'
  });

  useEffect(() => {
    const fetchDataAndUpdate = async (employeeDate) => {
      try {
        const localStore = localStorage.getItem('userData');
        if (localStore) {
          const parsedData = JSON.parse(localStore);
          const apiUrl = DAILY_ATTENDANCE(employeeDate);
          console.log('API Endpoint:', apiUrl);
          const data = await fetchData(apiUrl);
          setAttendanceData(data);
          console.log('Parsed Data:', parsedData);
        }
      } catch (err) {
        console.error('Error fetching attendance data:', err);
      }
    };

    fetchDataAndUpdate(employeeDate);
  }, []);


  const [monthlyAttendance, setMonthlyAttendance] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState('01');
  const [selectedYears, setSelectedYears] = useState('')



  useEffect(() => {
    const fetchDataAndUpdateMonthly = async (selectedMonths, selectedYears) => {
      try {
        const localStore = localStorage.getItem('userData');
        if (localStore) {
          const parsedData = JSON.parse(localStore);
          console.log(parsedData, "localStore");
          const apiUrl = MONTHLY_ATTENDANCE(selectedMonths, selectedYears);
          console.log(apiUrl, "apiUrl");
          console.log('API Endpoint:', apiUrl);
          const data = await fetchData(apiUrl);
          console.log(data, "data");

          // Filter out present status for future months
          const filteredData = data.map(attendanceData => ({
            ...attendanceData,
            attendance: attendanceData.attendance.map(attendance => ({
              ...attendance,
              status: isPastMonth(selectedMonths, selectedYears) ? attendance.status : 'Leave'
            }))
          }));

          setMonthlyAttendance(filteredData);
        }
      } catch (err) {
        console.error('Error fetching attendance data:', err);
      }
    };

    fetchDataAndUpdateMonthly(selectedMonths, selectedYears);
    console.log(selectedYears, "selectedMonths");
  }, [selectedMonths, selectedYears]);

  const isPastMonth = (selectedMonth, selectedYear) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    return selectedYear < currentYear || (selectedYear === currentYear && selectedMonth <= currentMonth);
  };




  const abbr = [
    { key: "CL", value: "Casual Leave" },
    { key: "SL", value: "Sick Leave" },
    { key: "EL", value: "Earned Leave" }
  ];

  const rowData = monthlyAttendance.map((attendanceData) => {
    return {
      name: attendanceData.employee_name,
      code: attendanceData.attendance.length > 0 ? attendanceData.attendance[0].employee_code : '',
      values: attendanceData.attendance.map((attendance) => {
        if (attendance.status === 'Leave') {
          const leaveAbbr = abbr.find(ab => ab.key === attendance.leave_type);
          return leaveAbbr ? leaveAbbr.key : 'L';

        } else if (attendance.status === 'Holiday') {
          return 'HL';

        } else if (attendance.status === 'Casual Leave') {
          return 'CL';
        } else if (attendance.status === 'Sick Leave') {
          return 'SL';
        } else if (attendance.status === 'Earned Leave') {
          return 'EL';
        } else if (attendance.status === 'Comp-Off ') {
          return 'CC';
        }
        else {
          return 'P';
        }
      }),
      leaveData: {
        sickleave: attendanceData.attendance.length > 0 ? attendanceData.attendance[0].sickleave : '',
        casualleave: attendanceData.attendance.length > 0 ? attendanceData.attendance[0].casualleave : '',
        earnedleave: attendanceData.attendance.length > 0 ? attendanceData.attendance[0].earnedleave : '',
      },
    };
  });









  const getColorBasedOnContent = (content) => {
    switch (content) {
      case 'P':
        return '#b9f6ca'; // Present
      case 'HL':
        return '#e3f2fd'; // Holiday
      case 'SL':
        return '#ffdfea'; // Sick Leave
      case 'CL':
        return '#ede7f6'; // Casual Leave
      case 'EL':
        return '#ffece8'; // Earned Leave
      case 'CC':
        return '#d3f1a7';
      default:
        return '#ffcccc'; // Leave for all other cases
    }
  };

  const rowsByColor = {
    '#b9f6ca': [],   // P (Present)
    '#ffcccc': [],   // Leave (All other values)
    '#e3f2fd': [],   // HL (Holiday)
    '#ffdfea': [],   // SL (Sick Leave)
    '#ede7f6': [],   // CL (Casual Leave)
    '#ffece8': [],   // EL (Earned Leave)
    '#d3f1a7': [],
  };



  // Now, categorize rows based on their color
  rowData.forEach((row) => {
    row.values.forEach((value, index) => {
      const color = getColorBasedOnContent(value);
      rowsByColor[color].push({
        name: row.name,
        code: row.code,
        status: value,
      });
    });
  });


  // Organizing rows by code
  const rowsByCode = {};

  rowData.forEach((row) => {
    const { code } = row;
    if (!rowsByCode[code]) {
      rowsByCode[code] = [];
    }
    rowsByCode[code].push(row);
  });


  const [selectedYear, setSelectedYear] = useState(null);
  const [months, setMonths] = useState([]);
  const [dates, setDates] = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState(0);


  useEffect(() => {
    const monthNames = Array.from({ length: 12 }, (_, index) => {
      const date = new Date(0, index);
      return date.toLocaleString('default', { month: 'short' });
    });
    setMonths(monthNames);
    setSelectedYear(currentYear);
  }, [currentYear]);

  useEffect(() => {
    generateDates(selectedYear, selectedMonth);
  }, [selectedYear, selectedMonth]);

  const generateDates = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const datesArray = Array.from({ length: daysInMonth }, (_, index) => {
      const date = new Date(year, month, index + 1);
      return {
        date: date.toLocaleDateString('default', { day: 'numeric' }),
        day: date.toLocaleDateString('default', { weekday: 'short' })
      };
    });
    setDates(datesArray);
  };


  const handleArrowClick = async (direction) => {
    let newMonth = selectedMonths;
    let newYear = selectedYears;

    // Update newMonth and newYear based on direction
    // Update newMonth and newYear based on direction
    if (direction === 'previous') {
      if (selectedMonths === '01') {
        newMonth = '12'; // Move to December
        newYear = parseInt(selectedYears, 10) - 1; // Move to previous year
      } else {
        const prevMonth = parseInt(selectedMonths, 10) - 1;
        newMonth = prevMonth < 10 ? `0${prevMonth}` : `${prevMonth}`;
      }
    } else {
      if (selectedMonths === '12') {
        newMonth = '01'; // Move to January
        newYear = parseInt(selectedYears, 10) + 1; // Move to next year
      } else {
        const nextMonth = parseInt(selectedMonths, 10) + 1;
        newMonth = nextMonth < 10 ? `0${nextMonth}` : `${nextMonth}`;
      }
    }

    if (selectedMonths === '12' && direction === 'next') {
      newYear = parseInt(selectedYears, 10) + 1; // Move to next year
    }


    // If the new month is January and the direction is 'next', increment the year
    if (newMonth === '01' && direction === 'next') {
      newYear = parseInt(selectedYears, 10) + 1;
    }

    setSelectedMonths(newMonth);
    setSelectedYears(newYear);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const [Dept, setDept] = React.useState('');

  const handleChange = (event) => {
    setDept(event.target.value);
  };
  const [loc, setLoc] = React.useState('');

  const handleChanges = (event) => {
    setLoc(event.target.value);
  };

  const [view, setView] = useState('daily');

  const handleTabChange = (selectedView) => {
    setView(selectedView);
  };

  // Given in-time and out-time strings
  const inTime = '9:10 AM';
  const outTime = '17:10 PM';

  // Parse the time strings into hours and minutes
  const [inHour, inMinute] = inTime.split(/:| /).map(Number);
  const [outHour, outMinute] = outTime.split(/:| /).map(Number);

  // Calculate the total working hours
  const totalHours = (outHour - inHour) + (outMinute - inMinute) / 60;

  // Display the total working hours
  console.log(`Total Working Hours: ${totalHours.toFixed(2)} hrs`);

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true
  });

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };




  const renderCalendarView = () => {
    switch (view) {
      case 'daily':

      case 'monthly':
        return (
          <div style={{ marginTop: '20px' }}>
            <TableContainer component={Paper} style={{ backgroundColor: '#eef0f1', borderColor: '#fff' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ padding: '25px' }}>Employee Code</TableCell>
                    <TableCell sx={{ marginLeft: '1px' }} >Employee Name</TableCell>
                    {dates.map((dateInfo, index) => (
                      <TableCell key={index}>
                        <div style={{ color: '#6e7a8a' }}> {months[selectedMonths - 1]} </div>{' '}
                        <div style={{ color: 'black', fontSize: '13px' }}>{dateInfo.date}</div>{' '}
                        <div style={{ color: '#6e7a8a', fontSize: '13px' }}> {dateInfo.day}</div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rowData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell component="th" scope="row">
                        {row.code}
                      </TableCell>
                      <TableCell component="th" scope="row" style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                        <Avatar style={{ width: '24px', height: '24px', marginRight: '10px', fontSize: '14px', backgroundColor: '#afd8f1' }}>
                          {row.name ? row.name.charAt(0) : ''}
                        </Avatar>

                        <span style={{ fontSize: '13px', marginLeft: '10px' }}>{row.name}</span>
                        <Tooltip
                          title={
                            <>
                              <Typography>Leaves : 3 Days</Typography>
                              <Typography>Total Working Hours : 40H:30M</Typography>
                              <Typography>Early In Hours : 00H:30M</Typography>
                              <Typography>Late In Hours : 26H:00M</Typography>
                            </>
                          }
                        >
                          <AccessAlarmTwoToneIcon style={{ fontSize: '19px', marginLeft: '60px', color: '#1da1f2' }} />
                        </Tooltip>
                        <Tooltip
                          title={
                            <>
                              <h6 style={{ marginLeft: '4rem' }}>Leaves</h6>
                              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                <div style={{ textAlign: 'left' }}>
                                  <Typography>SL : {formData.sickleave}</Typography>
                                  <Typography>CL : {formData.casualleave}</Typography>
                                  <Typography>EL : {formData.earnedleave}</Typography>
                                </div>

                                <div style={{ textAlign: 'right' }}>
                                  <Typography>Taken SL : 4</Typography>
                                  <Typography>Taken CL : 4</Typography>
                                  <Typography>Taken EL : 0</Typography>
                                </div>
                              </div>
                              <h6 style={{ marginLeft: '2rem' }}>Balance Leaves</h6>
                              <div style={{ marginLeft: '2rem' }}>
                                <Typography>Balance SL : 4</Typography>
                                <Typography>Balance CL : 4</Typography>
                                <Typography>Balance EL : 10</Typography>
                              </div>
                            </>
                          }
                        >
                          <Button
                            style={{
                              backgroundColor: '#6e6a6a',
                              color: '#364152',
                              padding: '5px',
                              fontSize: '13px',
                              height: '20px',
                              marginLeft: '5px'
                            }}
                          >
                            13<span className="text-muted">/24</span>
                          </Button>
                        </Tooltip>
                      </TableCell>
                      {row.values.map((value, columnIndex) => (
                        <Tooltip
                          title={
                            value === 'P' ? 'Present' : value === 'HL' ? 'Holiday' : 'Leave' + ' (SL: ' + (row.leaveData ? row.leaveData.sickleave : '') + ', CL: ' + (row.leaveData ? row.leaveData.casualleave : '') + ', EL: ' + (row.leaveData ? row.leaveData.earnedleave : '') + ')'
                          }
                          key={columnIndex}
                        >

                          <TableCell
                            align="right"
                            className="align-item-center"
                            style={{
                              backgroundColor: getColorBasedOnContent(value),
                              border: '5px solid #fff',
                              color: value === 'P' ? '#00c853' :
                                value === 'L' ? '#ff0000' :
                                  value === 'CL' ? '#673ab7' :
                                    value === 'SL' ? '#e965b3' :
                                      value === 'EL' ? '#f5826a' :
                                        value === 'CC' ? '#8ddd19' :
                                          '#31aaf3'
                            }}
                          >
                            {value === 'SH' ? (
                              <Tooltip
                                title={
                                  <>
                                    <Typography>Second Half Leave</Typography>
                                    <div className="d-flex">
                                      <WatchLaterSharpIcon style={{ color: '#00c853', fontSize: '15px', marginTop: '10px' }} />
                                      <Typography>IN TIME: 1:10 PM</Typography>
                                    </div>
                                    <div className="d-flex">
                                      <WatchLaterSharpIcon style={{ color: '#ce2d3e', fontSize: '15px', marginTop: '10px' }} />
                                      <Typography>OUT TIME: 5:10 PM</Typography>
                                    </div>
                                  </>
                                }
                              >
                                <TonalitySharpIcon style={{ color: '#00c853', fontSize: '15px' }} />
                              </Tooltip>
                            ) : (
                              value
                            )}
                          </TableCell>
                        </Tooltip>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      case 'yearly':

      default:
        return null;
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-item-center">

        <h5></h5>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '25ch',
            border: '1px solid #ccc',
            padding: '5px',
            borderRadius: '20px',
            marginRight: '30rem',
            marginLeft: '30rem'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <IconButton onClick={() => {
              console.log('Previous arrow clicked');
              handleArrowClick('previous', selectedMonths, selectedYears);
            }}>
              <NavigateBeforeIcon />
            </IconButton>
            <div>
              {months[selectedMonths - 1]}
              {console.log('Selected month:', months[selectedMonths - 1])}
            </div>

            <Select
              value={selectedYear}
              onChange={handleYearChange}
              variant="outlined"
              input={<InputBase sx={{ border: 'none' }} />}
              sx={{ minWidth: '80px', border: 'none' }}
            >
              {Array.from({ length: 5 }, (_, index) => {
                const year = currentYear + index + (selectedMonths === '12' && index === 0 ? 1 : 0);
                return (
                  <MenuItem key={year} value={year}>
                    &nbsp; {year}
                  </MenuItem>
                );
              })}

            </Select>



                        








            <IconButton onClick={() => {
              console.log('Next arrow clicked');
              handleArrowClick('next', selectedMonths, selectedYears);
            }}>
              <NavigateNextIcon />
            </IconButton>
          </Box>
        </Box>


        <div>

          <div className="butdiv d-flex">
            {/* <div style={{ marginLeft: '-10px', marginTop:'15px'}}>
              <Button onClick={handleExportData} variant="contained" color="primary" startIcon={<IconDownload />}>
                Export
              </Button>
            </div> */}
            {/* <Box sx={{ minWidth: 120 }}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-helper-label">Department</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={Dept}
                                    label="Department"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>IT</MenuItem>
                                    <MenuItem value={20}>Finance</MenuItem>
                                    <MenuItem value={30}>Tear down</MenuItem>
                                </Select>
                            </FormControl>
                        </Box> */}
            {/* <Box sx={{ minWidth: 120 }}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-helper-label">Location</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={loc}
                                    label="Location"
                                    onChange={handleChanges}
                                >
                                    <MenuItem value={10}>Ambathur</MenuItem>
                                    <MenuItem value={20}>Thiruvallur</MenuItem>
                                    <MenuItem value={30}>Kerala</MenuItem>
                                </Select>
                            </FormControl>
                        </Box> */}

            <ButtonGroup variant="outlined" aria-label="outlined primary button group" className="align-item-center">
            </ButtonGroup>
          </div>
        </div>
      </div>
      <div>{renderCalendarView()}</div>
    </div>
  );
}

export default MonthlyAttendance;
