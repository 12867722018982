import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import { WarningRounded } from '@mui/icons-material';

const Application1 = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10rem' }}>
      <MainCard
        style={{
          backgroundColor: '#f6e7b9',
          // width: '300px',
          // height: '200px',

          padding: '20px',
          position: 'relative',
          border: '2px solid #d2c914'
        }}
      >
        <div className="d-flex justify-content-center">
          <WarningRounded style={{ color: '#d2c914', fontSize: '85px', textAlign: 'center' }} />{' '}
        </div>
        <br />
        <Typography id="modal-modal-title" variant="h2" component="h2" style={{ textAlign: 'center' }}>
          Verification Pending from HR
        </Typography>
        <br />
        <Typography variant="h5" style={{ marginTop: '1rem', marginBottom: '1rem', textAlign: 'center' }}>
          Reason : Due to the large number of employees (350+), each with <br/> extensive documentation (15+ files), the verification process is
          taking longer than expected.
        </Typography>
      </MainCard>
    </div>
  );
};

export default Application1;
